import React, { useEffect, useState, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { t } from "components/CentralDataMangement/translation/Translation";
import LabelField from "../atoms/LabelField";
import Button from "../atoms/Button";
import ModalPopup from "components/common/atoms/ModalPopup";
import AutoCompleteDropDownForMap from "./AutoCompleteDropDownForMap";
import { LabelWithInputField } from "./LabelWithInputField";

interface AddressSearchPopupProps {
  initialLat: number;
  initialLng: number;
  initialAddress?: AddressDetails;
  onConfirm: (details: AddressDetails | null) => void;
  showAddPopup: boolean;
  onHide: () => void; // Add the onHide function to the props
}

export interface AddressDetails {
  street: string;
  nr: string;
  bus: string;
  postal: string;
  city: string;
  country: string;
  coordinates?: string;
}

const AddressSearchPopup: React.FC<AddressSearchPopupProps> = ({
  initialLat,
  initialLng,
  initialAddress = {
    street: "",
    nr: "",
    bus: "",
    postal: "",
    city: "",
    country: "",
    coordinates: "",
  },
  onConfirm,
  showAddPopup = false,
  onHide,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [addressDetails, setAddressDetails] = useState<AddressDetails>({
    street: "",
    nr: "",
    bus: "",
    postal: "",
    city: "",
    country: "",
    coordinates: "",
  });

  const mapRef = useRef<HTMLDivElement | null>(null);
  const addressInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`, // Replace with your API key
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      if (mapRef.current) {
        const mapInstance = new google.maps.Map(mapRef.current, {
          center: { lat: initialLat, lng: initialLng },
          zoom: 12,
        });
        setMap(mapInstance);

        const mapMarker = new google.maps.Marker({
          map: mapInstance,
          position: { lat: initialLat, lng: initialLng },
          draggable: true,
        });
        setMarker(mapMarker);

        const placeAutocomplete = new google.maps.places.Autocomplete(
          addressInputRef.current!
        );
        placeAutocomplete.bindTo("bounds", mapInstance);
        placeAutocomplete.setFields(["address_component", "geometry"]);
        placeAutocomplete.addListener("place_changed", fillInAddress);
        setAutocomplete(placeAutocomplete);

        mapMarker.addListener("dragend", () => {
          const position = mapMarker.getPosition();
          if (position) {
            updateAddressDetails(position);
          }
        });

        mapInstance.addListener("click", (event: google.maps.MapMouseEvent) => {
          const clickedLatLng = event.latLng;
          if (clickedLatLng) {
            mapMarker.setPosition(clickedLatLng);
            updateAddressDetails(clickedLatLng);
          }
        });

        updateAddressDetails(new google.maps.LatLng(initialLat, initialLng));
        // setGeocoderUsingState();
      }
    });
  }, [initialLat, initialLng, showAddPopup]);

  const fillInAddress = () => {
    if (!autocomplete) {
      return;
    }

    const place = autocomplete.getPlace();

    // Check if place and geometry are available
    if (!place || !place.geometry || !place.geometry.location) {
      return;
    }

    // Update map and marker if geometry.location exists
    const location = place.geometry.location;
    map?.setCenter(location);
    marker?.setPosition(location);

    // Call the function to update address details
    updateAddressDetails(location);
  };

  const updateAddressDetails = (
    latLng: google.maps.LatLng,
    from: string = ""
  ) => {
    const geocoder = new google.maps.Geocoder();

    if (!geocoder) return;

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.[0]) {
        const addressComponents = results[0].address_components;
        let street = "",
          nr = "",
          bus = "",
          postal = "",
          city = "",
          country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("street_number")) {
            nr = component.long_name;
          } else if (component.types.includes("route")) {
            street = component.long_name;
          } else if (component.types.includes("subpremise")) {
            bus = component.long_name;
          } else if (component.types.includes("postal_code")) {
            postal = component.long_name;
          } else if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        // Use geocoder results directly if 'from' is "findAddress", else fallback to initialAddress values
        setAddressDetails({
          street:
            from === "findAddress" ? street : street || initialAddress.street,
          nr: from === "findAddress" ? nr : nr || initialAddress.nr,
          bus: from === "findAddress" ? bus : bus || initialAddress.bus,
          postal:
            from === "findAddress" ? postal : postal || initialAddress.postal,
          city: from === "findAddress" ? city : city || initialAddress.city,
          country:
            from === "findAddress"
              ? country
              : country || initialAddress.country,
          coordinates: `Lat: ${latLng.lat()}, Lng: ${latLng.lng()}`,
        });
      }
    });
  };

  const confirmAddress = () => {
    onConfirm(addressDetails);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddressDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAddressFromMap = () => {
    const { street, nr, bus, postal, city, country } = addressDetails;

    const fullAddress = `${street} ${nr} ${bus} ${postal} ${city} ${country}`;
    searchAddress(fullAddress.trim()); // Search for the new address
  };

  const searchAddress = (address: string) => {
    const geocoder = new google.maps.Geocoder();

    if (geocoder && address) {
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results?.[0]) {
          const location = results[0].geometry.location;
          if (location) {
            map?.setCenter(location);
            marker?.setPosition(location);
            updateAddressDetails(location, "findAddress");
          }
        }
      });
    }
  };

  const clearAddress = () => {
    setAddressDetails({
      street: "",
      nr: "",
      bus: "",
      postal: "",
      city: "",
      country: "",
      coordinates: "",
    });
  };

  const closePopup = () => {
    onHide(); // Call onHide to close the popup
  };

  return (
    <>
      <ModalPopup
        size="md"
        show={showAddPopup}
        onHide={closePopup}
        title={t("Address")}
        headerClassName={`col-12 mx-auto`}
        modalBodyClassName="scrollBarDesign addressPopupBody"
        body={
          <div className="popup show">
            <LabelField
              title={t("Select a location")}
              className="tab-subtitle pb-0"
            />
            <div className="row">
              <div className="col-md-12 marginBottomPoint5">
                <div
                  className="address-input d-flex align-items-center"
                  style={{ gap: "1vw" }}
                >
                  <AutoCompleteDropDownForMap
                    ref={addressInputRef}
                    placeholder="Search address with Google Maps"
                  />
                  <button
                    className="form-button"
                    onClick={() =>
                      addressInputRef.current &&
                      searchAddress(addressInputRef.current.value)
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("Find nearest location")}
                  </button>
                </div>
              </div>
              <div
                className="col-md-12 d-grid"
                style={{ gridTemplateColumns: "repeat(3, 1fr)", gap: "0 1vw" }}
              >
                <div className="form-group">
                  <LabelWithInputField
                    isMandatory={false}
                    name="street"
                    handleChange={handleFieldChange}
                    value={addressDetails.street}
                    id="street"
                    label={t("Street")}
                    type="text"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <LabelWithInputField
                    isMandatory={false}
                    name="nr"
                    handleChange={handleFieldChange}
                    value={addressDetails.nr}
                    id="nr"
                    label={t("Number")}
                    type="text"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <LabelWithInputField
                    isMandatory={false}
                    name="bus"
                    handleChange={handleFieldChange}
                    value={addressDetails.bus}
                    id="bus"
                    label={t("Box")}
                    type="text"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <LabelWithInputField
                    isMandatory={false}
                    name="postal"
                    handleChange={handleFieldChange}
                    value={addressDetails.postal}
                    id="postal"
                    label={t("Postal code")}
                    type="text"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <LabelWithInputField
                    isMandatory={false}
                    name="city"
                    handleChange={handleFieldChange}
                    value={addressDetails.city}
                    id="city"
                    label={t("City")}
                    type="text"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <LabelWithInputField
                    isMandatory={false}
                    name="country"
                    handleChange={handleFieldChange}
                    value={addressDetails.country}
                    id="country"
                    label={t("Country")}
                    type="text"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-12 text-end">
                <Button
                  className="delete-btn marginRightPoint5"
                  handleClick={clearAddress}
                  title={t("Clear")}
                />
                <Button
                  className="form-button"
                  handleClick={getAddressFromMap}
                  title={t("Search address")}
                />
              </div>
            </div>
            <div
              id="map"
              ref={mapRef}
              className="mt-2"
              style={{ height: "200px", width: "100%" }}
            ></div>
            <div className="coordinates" style={{ marginTop: "0.5vw" }}>
              <p id="coordinates-text" className="mb-0">
                {addressDetails.coordinates}
              </p>
            </div>
            <div className="text-end">
              <Button
                className="form-button"
                handleClick={confirmAddress}
                title={t("Save")}
              />
            </div>
          </div>
        }
        className="modal-lg documentsCertificates addressPopup"
      />
    </>
  );
};

export default AddressSearchPopup;

/**** USAGE 
 * 
  const [showPopup, setShowPopup] = useState(false);

  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      SetAddressData(details, dispatch, state);
    }
    setShowPopup(false); // Close the popup after confirming the address
  };

  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };
 <div style={{ marginLeft: "1vw" }}>
                        <div className="d-flex align-items-center">
                          <Link
                            to={""}
                            title={t("Search address via google map...")}
                            onClick={handleAddPopup}
                            className="color-dark-pink marginRightPoint5"
                          >
                            <ActiveLocation />
                          </Link>
                        </div>
                        <AddressSearchPopup
                          initialLat={
                            state.general.initialLat === ""
                              ? 50.8503
                              : parseFloat(state.general.initialLat)
                          } // Replace with your initial latitude
                          initialLng={
                            state.general.initialLn === ""
                              ? 4.3517
                              : parseFloat(state.general.initialLn)
                          } // Replace with your initial longitude
                          onConfirm={handleConfirm}
                          showAddPopup={showPopup}
                          onHide={handleClosePopup} // Pass the onHide function from the parent
                        />
                      </div>
 */
