import { createBrowserRouter } from "react-router-dom";
import LoginForm from "components/IdentityManager/authentication/LoginForm";
import RegistrationForm from "components/IdentityManager/candidate-registration/RegistrationForm";
import ResetPasswordForm from "components/IdentityManager/authentication/ResetPasswordForm";
import HomePage from "dashboard/HomePage";
import CreatePermission from "components/IdentityManager/permissions/CreatePermission";
import ManagePermissions from "components/IdentityManager/permissions/ManagePermissions";
import CreateUserPage from "components/IdentityManager/users/CreateUserPage";
import RolePage from "components/IdentityManager/roles/CreateRole";
import EditUserPage from "components/IdentityManager/users/EditUserPage";
import ManageRoles, {
  loader as rolesLoader,
} from "components/IdentityManager/roles/ManageRoles";
import TermsAndConditions from "components/IdentityManager/terms-conditions/TermsAndConditions";
import ManageUsers from "components/IdentityManager/users/manage-users/ManageUsers";
import LinkAppLanguages from "components/CentralDataMangement/translation/LinkAppLanguages";
import CreateEmailTemplate from "components/CentralDataMangement/email-templates/CreateEmailTemplate";
import EditEmailTemplate from "components/CentralDataMangement/email-templates/EditEmailTemplate";
import ManageEmailTemplatesPage from "components/CentralDataMangement/email-templates/ManageEmailTemplates";
import { MultiFormProvider } from "components/CompanyCreation/Company/context/MultiFormProvider";
import ManageCityProfile from "components/CentralDataMangement/configurationAndSettings/cities/ManageCityProfile";
import { MultiFormProviderCandidate } from "components/CandidateCreation/context/MultiFormProviderCandidate";
import Location from "components/CompanyCreation/Location/Location";
import ManageCandidates from "components/CandidateCreation/manage-candidates/ManageCandidates";
import ManageFunctions from "components/CentralDataMangement/configurationAndSettings/functionsProfile/ManageFunctions";
import AddFunctions from "components/CentralDataMangement/configurationAndSettings/functionsProfile/AddFunctions";
import Checklist from "components/CentralDataMangement/configurationAndSettings/checklist/Checklist";
import ManageChecklist from "components/CentralDataMangement/configurationAndSettings/checklist/ManageChecklist";
import AddCityProfile from "components/CentralDataMangement/configurationAndSettings/cities/AddCityProfile";
import LowCoefficientTableForm from "components/CentralDataMangement/configurationAndSettings/coefficients/defaultLowCoefficents/LowCoefficientTableForm";
import WorkTypeExtra from "components/CentralDataMangement/configurationAndSettings/workTypeExtra/WorkTypeExtra";
import ManageExtras from "components/CentralDataMangement/configurationAndSettings/extras/ManageExtras";
import InflationCoefficientsForm from "components/CentralDataMangement/configurationAndSettings/coefficients/inflations/InflationCoefficientsForm";
import ManageInflations from "components/CentralDataMangement/configurationAndSettings/coefficients/inflations/ManageInflations";
import ManageLowCoefficients from "components/CentralDataMangement/configurationAndSettings/coefficients/defaultLowCoefficents/ManageLowCofficients";
import AddQuestions from "components/CentralDataMangement/questions/AddQuestions";
import ManageQuestions from "components/CentralDataMangement/questions/ManageQuestions";
import NewRootLayout from "components/common/layout/NewRootLayout";
import ProfilePage from "components/IdentityManager/profile-page/ProfilePage";
import CandidateScreening from "components/CentralDataMangement/screening/CandidateScreening";
import EmployeeType from "components/CentralDataMangement/configurationAndSettings/employeeType/EmployeeType";
import ManageEmployeeType from "components/CentralDataMangement/configurationAndSettings/employeeType/ManageEmployeeType";
import Coefficients from "components/CentralDataMangement/configurationAndSettings/coefficients/manageCoefficients/Coefficients";
import ManageCoefficient from "components/CentralDataMangement/configurationAndSettings/coefficients/manageCoefficients/ManageCoefficient";
import ManagePc from "components/CentralDataMangement/configurationAndSettings/paritiarCommittee/ManagePc";
import EditPc from "components/CentralDataMangement/configurationAndSettings/paritiarCommittee/EditPc";
import CoeffDeviation from "components/CentralDataMangement/configurationAndSettings/coefficients/manageCoeffientDeviation/CoeffDeviation";
import ManageCoeffDeviation from "components/CentralDataMangement/configurationAndSettings/coefficients/manageCoeffientDeviation/ManageCoeffDeviation";
import * as ROUTE from "routes/RouteConstants";
import EditOffices from "components/CentralDataMangement/offices/EditOffices";
import ManageOffices from "components/CentralDataMangement/offices/ManageOffices";
import PcCoefficients from "components/CentralDataMangement/configurationAndSettings/coefficients/PcCoefficients/PcCoefficients";
import ManagePcCoefficients from "components/CentralDataMangement/configurationAndSettings/coefficients/PcCoefficients/ManagePcCoefficients";
import MatchingList from "components/Matching/MatchingList";
import SalaryBenefit from "components/CentralDataMangement/configurationAndSettings/paritiarCommittee/SalaryBenefit";
import AddSignature from "components/CentralDataMangement/configurationAndSettings/signature/AddSignature";
import PageNotFound from "components/common/services/RolesAndPermissions/PageNotFound";
import TodosOverview from "components/CentralDataMangement/todos/TodosOverview";
import PresentationPage from "components/CentralDataMangement/todos/PresentationPage";
import CompanyPreInterviewPage from "components/CentralDataMangement/todos/CompanyPreInterviewPage";
import CandidatePreInterviewPage from "components/CentralDataMangement/todos/CandidatePreInterviewPage";
import PreInterviewPage from "components/CentralDataMangement/todos/PreInterviewPage";
import InterviewFeedbackPage from "components/CentralDataMangement/todos/InterviewFeedbackPage";
import WageDiscusisionPage from "components/CentralDataMangement/todos/WageDiscusisionPage";
import MultiFormProviderVacancy from "components/CentralDataMangement/vacancies/context/MultiFormProvider";
import CandidateCorner from "components/CandidateCreation/candidate-corner/CandidateCorner";
import BusinessUnit from "components/CentralDataMangement/configurationAndSettings/businessUnits/BusinessUnit";
import ManageBusinessUnit from "components/CentralDataMangement/configurationAndSettings/businessUnits/ManageBusinessUnit";
import ManageExperienceLevel from "components/CentralDataMangement/configurationAndSettings/experienceLevel/ManageExperienceLevel";
import ExperienceLevel from "components/CentralDataMangement/configurationAndSettings/experienceLevel/ExperienceLevel";
import DefaultCoefficients from "components/CentralDataMangement/configurationAndSettings/coefficients/defaultCoefficients/DefaultCoefficients";
import ManageDefaultCoefficients from "components/CentralDataMangement/configurationAndSettings/coefficients/defaultCoefficients/ManageDefaultCoefficients";
import Domain from "components/CentralDataMangement/configurationAndSettings/domain/Domain";
import Function from "components/CentralDataMangement/configurationAndSettings/function/Function";
import { MultiFormProviderAgreement } from "components//CompanyCreation/CooperationAgreement/context/MultiFormProvider";
import CoopAgreementPreview from "components/CompanyCreation/CooperationAgreement/create-agreement/formContent/CoopAgreementPreview";
import ManageAgreement from "components/CompanyCreation/CooperationAgreement/manageAgreement/ManageAgreement";
import ManageDomain from "components/CentralDataMangement/configurationAndSettings/domain/ManageDomain";
import ManageFunction from "components/CentralDataMangement/configurationAndSettings/function/ManageFunction";
import ViewUserPage from "components/IdentityManager/users/ViewUserPage";
import ManageWageElements from "components/CentralDataMangement/configurationAndSettings/specialAgreement/ManageWageElements";
import ManageEmailLogs from "components/CentralDataMangement/email-templates/ManageEmailLogs";
import EditInterviewDetails from "components/CentralDataMangement/todos/EditInterviewDetails";
import ContractPage from "components/CentralDataMangement/todos/ContractPage";
import ScreeningAssessment from "components/CentralDataMangement/screening/ScreeningAssessment";
import ManageScreening from "components/CentralDataMangement/screening/ManageScreening";
import Dashboard from "dashboard/dashboardContent/Dashboard";
import ConfigAndSettingsPage from "components/CentralDataMangement/configurationAndSettings/ConfigAndSettingsPage";
import ContractInfoPage from "components/CentralDataMangement/todos/ContractInfoPage";
import ManageRegions from "components/CentralDataMangement/budgetAndTarget/budget/ManageRegions";
import CreateRegion from "components/CentralDataMangement/budgetAndTarget/budget/CreateRegion";
import LinkOfficeToRegions from "components/CentralDataMangement/budgetAndTarget/budget/LinkOfficeToRegions";
import Budget from "components/CentralDataMangement/budgetAndTarget/budget/Budget";
import TargetConfig from "components/CentralDataMangement/budgetAndTarget/target/TargetConfig";
import BudgetAndTargetsPage from "components/CentralDataMangement/budgetAndTarget/BudgetAndTargetsPage";
import DailyTurnover from "components/CentralDataMangement/budgetAndTarget/budget/DailyTurnover";
import TargetOverview from "components/CentralDataMangement/budgetAndTarget/target/TargetOverview";
import ViewJobApplicationOverView from "components/CandidateCreation/JobApplications/ViewJobApplicationOverView";
import ManualMatching from "components/Matching/ManualMatching";
import TaskType from "components/CentralDataMangement/configurationAndSettings/taskTypes/TaskType";
import TaskSubType from "components/CentralDataMangement/configurationAndSettings/taskTypes/TaskSubType";
import ManageFollowUps from "components/CentralDataMangement/taskDeterminer/manageFollowUps/ManageFollowUps";
import ManageTranslation from "components/CentralDataMangement/translation/ManageTranslation";
import CandidateFeedback from "components/CentralDataMangement/todos/CandidateFeedback";
import ForgotPasswordForm from "components/IdentityManager/authentication/ForgotPasswordForm";
import CompanyRole from "components/CentralDataMangement/configurationAndSettings/companyRole.tsx/CompanyRole";
import ManageCompanyRole from "components/CentralDataMangement/configurationAndSettings/companyRole.tsx/ManageCompanyRole";
import Succession from "components/CentralDataMangement/taskDeterminer/Succession";
import TurnoverOverview from "components/CentralDataMangement/budgetAndTarget/budget/TurnoverOverview";
import UserLoginDetails from "components/IdentityManager/user-login-details/UserLoginDetails";
import ViewLoginDetails from "components/IdentityManager/user-login-details/ViewLoginDetails";
import VacancyOverview from "components/CentralDataMangement/vacancies/VacancyOverview";
import PageContents from "components/CentralDataMangement/configurationAndSettings/pageContents/PageContents";
import ManagePageContents from "components/CentralDataMangement/configurationAndSettings/pageContents/ManagePageContents";
import MultiFormProviderAdminFiche from "components/CompanyCreation/AdminFiche/context/MultiFormProviderAdminFiche";
import Offices from "components/CentralDataMangement/configurationAndSettings/offices/Offices";
import ManageOffice from "components/CentralDataMangement/configurationAndSettings/offices/ManageOffice";
import CreateShift from "components/CompanyCreation/shiftConfigurations/CreateShift";
import ManageShifts from "components/CompanyCreation/shiftConfigurations/ManageShifts";
import { MultiFormProviderPlanning } from "components/CompanyCreation/Planning/context/MultiFormProviderPlanning";
import RecruiterWeeklyReports from "components/CentralDataMangement/reports/recruiter/WeeklyReports";
import RecruiterMonthlyReports from "components/CentralDataMangement/reports/recruiter/MonthlyReports";
import OfficeManagerWeeklyReports from "components/CentralDataMangement/reports/officeManager/WeeklyReports";
import OfficeManagerMonthlyReports from "components/CentralDataMangement/reports/officeManager/MonthlyReports";
import TeamLeadWeeklyReports from "components/CentralDataMangement/reports/teamLead/WeeklyReports";
import TLMonthlyReports from "components/CentralDataMangement/reports/teamLead/MonthlyReports";
import ManageReports from "components/CentralDataMangement/reports/ManageReports";
import { ManageCompanies } from "components/CompanyCreation/Company/manageCompany/ManageCompanies";
import AdminFichePreview from "components/CompanyCreation/AdminFiche/preview/AdminFichePreview";
import SalesNoteMeeting from "components/CentralDataMangement/salesNote/SalesNoteMeeting";
import ManageAdminFiche from "components/CompanyCreation/AdminFiche/manageAdminFiche/ManageAdminFiche";
import ManagePlanning from "components/CompanyCreation/Planning/managePlanning/ManagePlanning";
import ManageVacancies from "components/CentralDataMangement/vacancies/manage-vacancy/ManageVacancies";
import ManageCompanyContacts from "components/CompanyCreation/Company/manageCompanyContacts/ManageCompanyContacts";
import ManageJobApplications from "components/CandidateCreation/JobApplications/manage-job-applications/ManageJobApplications";
import CandidateCentral from "components/CandidateCreation/CandidateCentral/CandidateCentral";
import SalesNoteMeetingsManagePage from "components/CentralDataMangement/salesNote/manage-salesnote/SalesNoteMeetingsManagePage";
import ManageLocation from "components/CompanyCreation/Location/manageLocation/ManageLocation";
import CandidateConsentForm from "components/CentralDataMangement/screening/CandidateConsentForm";
import CompanyCorner from "components/CompanyCreation/Company/CompanyCorner/CompanyCorner";


export const Routes = createBrowserRouter([
  { path: ROUTE.LOGIN, element: <LoginForm /> },
  { path: ROUTE.REGISTER, element: <RegistrationForm /> },
  { path: ROUTE.RESET_PASSWORD, element: <ResetPasswordForm /> },
  { path: ROUTE.FORGOT_PASSWORD, element: <ForgotPasswordForm /> },
  { path: ROUTE.TERMS_AND_CONDTIONS, element: <TermsAndConditions /> },
  {
    path: "/vacancy-overview/:id",
    element: (
      <VacancyOverview />
    )
  },
  {
    path: "/",
    element: <NewRootLayout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "*", element: <PageNotFound /> },
      { path: ROUTE.PERMISSION_CREATE, element: <CreatePermission /> },
      { path: ROUTE.PERMISSION_EDIT, element: <CreatePermission /> },
      { path: ROUTE.PERMISSION_UPDATE, element: <CreatePermission /> },
      { path: ROUTE.PERMISSION_DELETE, element: <ManagePermissions /> },
      { path: ROUTE.MANAGE_PERMISSIONS, element: <ManagePermissions /> },
      {
        path: ROUTE.MANAGE_ROLES,
        element: <ManageRoles />,
        loader: rolesLoader,
      },
      { path: ROUTE.ROLE_CREATE, element: <RolePage /> },
      { path: ROUTE.ROLE_EDIT, element: <RolePage /> },
      {
        path: ROUTE.USER_CREATE,
        element: <CreateUserPage />,
      },
      {
        path: ROUTE.USER_EDIT,
        element: <EditUserPage />,
      },
      {
        path: ROUTE.VIEW_USERS,
        element: <ViewUserPage />,
      },
      {
        path: ROUTE.USER_UPDATE,
        element: <EditUserPage />,
      },
      {
        path: ROUTE.MANAGE_USERS,
        element: <ManageUsers />,
      },
      // routes for translations
      {
        path: ROUTE.MANAGE_TRANSLATIONS,
        element: <ManageTranslation />,
      },
      {
        path: ROUTE.TRANSLATION_LINK_APP_LANGUAGE,
        element: <LinkAppLanguages />,
      },
      // routes for email templates
      {
        path: ROUTE.EMAIL_TEMPLATE_CREATE,
        element: <CreateEmailTemplate />,
      },
      {
        path: ROUTE.EMAIL_TEMPLATE_EDIT,
        element: <EditEmailTemplate />,
      },
      {
        path: ROUTE.EMAIL_TEMPLATE_VIEW,
        element: <EditEmailTemplate />,
      },
      {
        path: ROUTE.MANAGE_EMAIL_TEMPLATES,
        element: <ManageEmailTemplatesPage />,
      },
      {
        path: ROUTE.MANAGE_EMAIL_LOGS,
        element: <ManageEmailLogs />,
      },

      ///for company-creation
      {
        path: ROUTE.COMPANY,
        element: <MultiFormProvider />,
      },
      {
        path: ROUTE.MANAGE_COMPANIES,
        element: <ManageCompanies />,
      },
      {
        path: ROUTE.COMPANY_CONTACTS,
        element: <ManageCompanyContacts />,
      },
      {
        path: ROUTE.LOCATION,
        element: <Location />,
      },
      {
        path: ROUTE.MANAGE_LOCATIONS,
        element: <ManageLocation />,
      },

      //Candidate creation
      {
        path: ROUTE.CREATE_CANDIDATE,
        element: <MultiFormProviderCandidate />,
      },
      {
        path: ROUTE.EDIT_CANDIDATE,
        element: <MultiFormProviderCandidate />,
      },
      {
        path: ROUTE.VIEW_CANDIDATE,
        element: <MultiFormProviderCandidate />,
      },
      {
        path: ROUTE.MANAGE_CANDIDATES,
        element: <ManageCandidates />,
      },
      // routes for checklist
      {
        path: ROUTE.CHECKLIST_EDIT,
        element: <Checklist />,
      },
      {
        path: ROUTE.CHECKLIST_VIEW,
        element: <Checklist />,
      },
      {
        path: ROUTE.MANAGE_CHECKLIST,
        element: <ManageChecklist />,
      },

      //Cooperation agreement config and settings
      {
        path: ROUTE.CONFIG_AND_SETTINGS,
        element: <ConfigAndSettingsPage />,
      },
      {
        path: ROUTE.MANAGE_FUNCTION_PROFILE,
        element: <ManageFunctions />,
      },
      {
        path: ROUTE.FUNCTION_ADD,
        element: <AddFunctions />,
      },
      {
        path: ROUTE.FUNCTION_EDIT,
        element: <AddFunctions />,
      },
      {
        path: ROUTE.FUNCTION_VIEW,
        element: <AddFunctions />,
      },
      {
        path: ROUTE.EDIT_LOW_COEFFICIENTS,
        element: <LowCoefficientTableForm />,
      },
      {
        path: ROUTE.ADD_LOW_COEFFICIENTS,
        element: <LowCoefficientTableForm />,
      },
      {
        path: ROUTE.MANAGE_LOW_COEFFICIENTS,
        element: <ManageLowCoefficients />,
      },

      { path: ROUTE.ADD_CITY_PROFILE, element: <AddCityProfile /> },
      { path: ROUTE.EDIT_CITY_PROFILE, element: <AddCityProfile /> },
      { path: ROUTE.VIEW_CITYPROFILE, element: <AddCityProfile /> },
      { path: ROUTE.MANAGE_CITY_PROFILE, element: <ManageCityProfile /> },
      { path: ROUTE.WORK_TYPE_EXTRA, element: <WorkTypeExtra /> },
      { path: ROUTE.MANAGE_EXTRAS, element: <ManageExtras /> },
      { path: ROUTE.MANAGE_WAGE_ELEMENTS, element: <ManageWageElements /> },
      {
        path: ROUTE.INFLATION_COEFFICIENTS,
        element: <InflationCoefficientsForm />,
      },
      {
        path: ROUTE.MANAGE_VACANCIES,
        element: <ManageVacancies />,
      },

      {
        path: ROUTE.CREATE_COOPERATION_AGREEMENT,
        element: <MultiFormProviderAgreement />,
      },
      {
        path: ROUTE.VIEW_COOPERATION_AGREEMENT,
        element: <MultiFormProviderAgreement />,
      },
      {
        path: ROUTE.MANAGE_COOPERATION_AGREEMENT,
        element: <ManageAgreement />,
      },
      {
        path: ROUTE.ADD_INFLATION,
        element: <InflationCoefficientsForm />,
      },

      {
        path: ROUTE.EDIT_INFLATION,
        element: <InflationCoefficientsForm />,
      },
      {
        path: ROUTE.VIEW_INFLATION,
        element: <InflationCoefficientsForm />,
      },

      {
        path: ROUTE.MANAGE_INFLATIONS,
        element: <ManageInflations />,
      },
      //questions
      {
        path: ROUTE.SCREENING_ASSESSMENT,
        element: <ScreeningAssessment />,
      },
      {
        path: ROUTE.MANAGE_SCREENING,
        element: <ManageScreening />
      },
      {
        path: ROUTE.SCREENING,
        element: <CandidateScreening />,
      },
      {
        path: ROUTE.ADD_QUESTION,
        element: <AddQuestions />,
      },
      {
        path: ROUTE.EDIT_QUESTION,
        element: <AddQuestions />,
      },
      {
        path: ROUTE.CLONE_QUESTION,
        element: <AddQuestions />,
      },
      {
        path: ROUTE.VIEW_QUESTION,
        element: <AddQuestions />,
      },
      {
        path: ROUTE.MANAGE_QUESIONS,
        element: <ManageQuestions />,
      },

      {
        path: ROUTE.DASHBOARD,
        element: <Dashboard />,
      },

      {
        path: ROUTE.PROFILE_PAGE,
        element: <ProfilePage />,
      },

      //Paritair committee
      {
        path: ROUTE.MANAGE_PC,
        element: <ManagePc />,
      },
      {
        path: ROUTE.EDIT_PC,
        element: <EditPc />,
      },
      {
        path: ROUTE.VIEW_PC,
        element: <EditPc />,
      },
      {
        path: ROUTE.EDIT_SALARY_BENEFIT,
        element: <SalaryBenefit edit={true} />,
      },
      {
        path: ROUTE.VIEW_SALARY_BENEFIT,
        element: <SalaryBenefit />,
      },
      {
        path: ROUTE.ADD_COEFFICIENT,
        element: <Coefficients />,
      },
      {
        path: ROUTE.EDIT_COEFFICIENT,
        element: <Coefficients />,
      },
      {
        path: ROUTE.MANAGE_COEFFICIENT,
        element: <ManageCoefficient />,
      },
      {
        path: ROUTE.EDIT_COEFF_DEVIATION,
        element: <CoeffDeviation />,
      },
      {
        path: ROUTE.MANAGE_COEFF_DEVIATION,
        element: <ManageCoeffDeviation />,
      },
      {
        path: ROUTE.ADD_EMP_TYPE,
        element: <EmployeeType />,
      },
      {
        path: ROUTE.EDIT_EMP_TYPE,
        element: <EmployeeType />,
      },
      {
        path: ROUTE.MANAGE_EMP_TYPE,
        element: <ManageEmployeeType />,
      },

      {
        path: ROUTE.ADD_PC_COEFF,
        element: <PcCoefficients />,
      },
      {
        path: ROUTE.EDIT_PC_COEFF,
        element: <PcCoefficients />,
      },
      {
        path: ROUTE.MANAGE_PC_COEFF,
        element: <ManagePcCoefficients />,
      },
      //offices
      {
        path: ROUTE.MANAGE_OFFICE_LOCATIONS,
        element: <ManageOffices />,
      },
      {
        path: ROUTE.EDIT_OFFICE_LOCATIONS,
        element: <EditOffices />,
      },
      {
        path: ROUTE.ADD_SIGNATURE,
        element: <AddSignature />,
      },
      // Matching page
      {
        path: ROUTE.MATCHING_LIST,
        element: <MatchingList />,
      },
      {
        path: ROUTE.COOP_AGREEMENT_PREVIEW,
        element: <CoopAgreementPreview />,
      },
      {
        path: ROUTE.TODOS_OVERVIEW,
        element: <TodosOverview />,
      },
      {
        path: ROUTE.PRESENTATON_PAGE,
        element: <PresentationPage />,
      },
      {
        path: ROUTE.COMPANY_PRE_INTERVIEW_PAGE,
        element: (
          // {ROUTE.COMPANY_PRE_INTERVIEW_PAGE}>
          <CompanyPreInterviewPage />
        ),
      },
      {
        path: ROUTE.CANDIDATE_PRE_INTERVIEW_PAGE,
        element: <CandidatePreInterviewPage />,
      },
      {
        path: ROUTE.PRE_INTERVIEW_PAGE,
        element: <PreInterviewPage />,
      },
      {
        path: ROUTE.INTERVIEW_FEEDBACK_PAGE,
        element: <InterviewFeedbackPage />,
      },
      {
        path: ROUTE.WAGE_DISCUSSION_PAGE,
        element: <WageDiscusisionPage />,
      },
      {
        path: ROUTE.CANDIDATE_CORNER,
        element: (
          // {ROUTE.CANDIDATE_CORNER}>
          <CandidateCorner />
        ),
      },
      {
        path: ROUTE.VACANCY,
        element: <MultiFormProviderVacancy />,
      },
      {
        path: ROUTE.CLONE_VACANCY,
        element: <MultiFormProviderVacancy />,
      },
      {
        path: ROUTE.MANAGE_BUSINESS_UNIT,
        element: <ManageBusinessUnit />,
      },
      {
        path: ROUTE.CREATE_BUSINESS_UNIT,
        element: <BusinessUnit />,
      },
      {
        path: ROUTE.EDIT_BUSINESS_UNIT,
        element: <BusinessUnit />,
      },
      {
        path: ROUTE.MANAGE_EXPERIENCE_LEVEL,
        element: <ManageExperienceLevel />,
      },
      {
        path: ROUTE.CREATE_EXPERIENCE_LEVEL,
        element: <ExperienceLevel />,
      },
      {
        path: ROUTE.EDIT_EXPERIENCE_LEVEL,
        element: <ExperienceLevel />,
      },
      {
        path: ROUTE.ADD_DEFAULT_COEFFS,
        element: <DefaultCoefficients />,
      },
      {
        path: ROUTE.EDIT_DEFAULT_COEFFS,
        element: <DefaultCoefficients />,
      },
      {
        path: ROUTE.MANAGE_DEFAULT_COEFFS,
        element: <ManageDefaultCoefficients />,
      },
      {
        path: ROUTE.ADD_DOMAIN,
        element: <Domain />,
      },
      {
        path: ROUTE.EDIT_DOMAIN,
        element: <Domain />,
      },
      {
        path: ROUTE.ADD_FUNCTION,
        element: <Function />,
      },
      {
        path: ROUTE.EDIT_FUNCTION,
        element: <Function />,
      },
      {
        path: ROUTE.MANAGE_DOMAIN,
        element: <ManageDomain />,
      },
      {
        path: ROUTE.MANAGE_FUNCTION,
        element: <ManageFunction />,
      },
      {
        path: ROUTE.MANAGE_BUDGET_REGIONS,
        element: <ManageRegions />,
      },
      {
        path: ROUTE.BUDGET_REGION,
        element: <CreateRegion />,
      },
      {
        path: ROUTE.LINK_OFFICE_REGION,
        element: <LinkOfficeToRegions />,
      },
      {
        path: ROUTE.EDIT_OFFICE_REGION,
        element: <LinkOfficeToRegions />,
      },
      {
        path: ROUTE.BUDGET,
        element: <Budget />,
      },
      {
        path: ROUTE.BUDGET_AND_TARGET,
        element: <TargetConfig />,
      },
      {
        path: ROUTE.BUDGET_AND_TARGETS,
        element: <BudgetAndTargetsPage />,
      },
      {
        path: ROUTE.DAILY_TURNOVER,
        element: <DailyTurnover />,
      },
      {
        path: ROUTE.TARGET_OVERVIEW,
        element: <TargetOverview />,
      },
      {
        path: ROUTE.EDIT_INTERVIEW_DETAILS,
        element: <EditInterviewDetails />
      },
      {
        path: ROUTE.CANDIDATE_CENTRAL,
        element: <CandidateCentral />,
      },
      {
        path: ROUTE.CONTRACT_PAGE,
        element: <ContractPage />,
      },
      {
        path: ROUTE.CONTRACT_INFO_PAGE,
        element: (
          <ContractInfoPage />
        )
      },
      {
        path: ROUTE.CANDIDATE_FEEDBACK,
        element: (
          <CandidateFeedback />
        )
      },
      {
        path: ROUTE.MANAGE_JOB_APPLICATIONS,
        element: (
          <ManageJobApplications />
        )
      },
      {
        path: ROUTE.VIEW_JOB_APPLICATION,
        element: (
          <ViewJobApplicationOverView />
        )
      },
      {
        path: ROUTE.MANUAL_MATCHING,
        element: (
          <ManualMatching />
        )
      },
      {
        path: ROUTE.DAILY_TURNOVER_OVERVIEW,
        element: (
          <TurnoverOverview />
        )
      },

      //task determiner
      {
        path: ROUTE.ADD_TASK_TYPE,
        element: (
          <TaskType />
        )
      },
      {
        path: ROUTE.ADD_TASK_SUB_TYPE,
        element: (
          <TaskSubType />
        )
      },
      {
        path: ROUTE.ADD_FOLLOW_UP,
        element: (
          <Succession />
        )
      },
      {
        path: ROUTE.EDIT_FOLLOW_UP,
        element: (
          <Succession />
        )
      },
      {
        path: ROUTE.VIEW_FOLLOW_UP,
        element: (
          <Succession />
        )
      },
      {
        path: ROUTE.MANAGE_FOLLOW_UPS,
        element: (
          <ManageFollowUps />
        )
      },

      {
        path: ROUTE.COMPANY_ROLE,
        element: (
          <CompanyRole />
        )
      },
      {
        path: ROUTE.MANAGE_COMPANY_ROLES,
        element: (
          <ManageCompanyRole />
        )
      },
      {
        path: ROUTE.USER_LOGIN_DETAILS,
        element: (
          <UserLoginDetails />
        )
      },
      {
        path: ROUTE.VIEW_LOGIN_DETAILS,
        element: (
          <ViewLoginDetails />
        )
      },
      {
        path: ROUTE.VACANCY_OVERVIEW,
        element: (
          <VacancyOverview />
        )
      },
      {
        path: ROUTE.PAGE_CONTENTS,
        element: (
          <PageContents />
        )
      },
      {
        path: ROUTE.MANAGE_PAGE_CONTENTS,
        element: (
          <ManagePageContents />
        )
      },
      {
        path: ROUTE.OFFICE,
        element: (
          <Offices />
        )
      },
      {
        path: ROUTE.MANAGE_OFFICES,
        element: (
          <ManageOffice />
        )
      },
      {
        path: ROUTE.CREATE_ADMIN_FICHE,
        element: <MultiFormProviderAdminFiche />
      },
      {
        path: ROUTE.CREATE_SHIFT,
        element: (
          <CreateShift />
        )
      },
      {
        path: ROUTE.EDIT_SHIFT,
        element: (
          <CreateShift />
        )
      },
      {
        path: ROUTE.MANAGE_SHIFTS,
        element: (
          <ManageShifts />
        )
      },
      //plannig / contract
      {
        path: ROUTE.CREATE_PLANNING,
        element: <MultiFormProviderPlanning />,
      },
      {
        path: ROUTE.EDIT_PLANNING,
        element: <MultiFormProviderPlanning />,
      },
      {
        path: ROUTE.MANAGE_PLANNING,
        element: <ManagePlanning />,
      },

      //reports
      {
        path: ROUTE.MANAGE_REPORTS,
        element: (
          <ManageReports />
        )
      },
      {
        path: ROUTE.RECRUITER_WEEKLY_REPORTS,
        element: (
          <RecruiterWeeklyReports />
        )
      },
      {
        path: ROUTE.RECRUITER_MONTHLY_REPORTS,
        element: (
          <RecruiterMonthlyReports />
        )
      },
      {
        path: ROUTE.OM_WEEKLY_REPORTS,
        element: (
          <OfficeManagerWeeklyReports />
        )
      },
      {
        path: ROUTE.OM_MONTHLY_REPORTS,
        element: (
          <OfficeManagerMonthlyReports />
        )
      },
      {
        path: ROUTE.TL_WEEKLY_REPORTS,
        element: (
          <TeamLeadWeeklyReports />
        )
      },
      {
        path: ROUTE.TL_MONTHLY_REPORTS,
        element: (
          <TLMonthlyReports />
        )
      },
      {
        path: ROUTE.ADMIN_FICHE_PREVIEW,
        element: (
          <AdminFichePreview />
        )
      },
      {
        path: ROUTE.MANAGE_SALES_NOTE_MEETINGS,
        element: (
          <SalesNoteMeetingsManagePage />
        )
      },
      {
        path: ROUTE.VIEW_SALES_NOTE_MEETING,
        element: (
          <SalesNoteMeeting />
        )
      },
      {
        path: ROUTE.MANAGE_ADMIN_FICHE,
        element: <ManageAdminFiche />,
      },
      {
        path: ROUTE.CANDIDATE_CONSENT_FORM,
        element: <CandidateConsentForm />,
      },
      {
        path: ROUTE.COMPANY_CORNER,
        element: <CompanyCorner />,
      },
    ],
  },
]);
