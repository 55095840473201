export const SERVICE_URL = process.env.REACT_APP_SERVICE_REGISTRY;
export const getRefreshToken = SERVICE_URL + 'get-refresh-token';
export const forgotPassword = "forgot-password";
export const FILE_UPLOAD = "api/file/upload";
export const FILE_DELETE = "api/delete/file";
export const FILE_DOWNLOAD = "api/download/file";

// ***File url based on server ***
const app_env = process.env.REACT_APP_NODE_ENV;
let FILE_URL: string;
if (app_env === "production") {
  FILE_URL = "https://absolute-jobs-production.s3.eu-west-1.amazonaws.com";
} else {
  FILE_URL = "https://absolute-jobs-development.s3.eu-west-1.amazonaws.com";
}
export { FILE_URL };
//***  */

export const RESUME_PARSER = "api/v1/resume/parser";
export const GET_FORM_DATA = "getForm";
export const PHONE_CALL_URL = "https://absolutejobs-11617.3cx.be/webclient/#/call?phone="


// Identity Manager 
export const GET_ROLES = "getRoles";
export const REGISTER = "registration";
export const GETLOCATION = "getLocation";
export const GET_REGISTER_FORM_DETAILS = "getRegisterFormDetails";
export const GET_USER = "getUserDetails";
export const GET_USERS = "getUsers";
export const UPDATE_USER = "updateUser";
export const FORGOT_PASSWORD = "forgot-password";
export const LOGIN = "login";
export const LOGOUT = "logout";

export const RESET_PASSWORD = "reset-password";
export const CANDIDATE_STATUS = "updateCandidateStatus";

export const GET_PERMISSION = "getPermission";
export const UPDATE_PERMISSION = "updatePermission";
export const STORE_PERMISSION = "storePermission";
export const GET_PERMISSIONS = "getPermissions";
export const DELETE_PERMISSION = "deletePermission";

export const EDIT_ROLE = "editRole";
export const CLASS_BY_PERMISSIONS = "classificationsByPermissions";
export const CREATE_ROLE = "createRole";
export const DELETE_ROLE = "deleteRole";
export const DELETE_USER = "deleteUser";

// Central Data Management
export const MODULES = "getModules";
export const STORE_TEMPLATE = "storeTemplate";
export const GET_TEMPLATE = "getTemplate";
export const UPDATE_TEMPLATE = "updateTemplate";
export const GET_TEMPLATES = "getTemplates";
export const GET_APP_LANG = "getAppLang";
export const GET_EMAIL_LOGS = "manageEmailLogs";
export const SEND_EMAIL = "sendEmail";

export const GET_ALL_APP_LANG = "getAllAppLangsAndLanguages";
export const LINK_APP_LANG = "linkAppLanguages";
export const LOCALE_TARGETS = "locale-targets";
export const LOCALE_TARGETS_SEARCH = "locale-targets/search";
export const SAVE_TRANSLATION = "saveTranslation";
export const UPDATE_TRANSLATIONS = "updateTranslations";
export const LOCALE_SOURCES = "localeSources";

export const GET_QUESTION_DETAILS = "getQuestionDetails";
export const STORE_QUESTIONS = "storeQuestions";
export const UPDATE_QUESTIONS = "updateQuestions";
export const MANAGE_QUESTIONS = "ManageQuestions";
export const GET_DOMAIN_FOR_FUNCTION = "getDomainForFunctionDetails";
export const GET_FUNCTION_FOR_DOMAIN = "getFunctionForDomainDetails";
export const DELETE_QUESTIONS = "deleteQuestions";
export const GET_SCREENING_DETAILS = "getScreeningDetails";
export const GET_QUESTIONS_BY_FUNCTIONS = "getQuestionsByFunctions";
export const STORE_SCREENING_INFO = "storeScreeningInfo";
export const GET_SCREENING_ASSESSMENT = "getScreeningAssesment";
export const MANAGE_SCREENING = "ManageScreening";

// Candidate-creation
export const GENERAL_DETAILS = "getGeneralDetails";
export const OTHER_DETAILS = "getOtherTabDetails";
export const JOBPROFILE_DETAILS = "getJobProfileDetails";
export const CANDIDATE_COMPETENCE = "getCandidateCompetence";
export const DOCUMENT_DETAILS = "getDocumentDetails";
export const REFERENCE_DETAILS = "getReferenceDetails";
export const STUDY_DETAILS = "getStudyDetails";
export const WORK_DETAILS = "getWorkDetails";
export const RESGISTER_CANDIDATE = "registerCandidate";
export const CANDIDATES = "getCandidates";
export const ARCHIVE_CANDIDATE = "archiveCandidate";
export const DELETE_USER_CC = "deleteUser";
export const REGISTER_CC = "registration";
export const UPDATE_USER_CC = "updateUser";
export const GET_OFFICE_FOR_CONSULTANT = "getOfficeForConsultant";
export const VALIDATION_FOR_CANDIDATE_FIELDS = "validationForCandidate";
export const CHECK_FOR_IBAN_EXISTANCE = "checkForIbanExistence";
//parsing details
export const GET_PARSING_DETAILS = "getParsingDetails";
export const CONSENT_TO_TERMS = "consentToTerms";

//Comapny-creation
export const RESGISTER_COMPANY = "registerCompany";
export const COMPANY_DETAILS = "getCompanyDetails";
export const USERS_TO_IDM = "userToIdm";
export const MANAGE_COMPANY = "manageCompany";
export const COMPANY_CONTACTS = "companyContacts";
export const ARCHIVE_COMPANY = "archiveCompany";
export const LOCATION_DETAILS = "getLocationDetails";
export const ADD_LOCATION = "addLocation";
export const MANAGE_LOCATION = "manageLocation";
export const ARCHIVE_LOCATION = "archiveLocation";

export const COMPANY_CORNER = "companyCorner";
export const COMPANY_COUNTS = "companyCounts";

export const ADD_CHECKLIST = "addChecklist";
export const GET_CHECKLIST = "getChecklistDetails";
export const MANAGE_CHECKLIST = "manageChecklist";
export const CHECK_FOR_EMAIL_EXIST = "CheckForEmailExist";

// configuration and settings
export const FETCH_FUNCTION_DETAILS = "fetchFunctionDetails";
export const STORE_FUNCTIONS_TO_COUNTRY = "storeFunctions";
export const MANAGE_FUNCTIONS = "manageFunctions";
export const GET_WORK_TYPE_COEFFICIENT = "getWorkTypeCoefficient";
export const STORE_WORK_TYPE_COEFFICIENT = "storeWorkTypeCoefficient";
export const GET_NESTED_LOW_COFFICIENTS = "getNestedLowCoefficients";
export const MANAGE_LOW_COEFFICIENTS = "manageLowCoefficients";
export const UPDATE_LOW_COEFFICIENTS = "updateLowCoefficients";
export const STORE_CITY = "storeCity";
export const UPDATE_CITY = "updateCity";
export const GET_CITY_DETAILS = "getCityDetails";
export const GET_CITIES_DETAILS = "getCitiesDetails";
export const GET_REGION_FOR_COUNTRIES = "getRegionForCountryDetails";
export const GET_COUNTRIES_FOR_REGION = "getCountryForRegionDetails";
export const GET_NESTED_INFLATIONS = "getNestedInflations";
export const SAVE_INFLATIONS = "saveInflations";
export const GET_INFLATIONS = "getInflations";
export const GET_SSN = "getSSN";
export const CONF_SETTINGS_ADD = "confSettingsAdd";
export const CONF_SETTINGS_EDIT = "confSettingsEdit";
export const CONF_SETTINGS_UPDATE = "confSettingsUpdate";
export const CONF_SETTINGS_GET = "confSettingsGet";
export const CONF_SETTINGS_DELETE = "confSettingsDelete";

// cooperation agreement
export const GET_AGREEMENT_DETAILS = "getAgreementDetails";
export const CREATE_AGREEMENT = "storeAgreementDetails";
export const GET_PC_FUNCTIONS = "getPcFunctions";
export const MANAGE_COOPERATION_AGREEMENT = "manageAgreement";
export const SEND_FOR_AM_APPROVAL = "sendForAmApproval";
export const PAY_TERM_APPROVAL = "payTermApproval";


// vacancies
export const MANAGE_VACANCY = "manageVacancies";
export const DELETE_VACANCY = "deleteVacancy";
export const VACANCY_DETAILS = "getVacancyDetails";
export const STORE_VACANCY = "storeVacancyDetails";
export const COMPANY_RELATED_DETAILS = "getCompanyRelatedDetails";
export const GET_COMPETENCES_BY_TEMPLATE_ID = "getCompetencesByTemplateId";
export const GET_VACANCY_PDF = 'getVacancyPdf';

//offices
export const MANAGE_OFFICES = "manageOffices";
export const GET_OFFICE_DETAILS = "getOfficeDetails";
export const STORE_OFFICE_DETAILS = "storeOfficeDetails";

export const DELETE_PROFILE_PIC = "updateFileUploadDataInIdm";
export const CREATE_TODO = "createTodo";
export const CREATE_PRESENTATION_TODO = "createPresentationTodo";
export const MANUAL_MATCHING_SAVE = "saveManualMatching";

// paritair committee
export const MANAGE_PC = "getParitairCommittees";

//Matching list
export const MATCHING_LIST = 'matchingList';
export const GET_CANDIDATE_PROFILE_CARD_DETAILS = 'getCandidateProfileCardDetails';
export const GET_FUNCTIONS_LIST = 'fetchFunctionsList';
export const GET_MANUAL_MATCHING_SUGGESTIONS = 'getManualMatchingSuggestions';
export const GET_VACANCY_FOR_COMPANY = 'getVacancyForCompany';

//signature
export const ADD_SIGNATURE = "addSignature";
export const GET_SIGNATURE = "getSignature";
export const CHECK_EMPLOYER_SIGN = "checkEmployerSign";
export const AUTHENTICATE_EMPLOYER = "authenticateEmployer";
export const GET_CITY_AND_COUNTRY = "getCityAndCountryOptions";

//presentation
export const CREATE_PRESENTATION_DOCUMENT = "createPresentationDocument";

//budget
export const BUDGET_GET = "getBudgetByType";
export const BUDGET_ADD = "addBudgetByType";
export const BUDGET_EDIT = "editBudgetByType";
export const BUDGET_UPDATE = "updateBudgetByType";
export const BUDGET_DELETE = "deleteBudgetByType";

//target
export const TARGET_GET = "getTargetByType";
export const TARGET_ADD = "addTargetByType";
export const TARGET_EDIT = "editTargetByType";
export const TARGET_UPDATE = "updateTargetByType";
export const TARGET_DELETE = "deleteTargetByType";

//dashboard
export const GET_DASHBOARD = "getDashboardByType";
export const GET_USER_PERMISSIONS = "getUserPermissions";

export const GET_COMPANY_RESPONSIBLE_PERSON = "getCompanyResponsiblePerson";
export const ASSIGN_TODO = "assignTodo";
export const GET_TODOS = "getTodos";
export const UPDATE_TODO = "updateTodo";
export const GET_PRE_INTERVIEW_DETAILS = "getPreInterviewDetails";
export const STORE_PRE_INTERVIEW_DETAILS = "storePreInterviewDetails";
export const CANDIDATE_CENTRAL = "getCandidateCentralDetails";

export const GET_JOB_APPLICATIONS_DETAILS = "getJobApplicationsDetails"; //for manage page all records
export const GET_JOB_APPLICATION_DETAILS = "getJobApplicationDetails"; //for view page single record
export const UPDATE_OR_CREATE_JOB_APPLICATION =
  "updateOrCreateJobApplicationDetails";

//task-determiner
export const GET_FOLLOW_UPS = "getFollowUps";
export const EDIT_FOLLOW_UP = "editFollowUp";
export const STORE_FOLLOW_UP = "storeFollowUp";
export const CLOSE_FOLLOW_UP = "closeFollowUp";
export const FETCH_FOLLOWUP_INFO = "fetchFollowUpInfo";
export const FETCH_COMPANY_VACANCIES = "fetchCompanyVacancies";

//auto-search
export const GET_SEARCH_VALUES = "autoSearch";

export const GET_COMPANY = "getCompany";
export const VERIFY_VAT_NUMBER = "verifyVatNumber";

//login-details
export const GET_LOGIN_DETAILS = "getLoginDetails";
export const GET_USER_LOGIN_DETAILS = "getUserLoginDetails";

//check for email or ssn existence
export const CHECK_FOR_SSN_OR_EMAIL_EXISTANCE = "checkForSSNorEmailExistance";

export const DELETE_COMPANY_CONTACT = 'deleteCompanyContact';
export const STORE_PAGE_CONTENT = "storePageContent";
export const GET_PAGE_CONTENT = "getPageContent";
export const MANAGE_PAGE_CONTENT = "managePageContents";
export const ACCEPT_TERMS_AND_CONDITIONS = 'accpetTermsAndConditions';

//admin fiche
export const STORE_ADMIN_FICHE = "storeAdminFiche";
export const GET_ADMIN_FICHE_DETAILS = "getAdminFicheDetails";
export const CHECK_AUTHENTICATION = "checkAuthentication";
export const APPROVE_ADMIN_FICHE = "approveAdminFiche";
export const MANAGE_ADMIN_FICHE = "manageAdminFiche";

export const CREATE_CONTRACT_TODO = "createContractTodo";

export const UPDATE_OR_CREATE_SHIFT = 'updateOrCreateShift';
export const GET_SHIFT_DETAILS = 'getShiftDetails';
export const MANAGE_SHIFT_DETAILS = 'manageShiftDetails';
export const GET_SHIFT_DATA = "getShiftData";

//Sales note
export const CREATE_USER_IN_SALES_NOTE = 'createUserInSalesNote';
export const CREATE_MEETING_IN_SALES_NOTE = 'createMeetingInSalesNote';
export const GET_SOUND_SETTINGS = 'getSoundSettings';
export const SET_MICROPHONE = 'setMicrophone';
export const GET_MEETING_TYPES = 'getMeetingTypes';
export const MANAGE_SALES_NOTE_MEETINGS = 'manageSalesNoteMeetings';
export const GET_SALES_NOTE_MEETING = 'getSalesNoteMeeting';
export const STOP_RECORDING = 'stopRecording';

//reports
export const FETCH_REPORTS = 'fetchReports';
export const EDIT_WEEKLY_REPORTS = 'editWeeklyReports';
export const EDIT_MONTHLY_REPORTS = 'editMonthlyReports';
export const UPDATE_WEEKLY_REPORTS = 'updateWeeklyReports';
export const UPDATE_MONTHLY_REPORTS = 'updateMonthlyReports';

//reminders
export const GET_REMINDER_DETAILS = 'getReminderDetails';
export const STORE_REMINDER_DETAILS = 'storeReminderDetails';
export const DELETE_REMINDER = 'deleteReminder';

