import React, { ReactElement, useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import RadioField from "components/common/atoms/RadioField";
import LabelField from "components/common/atoms/LabelField";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import { useFormContext } from "../../context/Context";
import {
  validateForm,
  Option,
  scrollToTop,
  validateRadioField,
  validateSelectField,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { useNavigate, useParams } from "react-router-dom";
import { CREATE_AGREEMENT, PAY_TERM_APPROVAL } from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import ModalPopup from "components/common/atoms/ModalPopup";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Calender from "components/common/molecules/Calender";
import * as CONST from "../../annotations/CoopAgreementConstants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Navigation from "../formNavigation/Navigation";
import Button from "components/common/atoms/Button";
import { COMPANY_MICROSERVICE } from "Constants";

const InvoiceTab = () => {
  const { state, dispatch } = useFormContext();
  // const { modalId } = useParams<{ modalId: string }>();
  const { agreementId } = useParams<{ agreementId: string }>();
  const { companyId } = useParams<{ companyId: string }>();
  const [loading, setLoading] = useState(false);
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const [showModal, setShowModal] = useState(false);
  const [coeffShowModal, setcoeffShowModal] = useState(false);
  const [modalBody, setModalBody] = useState<{
    body: React.ReactNode;
    title: string;
    closeTitle: string;
    confirmTitle: string;
  }>({
    body: null,
    title: "",
    closeTitle: "",
    confirmTitle: "",
  });

  const handleModalClose = () => setShowModal(!showModal);
  const handleModalCloseCoeff = () => setcoeffShowModal(!coeffShowModal);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    if (type === "checkbox") {
      const checked = (e.target as HTMLInputElement).checked;
      const newValue = checked ? 1 : 0;
      dispatch({
        type: "UPDATE_INVOICE_FIELD",
        field: name,
        value: newValue,
      });
      validation(name, newValue, true);
    } else {
      const newValue = type === "radio" ? parseInt(value) : value;
      dispatch({ type: "UPDATE_INVOICE_FIELD", field: name, value: newValue });
      validation(name, newValue, true);
    }
  };

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedDate = date !== null ? date : new Date();
    dispatch({
      type: CONST.UPDATE_INVOICE_FIELD,
      field: name,
      value: updatedDate,
    });
    validation(name, updatedDate, true);
  };

  const validation = (
    name: string,
    value:
      | string
      | number
      | boolean
      | Date
      | Option
      | object[]
      | null
      | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      paymentTerm: [validateSelectField],
      billingType: [validateRadioField],
    };

    const validationErrors = validateForm(
      { ...state.invoice, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectField = (selectedOption: any, name: string) => {
    dispatch({
      type: CONST.UPDATE_INVOICE_FIELD,
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const checkForDuplicates = () => {
    const emailSet = new Set();
    const phoneSet = new Set();
    let hasDuplicate = false;

    if (state.contacts.every((contact: any) => contact.type === 0)) {
      hasDuplicate = true;
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: "selectedContact",
        error: t("Please select atleast one contact or add new contact"),
      });
      CustomNotify({
        type: "error",
        message: t("Please select atleast one contact or add new contact"),
      });
      return hasDuplicate;
    }
    state.contacts.forEach((contact, index) => {
      if (contact.email.trim() !== "") {
        if (emailSet.has(contact.email)) {
          state.dynamicErrors[index].email = t("Duplicate email");
          hasDuplicate = true;
        } else {
          emailSet.add(contact.email);
        }
      }

      if (contact?.phNumber?.trim() !== "") {
        if (phoneSet.has(contact.phNumber)) {
          state.dynamicErrors[index].phNumber = t("Duplicate phone number");
          hasDuplicate = true;
        } else {
          phoneSet.add(contact.phNumber);
        }
      }
    });
    hasDuplicate &&
      CustomNotify({
        type: "error",
        message: t("Duplicate email or phone number exists") + "!!",
      });
    return hasDuplicate;
  };

  const amPaymentTermApproval = () => {
    const newPaymentTerm = state.invoice.paymentTerm?.value;
    const oldPaymentTerm = state.invoice.oldPaymentTerm?.value;
    const amStatus = state.general.payTermApproval;
    const amApprovalPaymentTerms = [2, 5, 6];

    if (amStatus === 2 && oldPaymentTerm === newPaymentTerm) {
      return false;
    }
    if (
      newPaymentTerm &&
      (amStatus === 1 || oldPaymentTerm !== newPaymentTerm) &&
      amApprovalPaymentTerms.includes(newPaymentTerm)
    ) {
      dispatch({
        type: "UPDATE_INVOICE_FIELD",
        field: "paymentTermStatus",
        value: true,
      });
      setShowModal(true);
      setModalBody({
        body: (
          <div>
            <div className="mb-3">
              {t("A payment term of")}{" "}
              <b>({state.invoice.paymentTerm?.label})</b>{" "}
              {userAuth.role === "AREA_MANAGER" ||
              userAuth.role === "ADMIN" ||
              userAuth.role === "SUPER_ADMIN"
                ? t("has been selected. Please approve it before clicking the submit button") + '.'
                : t("needs Area Manager approval. Do you want to proceed") + '?'}
            </div>
          </div>
        ),
        title: "Payment term",
        closeTitle: "Close",
        confirmTitle:
          userAuth.role === "AREA_MANAGER" ||
          userAuth.role === "ADMIN" ||
          userAuth.role === "SUPER_ADMIN"
            ? ""
            : "Send for AM approval",
      });
      return true;
    } else {
      dispatch({
        type: "UPDATE_INVOICE_FIELD",
        field: "paymentTermStatus",
        value: false,
      });
      setShowModal(false);
      setModalBody({
        body: null,
        title: "",
        closeTitle: "",
        confirmTitle: "",
      });
      return false;
    }
  };

  const handlePaymentTermApproval = async () => {
    const data = {
      approved: 2,
      agreementId,
      companyId,
    };
    const response = await ApiCall.service(
      PAY_TERM_APPROVAL,
      "POST",
      data,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      dispatch({
        type: CONST.UPDATE_GENERAL_FIELD,
        field: "payTermApproval",
        value: 2,
      });
      CustomNotify({ type: "success", message: t(response.msg) });
    }
  };

  const handleSubmit = async (
    event: React.FormEvent,
    sendApproval: boolean = false,
    isPayemntTermApproval: boolean = false
  ) => {
    event.preventDefault();
    // const duplicates = checkForDuplicates();

    // if (duplicates) {
    //   dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 1, error: true });
    // }

    if (state.invoice.coeffMaxStatus) {
      dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 3, error: true });
      return;
    }

    const holidayCoeff = state.compositionData.filter(
      (item: any) => item.wageId === 1
    );
    if (holidayCoeff && holidayCoeff?.[0]?.["errorStatus"]) {
      dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 3, error: true });
      return;
    }
    const { name, value } = event.target as HTMLInputElement;
    const processedBillingData = state.billing.map(
      ({ empTypeDropdownValues, ...billingData }) => billingData
    );

    if (
      validationStatus.isValid &&
      validation(name, value) &&
      !state.invoice.coeffMaxStatus
    ) {
      if (
        state.invoice.coeffStatus === true &&
        !sendApproval &&
        userAuth.role !== "AREA_MANAGER"
      ) {
        setcoeffShowModal(true);
        return;
      }

      let paymentTermModalShow = true;
      if (
        !coeffShowModal &&
        userAuth.role !== "AREA_MANAGER" &&
        userAuth.role !== "ADMIN" &&
        userAuth.role !== "SUPER_ADMIN"
      ) {
        if (isPayemntTermApproval && state.invoice.paymentTermStatus) {
          if (modalBody.title === "Payment term") {
            paymentTermModalShow = false;
          }
          setShowModal(false);
        }

        if (paymentTermModalShow) {
          const approval = amPaymentTermApproval();
          if (approval) {
            return;
          }
        }
      }
      const formData = {
        general: {
          ...state.general,
          companyId: companyId,
        },
        billing: processedBillingData,
        contacts: state.contacts,
        compositionData: state.compositionData,
        invoice: {
          ...state.invoice,
          sendApproval: sendApproval,
          isCreateFollowUp: !paymentTermModalShow ? true : false,
        },
      };
      setLoading(true);
      const url = !agreementId
        ? CREATE_AGREEMENT
        : `${CREATE_AGREEMENT}/${agreementId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        true,
        "company-creation"
      );
      if (response.status === 200) {
        if (state.invoice.coeffStatus || !paymentTermModalShow) {
          userAuth.role !== "AREA_MANAGER" &&
            CustomNotify({
              type: "success",
              message: t("Sent for AM approval"),
            });
          navigate("/manage/cooperation-agreement");
        } else {
          navigate(
            `/cooperation-agreement/preview/${companyId}/${response.data}?coeff_type=${state.general.coeffType}`
          );
        }
        setLoading(false);
      } else if (response.status === 400) {
        CustomNotify({ type: "error", message: t("Some error occurred") });
        scrollToTop();
      } else {
        if (response?.emails) {
          state.contacts.forEach((contact, index) => {
            if (response.emails.includes(contact.email)) {
              state.dynamicErrors[index].email = t(
                "Email already exist with a different role"
              );
            }
          });

          dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 1, error: true });
          CustomNotify({
            type: "error",
            message: t(response?.msg),
          });
        }
      }
    } else {
      setShowModal(false);
      setModalBody({
        body: null,
        title: "",
        closeTitle: "",
        confirmTitle: "",
      }); // Reset modal body when not visible
    }
    setLoading(false);
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    dispatch({ type: CONST.UPDATE_INVOICE_FIELD, field: name, value: content });
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              <div className="row">
                <div className="d-flex">
                  <div className="billing-days-dropdwon w-100 marginRight1">
                    <SelectWithSearch
                      isMandatory={true}
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectField(e, "paymentTerm");
                      }}
                      search={true}
                      options={state.payCondition}
                      value={state.invoice.paymentTerm}
                      name="paymentTerm"
                      error={state.fieldError.paymentTerm}
                      title={t("Number of days payment term (standard: 14)")}
                      isTranslate={true}
                    />
                  </div>
                  {state.general.payTermApproval === 1 &&
                    (userAuth.role === "AREA_MANAGER" ||
                      userAuth.role === "ADMIN" ||
                      userAuth.role === "SUPER_ADMIN") && (
                      <div style={{ marginTop: "1.8vw" }}>
                        <Button
                          handleClick={handlePaymentTermApproval}
                          title={t("Approve")}
                          className="form-button shadow-none text-nowrap"
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <LabelField title={t("Billing type")} isMandatory={true} />
                  </div>
                  <div className="mb-2">
                    <RadioField
                      handleChange={changeHandler}
                      label={t("Week")}
                      name="billingType"
                      value={0}
                      ischecked={state.invoice.billingType === 0}
                    />
                  </div>
                  <div>
                    <RadioField
                      handleChange={changeHandler}
                      label={t("Month")}
                      name="billingType"
                      value={1}
                      ischecked={state.invoice.billingType === 1}
                    />
                  </div>
                </div>
                <div
                  className="height-20 text-danger"
                  style={{ marginBottom: "0.5vw" }}
                >
                  {state.fieldError.billingType}
                </div>
                <div className="col-lg-12">
                  <LabelField
                    title={t("How should corrections be settled") + "?"}
                    isMandatory={false}
                  />
                  <div className="mb-2">
                    <RadioField
                      handleChange={changeHandler}
                      label={t("Correction on one document")}
                      name="corrections"
                      value={0}
                      ischecked={state.invoice.corrections === 0}
                    />
                  </div>
                  <div>
                    <RadioField
                      handleChange={changeHandler}
                      label={t("Credit note and new invoice")}
                      name="corrections"
                      value={1}
                      ischecked={state.invoice.corrections === 1}
                    />
                  </div>
                </div>
                <div
                  className="height-20 text-danger"
                  style={{ marginBottom: "0.5vw" }}
                >
                  {state.fieldError.corrections}
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Separate invoice for worker/employee")}
                    name="invoiceWorker"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.invoiceWorker}
                    id="invoiceWorker"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Separate invoice per location")}
                    name="invoicePerLocation"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.invoicePerLocation}
                    id="invoicePerLocation"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Separate invoice per employee")}
                    name="invoicePerEmployee"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.invoicePerEmployee}
                    id="invoicePerEmployee"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Separate invoice for selection/payroll")}
                    name="invoiceSelcOrPay"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.invoiceSelcOrPay}
                    id="invoiceSelcOrPay"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Subtotals per cost center")}
                    name="costCenter"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.costCenter}
                    id="costCenter"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Add total hours worked by cost center")}
                    name="hrsCostCenter"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.hrsCostCenter}
                    id="hrsCostCenter"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Merge detail lines per UZK")}
                    name="mergeTempWorker"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.mergeTempWorker}
                    id="mergeTempWorker"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBottomPoint5">
                  <CheckBoxField
                    label={t("Add total line of hours worked")}
                    name="hrsWorked"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.hrsWorked}
                    id="hrsWorked"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBotttom1">
                  <CheckBoxField
                    label={t("Add function of contract")}
                    name="contractFunction"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.contractFunction}
                    id="contractFunction"
                    lineHeight="1vw"
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 marginBotttom1">
                  <CheckBoxField
                    label={t("Payment by direct debit")}
                    name="paymentDebit"
                    onChangeHandler={changeHandler}
                    isChecked={state.invoice.paymentDebit}
                    id="paymentDebit"
                    lineHeight="1vw"
                  />
                </div>

                {state.invoice.paymentDebit ? (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3 ps-1">
                        <LabelWithInputField
                          isMandatory={false}
                          name="accountNo"
                          handleChange={changeHandler}
                          value={state.invoice.accountNo}
                          label={t("Bank Account No")}
                          placeholder={t("IBAN")}
                          error={state.fieldError.accountNo}
                        />
                      </div>
                      <div className="col-3">
                        <LabelWithInputField
                          isMandatory={false}
                          name="reference"
                          handleChange={changeHandler}
                          value={state.invoice.reference}
                          label={t("Mandate reference")}
                          placeholder={t("Mandate reference")}
                          error={state.fieldError.reference}
                        />
                      </div>
                      <div className="col-3">
                        <SelectWithSearch
                          isMandatory={false}
                          isMulti={false}
                          onChange={(e) => {
                            handleSelectField(e, "invoiceType");
                          }}
                          search={true}
                          options={[
                            { value: 1, label: "Core" },
                            { value: 2, label: "B2B" },
                          ]}
                          value={state.invoice.invoiceType}
                          name="invoiceType"
                          error={state.fieldError.invoiceType}
                          title={t("Type")}
                        />
                      </div>
                      <div className="col-3 position-relative">
                        <Calender
                          onChange={handleDateChange}
                          selectedDate={state.invoice.dateOfSign}
                          label={t("Date")}
                          isMandatory={false}
                          name="dateOfSign"
                          error={
                            state.fieldError.dateOfSign
                              ? state.fieldError.dateOfSign.toString()
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-lg-12">
                  <LabelWithCKEditorField
                    name="comments"
                    label={t("Are there any comments") + "?"}
                    value={state.invoice.comments}
                    handleChange={(event, editor) =>
                      handleCKEditorChange(event, editor, "comments")
                    }
                    isMandatory={false}
                    error={state.fieldError.comments}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} isLoading={loading} />
        {(!validationStatus.isValid || state.invoice.paymentTermStatus) && (
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={t(modalBody.title)}
            body={modalBody.body}
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={(event: React.FormEvent) =>
              handleSubmit(event, false, true)
            }
            className="modal-lg"
            closeTitle={t(modalBody.closeTitle)}
            confirmTitle={t(modalBody.confirmTitle)}
          />
        )}
        {state.invoice.coeffStatus && (
          <ModalPopup
            show={coeffShowModal}
            onHide={handleModalCloseCoeff}
            title={t("Coefficients")}
            body={
              <div>
                <div className="mb-3">
                  {t(
                    "Looks like you have added the coefficients or amount below the minimum value, therefore these coefficients will need approval from your Area manager"
                  )}
                  .
                </div>
                <div>
                  <span className="color-dark-pink">{t("Note")}:</span>{" "}
                  {t(
                    "If you click on cancel then you will have to add a coefficient or amount above the minimum"
                  )}
                  .
                </div>
              </div>
            }
            onCloseButtonClick={handleModalCloseCoeff}
            onConfirmButtonClick={(event: React.FormEvent) =>
              handleSubmit(event, true)
            }
            className="modal-lg"
            closeTitle={t("Cancel")}
            confirmTitle={t("Send approval")}
          />
        )}
      </form>
    </>
  );
};
export default translate(InvoiceTab);
