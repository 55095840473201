import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as CONST from 'Constants';
import { STOP_RECORDING, GET_SOUND_SETTINGS, PHONE_CALL_URL } from 'routes/ApiEndpoints';
import CustomNotify from 'components/common/atoms/CustomNotify';
import { t } from '../translation/Translation';
import { addUserDetails, selectAuth } from 'features/auth/AuthSlice';
import { ApiCall } from 'components/common/services/ApiServices';
import MeetingTypesModal from './MeetingTypesModal';
import DeviceSelectionModal from './DeviceSelectionModal';
import ModalPopup from 'components/common/atoms/ModalPopup';
import { useDispatch } from 'react-redux';
import SuccessionPopup from '../taskDeterminer/SuccessionPopup';
import { EntityData } from 'components/common/atoms/RightSideBar';

export interface ModalDetails {
    modalType: string;
    showModal: boolean;
    body: string | ReactNode;
    title: string;
    showCloseButton: boolean;
    closeTitle: string;
    confirmTitle: string;
}

export interface SalesNoteProps {
    salesnote: {
        name: string;
        phone?: string;
        id: number | null;
        activeRecordingId?: number | null;
        companyId?: number | null;
        triggerMeeting?: boolean,
        type?: number | null;
        isMeetingTitle?: boolean;
        isPlay?: boolean;
        navigate?: string;
        prevId?: number | null;
        isInBright?: boolean;
    };
    setSalesNote: (salesnote: any) => void;
}

const SalesNote: React.FC<SalesNoteProps> = ({ salesnote, setSalesNote }) => {
    const authUser = useSelector(selectAuth);
    const [modalDetails, setModalDetails] = useState<ModalDetails>({
        modalType: '',
        showModal: false,
        body: '',
        title: '',
        showCloseButton: false,
        closeTitle: '',
        confirmTitle: '',
    });
    const dispatch = useDispatch();
    const [soundSettingsResponse, setSoundSettingsResponse] = useState<any>(null);
    const [soundSettingsModalOpen, setSoundSettingsModalOpen] = useState(false);
    const [showMeetingTypesModal, setShowMeetingTypesModal] = useState(false);
    const [prevId, setPrevId] = useState<number | null | undefined>(null);

    const recordConfirmPopUp = () => {
        setModalDetails({
            modalType: CONST.CONFIRM,
            body: (
                <>
                    {t('Do you want to record a call?')}
                </>
            ),
            title: t('Recording confirmation'),
            showModal: true,
            closeTitle: t('No'),
            confirmTitle: t('Yes'),
            showCloseButton: false,
        });
    }

    const recordConfirmYes = () => {
        const user = salesnote;
        setSoundSettingsModalOpen(true);
        setSalesNote({
            ...user,
            activeRecordingId: salesnote.activeRecordingId,
            triggerMeeting: false
        });
        setModalDetails((prev) => ({
            ...prev,
            modalType: '',
            showModal: false,
        }));
    }

    const handleMeeting = async () => {

        // Fetch sound settings for the meeting

        const soundSettingsResponse = await ApiCall.getService(
            GET_SOUND_SETTINGS,
            'GET',
            CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true
        );

        if (soundSettingsResponse.status === 200) {
            recordConfirmPopUp();
            setSoundSettingsResponse(soundSettingsResponse.data);

        } else if (soundSettingsResponse.status == 500) {
            setModalDetails({
                modalType: STOP_RECORDING,
                body: (
                    <>
                        {t('A recording is already in progress')}{' '}
                        <strong>{soundSettingsResponse.error}</strong>{'. '}
                        {t('Do you want to stop it and start a new recording')}{'?'}
                    </>
                ),
                title: t('Stop recording'),
                showModal: true,
                closeTitle: t('No'),
                confirmTitle: t('Yes'),
                showCloseButton: false,
            });

        } else {
            if (soundSettingsResponse.status === 503) {
                CustomNotify({ type: CONST.ERROR, message: t(soundSettingsResponse.error) });
            } else {
                CustomNotify({ type: CONST.ERROR, message: t("Recording is temporarily unavailable. Please contact the administrator for assistance.") });
            }

            if (salesnote.navigate === CONST.CALL_3CX && salesnote.isInBright) {
                handleFollowUp();
            }

            setTimeout(() => {
                if (salesnote.navigate === CONST.CALL_3CX) {
                    const url = salesnote.phone ? `${PHONE_CALL_URL}${salesnote.phone.replace('+', '%2B')}` : '';
                    window.open(url, '_blank');
                }
            }, 1000);

            setSalesNote((prev: any) => ({
                ...prev,
                activeRecordingId: null,
                triggerMeeting: false,
                isPlay: false,
            }));
        }
    };

    const stopRecording = async () => {
        setSalesNote((prev: any) => ({
            ...prev,
            activeRecordingId: null,
            triggerMeeting: false,
            isPlay: false,
        }));
        const recording = {
            isRecording: false
        }
        dispatch(addUserDetails(recording))

        const data = {
            agentId: authUser.userId,
        };
        setModalDetails((prev) => ({
            ...prev,
            showModal: false,
        }));

        const stopRecordingResponse = await ApiCall.service(
            STOP_RECORDING,
            'POST',
            data,
            true,
            CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (stopRecordingResponse.status === 200) {
            CustomNotify({ type: CONST.SUCCESS, message: t('Recording stopped successfully') });
            if (modalDetails.modalType === STOP_RECORDING) {
                handleMeeting();
            }
        }
        else if (stopRecordingResponse.status === 503) {
            CustomNotify({ type: CONST.ERROR, message: t(stopRecordingResponse.error) });
            if (salesnote.navigate === CONST.CALL_3CX && salesnote.isInBright) {
                handleFollowUp();
            }

            setTimeout(() => {
                if (salesnote.navigate === CONST.CALL_3CX) {
                    const url = salesnote.phone ? `${PHONE_CALL_URL}${salesnote.phone.replace('+', '%2B')}` : '';
                    window.open(url, '_blank');
                }
            }, 3000);
        }
        else {
            CustomNotify({ type: CONST.ERROR, message: t('An error occurred while stopping the recording') });
        }
    };

    const closeModal = (modalType?: string, closeType?: string) => {
        setSoundSettingsModalOpen(false);

        if (modalType === CONST.DEVICE) {
            setShowMeetingTypesModal(true);
        } else if (modalType === CONST.MEETING_TYPE) {
            setShowMeetingTypesModal(false);
        }

        if (closeType === CONST.CANCEL) {
            setSalesNote((prev: any) => ({
                ...prev,
                activeRecordingId: null,
                isPlay: false
            }));
        }

        setSalesNote((prev: any) => ({
            ...prev,
            triggerMeeting: false
        }))

        if (modalDetails.modalType === STOP_RECORDING && modalDetails.showModal === true) {

            const user = salesnote
            setSalesNote({
                ...user,
                activeRecordingId: prevId,
                triggerMeeting: false,
                isPlay: authUser.isRecording === true ? true : false
            });
        } else if (modalDetails.modalType === CONST.CONFIRM && modalDetails.showModal === true) {
            if (salesnote.navigate === CONST.CALL_3CX && salesnote.isInBright) {
                handleFollowUp();
            }

            if (salesnote.navigate === CONST.CALL_3CX) {
                const url = salesnote.phone ? `${PHONE_CALL_URL}${salesnote.phone.replace('+', '%2B')}` : '';
                window.open(url, '_blank');
            }

            setSalesNote((prev: any) => ({
                ...prev,
                activeRecordingId: null,
                isPlay: false
            }));
        }
        setModalDetails((prev) => ({
            ...prev,
            modalType: '',
            showModal: false,
        }));
    };

    // Automatically call handleMeeting when the trigger is fired
    useEffect(() => {
        if (salesnote.triggerMeeting) {
            handleMeeting(); // Trigger the meeting when `triggerMeeting` is invoked
        }
        if (salesnote.prevId) {
            setPrevId(salesnote.prevId);
        }
    }, [salesnote.triggerMeeting, salesnote.prevId]);


    //FollowUp
    const initialData = {
        id: null,
        office: null,
        type: null,
        typeId: null
    }
    const [showModal, setShowModal] = useState(false);
    const [entity, setEntity] = useState<EntityData>(initialData);
    const handleModalClose = () => {
        setShowModal(false);
        setEntity(initialData);
    };

    const refresh = () => {
        handleModalClose();
    }

    const handleFollowUp = () => {
        setEntity((prev) => ({
            ...prev,
            id: null,
            type: salesnote.type === 1 ? 'candidate' : (salesnote.type === 2 ? 'company' : ''),
            typeId: ((salesnote.type === 1 || salesnote.type === 2) && salesnote.activeRecordingId) ? salesnote.activeRecordingId : null,
            taskType: salesnote.type === 1 ? 3 : (salesnote.type === 2 ? 2 : null),
        }));
        setShowModal(true);
    };

    return (
        <div>
            <MeetingTypesModal
                showModal={showMeetingTypesModal}
                closeModal={closeModal}
                salesnote={salesnote}
                setSalesNote={setSalesNote}
                setPrevId={setPrevId}
            />
            {soundSettingsResponse && (
                <DeviceSelectionModal
                    response={soundSettingsResponse}
                    closeModal={closeModal}
                    showModal={soundSettingsModalOpen}
                />
            )}
            <ModalPopup
                show={modalDetails.showModal}
                title={modalDetails.title}
                body={modalDetails.body}
                onCloseButtonClick={closeModal}
                onConfirmButtonClick={modalDetails.modalType === STOP_RECORDING ? stopRecording : modalDetails.modalType === CONST.CONFIRM ? recordConfirmYes : () => { }}
                closeTitle={modalDetails.closeTitle}
                confirmTitle={modalDetails.confirmTitle}
                showCloseButton={modalDetails.showCloseButton}
            />
            {(salesnote.type === 1 || salesnote.type === 2) && showModal &&
                <SuccessionPopup
                    entity={entity}
                    show={showModal}
                    onHide={handleModalClose}
                    refresh={refresh}
                />
            }
        </div>
    );
};

export default SalesNote;
