import React, { useEffect, useState } from "react";
import { t } from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import Calender from "components/common/molecules/Calender";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { ApiCall } from "components/common/services/ApiServices";
import { Modal } from "react-bootstrap";
import {
  DELETE_REMINDER,
  GET_REMINDER_DETAILS,
  STORE_REMINDER_DETAILS,
} from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateRequired,
} from "components/common/services/ValidationService";
import { format } from "date-fns";

interface ReminderProps {
  entityTypeId: number;
  entitySubType: {
    subType: {
      value: number;
      label: string;
    };
    intial: boolean;
  };
  entityId?: string;
  onDropdownDataFetch: (data: any) => void;
}

const Reminder: React.FC<ReminderProps> = ({
  entityTypeId,
  entitySubType,
  entityId,
  onDropdownDataFetch,
}) => {
  const initialData = {
    reminderId: null,
    reminderMessage: "",
    fromDate: null as Date | null,
    toDate: null as Date | null,
    lastModifiedAt: null as Date | null,
    lastModifiedBy: "",
  };

  const [reminderDetails, setReminderDetails] = useState(initialData);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  const handleFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReminderDetails({
      ...reminderDetails,
      reminderMessage: e.target.value,
    });
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setReminderDetails({
      ...reminderDetails,
      [name]: date ? format(date, "yyyy-MM-dd") : null,
    });
  };

  useEffect(() => {
    if (entityId) {
      getReminderDetails();
    }

    if (entitySubType.intial === false) {
      setShow(true);
    }
  }, [entityId, entitySubType]);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      reminderMessage: [validateRequired],
    };
    const validationErrors = validateForm(
      { ...reminderDetails, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      setShow(false);
      const payload = {
        entityTypeId,
        entitySubType: entitySubType.subType,
        entityId,
        ...reminderDetails,
      };
      const response = await ApiCall.service(
        STORE_REMINDER_DETAILS,
        "POST",
        payload,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
      } else {
        CustomNotify({ type: "error", message: t('Some error occurred') });
        console.error("Failed to create reminder", response.data);
      }
    }
  };

  const getReminderDetails = async () => {
    try {
      const payload = {
        entityTypeId,
        entitySubType: entitySubType.subType,
        entityId,
        isInitial: entitySubType.intial,
      };
      const response = await ApiCall.service(
        GET_REMINDER_DETAILS,
        "POST",
        payload,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        if (Object.values(response?.data?.reminderData || {}).length > 0) {
          setReminderDetails(response.data.reminderData);
          setShow(true);
        } else {
          setReminderDetails(initialData);
        }
        onDropdownDataFetch(response.data.dropdownData);
      }
    } catch (error) {
      console.error("Error while fetching reminder details:", error);
    }
  };

  const handleDelete = async () => {
    try {
      setShow(false);
      const payload = {
        reminderId: reminderDetails.reminderId,
      };
      const response = await ApiCall.service(
        DELETE_REMINDER,
        "POST",
        payload,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
      } else {
        CustomNotify({ type: "error", message: t('Some error occurred') });
      }
    } catch (error) {
      console.error("Error while fetching reminder details:", error);
    }
  };

  const entityTypes: { [key: number]: string } = {
    1: "Company",
    2: "Candidate",
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="reminderPopup"
      >
        <Modal.Header className="px-0" closeButton>
          <Modal.Title>{t("Reminder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {entitySubType.intial ? (
            <div>
              <strong>
                {t(entityTypes[entityTypeId as keyof typeof entityTypes])}
              </strong>
              : {reminderDetails.reminderMessage}
            </div>
          ) : (
            <div>
              <div className="marginBotttom1 roobert-semibold">
                {entitySubType?.subType?.label ?? ""}
              </div>
              <div>
                <LabelWithTextAreaField
                  name="reminderMsg"
                  handleChange={handleFieldChange}
                  value={reminderDetails.reminderMessage}
                  label={t("Reminder message")}
                  error={errors.reminderMessage}
                  isMandatory={true}
                />
              </div>
              <div className="d-flex align-items-center" style={{ gap: "1vw" }}>
                <div className="position-relative hideHeight w-50">
                  <Calender
                    onChange={(date) => handleDateChange(date, "fromDate")}
                    selectedDate={
                      reminderDetails.fromDate
                        ? new Date(reminderDetails.fromDate)
                        : null
                    }
                    label={t("Show from")}
                    name="fromDate"
                    maxDate={reminderDetails.toDate}
                  />
                </div>
                <div className="position-relative hideHeight w-50">
                  <Calender
                    onChange={(date) => handleDateChange(date, "toDate")}
                    selectedDate={
                      reminderDetails.toDate
                        ? new Date(reminderDetails.toDate)
                        : null
                    }
                    label={t("Show until")}
                    name="toDate"
                    minDate={reminderDetails.fromDate}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        {!entitySubType.intial && (
          <Modal.Footer className="border-0 d-flex align-items-center justify-content-between px-0">
            <div className="w-75">
              {reminderDetails.reminderId !== null && (
                <div className="d-flex align-items-center">
                  <Button
                    handleClick={handleDelete}
                    className="deletebtn shadow-none mb-1"
                  >
                    {t("To delete")}
                  </Button>
                  <div className="w-75">
                    {t("Last modified")}:{" "}
                    {reminderDetails.lastModifiedAt
                      ? new Date(reminderDetails.lastModifiedAt).toLocaleString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      : ""}{" "}
                    {t("by")} {reminderDetails.lastModifiedBy || ""}
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-end m-0">
              <div>
                <Button
                  handleClick={handleClose}
                  className="delete-btn shadow-none"
                >
                  {t("Close")}
                </Button>
              </div>
              <div>
                <Button
                  handleClick={handleSubmit}
                  className="mx-0 form-button shadow-none"
                >
                  {t("Save")}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default Reminder;
