import React, { useState, useEffect } from 'react';
import { ApiCall } from 'components/common/services/ApiServices';
import ModalPopup from 'components/common/atoms/ModalPopup';
import RadioField from 'components/common/atoms/RadioField';
import { CREATE_MEETING_IN_SALES_NOTE, GET_MEETING_TYPES, PHONE_CALL_URL } from 'routes/ApiEndpoints';
import { CALL_3CX, CANCEL, CENTRAL_DATA_MANAGEMENT_MICROSERVICE, ERROR, MEETING_TYPE, SUCCESS } from 'Constants';
import CustomNotify from 'components/common/atoms/CustomNotify';
import { t } from '../translation/Translation';
import LabelField from 'components/common/atoms/LabelField';
import secureLocalStorage from "react-secure-storage";
import { LabelWithInputField } from 'components/common/molecules/LabelWithInputField';
import { ValidationRules } from 'components/common/utlis/TypeAnnotations';
import { validateForm, validateRadioField, validateRequired } from 'components/common/services/ValidationService';
import { addUserDetails } from 'features/auth/AuthSlice';
import { useDispatch } from 'react-redux';
import CheckBoxField from 'components/common/atoms/CheckBoxField';
import SuccessionPopup from '../taskDeterminer/SuccessionPopup';
import { EntityData } from 'components/common/atoms/RightSideBar';

export interface SalesNote {
    name: string;
    phone?: string;
    id?: number | null;
    companyId?: number | null;
    navigate?: string;
    title?: string;
    activeRecordingId?: number | null;
    triggerMeeting?: boolean;
    type?: number | null;
    isMeetingTitle?: boolean;
    isPlay?: boolean;
    isInBright?: boolean;
}

interface MeetingTypesModalProps {
    showModal: boolean;
    closeModal: (modalType?: string, closeType?: string) => void;
    salesnote: SalesNote;
    setSalesNote: (salesnote: SalesNote) => void;
    setPrevId?: (id: number | null | undefined) => void;
}

interface Meeting {
    id: number;
    name: string;
}

interface Language {
    id: number;
    name: string;
    key: string;
}

const MeetingTypesModal: React.FC<MeetingTypesModalProps> = ({ showModal, closeModal, salesnote, setSalesNote, setPrevId }) => {
    const [meetingTypes, setMeetingTypes] = useState<Meeting[] | null>(null);
    let langKey = secureLocalStorage.getItem("site_lang") ?? "en";
    const [languages, setLanguages] = useState<Language[] | null>(null);
    const [formData, setFormData] = useState({
        meetingType: null as number | string | null,
        languageKey: null as string | null,
        title: '' as string,
        isOnlineMeeting: true as boolean
    })
    const [errors, setErrors] = useState({
        meetingType: '',
        languageKey: '',
        title: ''
    });
    const dispatch = useDispatch();

    const validation = (
        name: string,
        value: string | boolean | null | undefined,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            meetingType: [validateRadioField],
            languageKey: [validateRequired],
            title: (salesnote.isPlay && salesnote.isPlay == true) ? [validateRequired] : [],
        };

        const validationErrors = validateForm(
            { ...formData, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        if (isSingleFieldValidation && Object.keys(errors).length > 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors({
                meetingType: validationErrors.meetingType || '',
                languageKey: validationErrors.languageKey || '',
                title: validationErrors.title || '',
            });
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    // Prefill the language key if languageKey matches any of the languages
    const prefillLanguageKey = (languages: Language[]) => {
        if (langKey === 'en-source') {
            langKey = 'en';
        }
        const matchedLanguage = languages.find(language => language.key.split('-')[0] === langKey); // Match by language prefix (e.g., 'en' or 'nl')
        if (matchedLanguage) {
            setFormData(prev => ({
                ...prev,
                languageKey: matchedLanguage.key
            }));
        }
    };

    // Fetch meeting types when modal is opened
    const fetchMeetingTypes = async () => {
        const meetingTypesResponse = await ApiCall.getService(GET_MEETING_TYPES, 'GET', CENTRAL_DATA_MANAGEMENT_MICROSERVICE, true);
        if (meetingTypesResponse.status === 200) {
            setMeetingTypes(meetingTypesResponse?.data?.meetingTypes);
            setLanguages(meetingTypesResponse?.data?.languages)
            if (meetingTypesResponse?.data?.languages) {
                prefillLanguageKey(meetingTypesResponse.data.languages);
            }
        }
    };

    useEffect(() => {
        if (showModal) {
            fetchMeetingTypes();
        }
    }, [showModal]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
        const { value, type } = event.target;
        if (type === 'checkbox') {
            const newValue = event.target instanceof HTMLInputElement
                ? event.target.checked
                : false;
            setFormData((prev) => ({
                ...prev,
                isOnlineMeeting: newValue
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));
            validation(name, value, true)
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const { name, value } = e.target as HTMLInputElement | HTMLSelectElement

        if (validation(name, value)) {
            const data = {
                type: formData.meetingType,
                user: salesnote.name,
                id: salesnote.id,
                companyId: salesnote?.companyId ?? null,
                title: formData.title,
                languageKey: formData.languageKey,
                entityId: salesnote.activeRecordingId,
                entityType: salesnote.type,
                isOnlineMeeting: formData.isOnlineMeeting
            };

            // Open phone call URL
            if (salesnote.navigate === CALL_3CX && salesnote.isInBright) {
                handleFollowUp();
            }

            if (salesnote.navigate === CALL_3CX) {
                const url = salesnote.phone ? `${PHONE_CALL_URL}${salesnote.phone.replace('+', '%2B')}` : '';
                window.open(url, '_blank');
            }

            // Make the API call to create a meeting
            const response = await ApiCall.service(
                CREATE_MEETING_IN_SALES_NOTE,
                'POST',
                data,
                true,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );

            if (response.status === 200) {
                CustomNotify({ type: SUCCESS, message: t("Recording started successfully") });
            }
            else if (response.status === 503) {
                CustomNotify({ type: ERROR, message: t(response.error) });
            }
            else {
                CustomNotify({ type: ERROR, message: t("Recording is temporarily unavailable. Please contact the administrator for assistance.") });
            }

            setFormData((prev) => ({
                ...prev,
                meetingType: null,
                languageKey: null
            }));
            setErrors((prev) => ({
                ...prev,
                languageKey: "",
                meetingType: ""
            }));

            closeModal(MEETING_TYPE); // Close the modal after handling
            setPrevId && setPrevId(salesnote.activeRecordingId ?? null);

            // Update the user
            setSalesNote({
                ...salesnote,
                name: '',
                phone: '',
                id: null,
                navigate: '',
                companyId: null,
                title: '',
                isMeetingTitle: false,
            });
            if (salesnote.isPlay) {
                const recording = {
                    isRecording: true
                }
                dispatch(addUserDetails(recording))
            }
        }
    };

    const closeMeetingTypeModal = () => {
        closeModal(MEETING_TYPE, CANCEL);
        setFormData((prev) => ({
            ...prev,
            meetingType: null,
            languageKey: null,
            title: '',
            isOnlineMeeting: true
        }));
        setErrors((prev) => ({
            ...prev,
            languageKey: "",
            meetingType: "",
            title: ""
        }));
    }

    //FollowUp
    const initialData = {
        id: null,
        office: null,
        type: null,
        typeId: null
    }
    const [showFollowUpModal, setShowFollowUpModal] = useState(false);
    const [entity, setEntity] = useState<EntityData>(initialData);
    const handleModalClose = () => {
        setShowFollowUpModal(false);
        setEntity(initialData);
    };

    const refresh = () => {
        handleModalClose();
    }

    const handleFollowUp = () => {
        setEntity((prev) => ({
            ...prev,
            id: null,
            type: salesnote.type === 1 ? 'candidate' : (salesnote.type === 2 ? 'company' : ''),
            typeId: ((salesnote.type === 1 || salesnote.type === 2) && salesnote.activeRecordingId) ? salesnote.activeRecordingId : null,
            taskType: salesnote.type === 1 ? 3 : (salesnote.type === 2 ? 2 : null),
        }));
        setShowFollowUpModal(true);
    };

    return (
        <>
            <ModalPopup
                show={showModal}
                showCloseButton={false}
                title={t("Select meeting type and preferred language")}
                body={
                    <div>
                        <div className="col-sm-12 col-md-12">
                            <LabelField title={t("Meeting type")} isMandatory={true} />
                            {meetingTypes &&
                                meetingTypes.map((meeting: Meeting, index: number) => (
                                    <div className="col-sm-12 col-md-12" key={index}>
                                        <RadioField
                                            key={meeting.id}
                                            value={meeting.id}
                                            ischecked={formData.meetingType == meeting.id}
                                            handleChange={(e) => handleChange(e, 'meetingType')}
                                            label={meeting.name}
                                            name={'meetingType'}
                                        />
                                    </div>
                                ))}
                            <div className='text-danger mt-2'>
                                {t(errors.meetingType)}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 mt-4">
                            <>
                                <LabelField title={t("Language")} isMandatory={true} />
                                <select
                                    className="form-control field-shadow rounded-3 form-select"
                                    name="languageKey"
                                    id="languageKey"
                                    onChange={(e) => handleChange(e, 'languageKey')}
                                    value={formData.languageKey ?? ""}
                                >
                                    <option value="">{t("Select")}</option>
                                    {languages?.map((language: Language, key: any) => (
                                        <option key={key} value={language.key}>
                                            {t(`${language.name}`)}
                                        </option>
                                    ))}
                                </select>
                                <div className='text-danger mt-2'>
                                    {t(errors.languageKey)}
                                </div>
                            </>
                        </div>
                        <div className="col-sm-12 col-md-12 mt-4">
                            <CheckBoxField
                                label={t("Online meeting")}
                                name="isOnlineMeeting"
                                onChangeHandler={(e: any) => (handleChange(e, 'isOnlineMeeting'))}
                                isChecked={formData.isOnlineMeeting}
                                className="document-checkbox"
                                id="isOnlineMeeting"
                                lineHeight="1.1vw"
                            />
                        </div>
                        {salesnote.isMeetingTitle && <div className="col-sm-12 col-md-12 mt-4">
                            <LabelWithInputField
                                type="text"
                                label={t("Meeting title")}
                                name="title"
                                handleChange={(e) => (handleChange(e, 'title'))}
                                value={formData.title}
                                error={errors.title}
                                isMandatory={true}
                            />
                        </div>
                        }
                    </div>
                }
                onCloseButtonClick={closeMeetingTypeModal}
                onConfirmButtonClick={handleSubmit}
                closeTitle={t("Cancel")}
                confirmTitle={t("Save")}
            />
            {(salesnote.type === 1 || salesnote.type === 2) && showFollowUpModal &&
                <SuccessionPopup
                    entity={entity}
                    show={showFollowUpModal}
                    onHide={handleModalClose}
                    refresh={refresh}
                />
            }
        </>

    );
};

export default MeetingTypesModal;
