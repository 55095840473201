import { useFormContext } from "components/CompanyCreation/Company/context/Context";
import Button from "components/common/atoms/Button";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelField from "components/common/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import CloseFile from "static/images/CloseFile";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useState } from "react";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  scrollToTop,
  validateLinkedinUrl,
  validatePhoneNumberCanBeNull,
  validateEmailCanBeNull,
} from "../../../../common/services/ValidationService";
import Calender from "components/common/molecules/Calender";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Navigation from "../formNavigation/Navigation";
import CheckBoxField from "components/common/atoms/CheckBoxField";

const Contacts = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      // fName: [validateRequired, validateTextFieldAlpha],
      // lName: [validateRequired, validateTextFieldAlpha],
      email: [validateEmailCanBeNull],
      phNumber: [validatePhoneNumberCanBeNull],
      // dob: [validateDate],
      linkedIn: [validateLinkedinUrl],
    };
    const validationErrors = validateForm(
      { ...state.contacts[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_CONTACTS_FIELD_ERROR",
      field: name,
      error: validationErrors[name],
      index,
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.dynamicErrors[index][name] = "";
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      const checked = (event.target as HTMLInputElement).checked;
      const newValue = checked ? 1 : 0;
      dispatch({
        type: "UPDATE_CONTACTS_FIELD",
        field: name,
        value: newValue,
        index,
      });
      // validation(name, checked, index, true);
    } else {
      dispatch({ type: "UPDATE_CONTACTS_FIELD", field: name, value, index });
      validation(name, value, index, true);
    }
  };

  /* To prefill data with existing email (For future)
  const emailCheck = (
    event: React.FocusEvent<HTMLInputElement>,
    index: number
  ) => {
    setEmailUser({});
    const { name, value } = event.target;
    if (validation(name, value, index, true)) {
      checkForEmailExist(value, index);
    }
  };

  const checkForEmailExist = async (value: String, index: number) => {    
    const response = await ApiCall.getService(
      `${ENDPOINTS.CHECK_FOR_EMAIL_EXIST}/${value}`,
      "GET",
      "company-creation",
      false
    );

    if (response.status === 200) {
      setModalTitle(t("Continue with existing account"));
      setModalBody(t("User already exist with this email, Do you want to continue with this user details"));
      setShowModal(true);
      setEmailUser(response.data);
    }
  };
  */

  const handlePhoneNumberChange = (
    phNumber: string | undefined,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      index,
      field: "phNumber",
      value: phNumber,
    });
    validation("phNumber", phNumber, index, true);
  };
  const handleAddContact = () => {
    dispatch({ type: "ADD_CONTACT" });
  };

  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_CONTACT", indexToRemove });
  };

  const handleDateChange = (date: Date | null, name: string, index: number) => {
    const updatedDate = date !== null ? date : new Date();

    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: updatedDate,
      index,
    });
    // validation(name, updatedDate, index, true);
  };

  const currentDate = new Date();
  const below15 = new Date();
  below15.setFullYear(currentDate.getFullYear() - 15);
  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: selectedOption,
      index,
    });
    // validation(name, selectedOption, index, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              {state.contacts.map((contact, index) => (
                <div className="row" key={index}>
                  <div className="col-12">
                    <div
                      className="field-set internalContentWrapper marginBotttom1"
                      key={index}
                    >
                      {index > 0 && (
                        <div className="row">
                          <div className="col-md-12">
                            <span
                              title={t("Delete")}
                              onClick={() => handleRemoveContact(index)}
                              className="table-action-icons cursor-pointer float-end  rounded-0 shadow-none"
                            >
                              <CloseFile />
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-sm-12 col-lg-2 col-md-4">
                          <SelectWithSearch
                            title={t("Title")}
                            name="title"
                            isMandatory={false}
                            search={true}
                            options={state.genders}
                            value={contact.title}
                            onChange={(e) =>
                              handleSelectChange(e, "title", index)
                            }
                            isMulti={false}
                            error={state.dynamicErrors[index]?.title}
                            isTranslate={true}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-5 col-md-4">
                          <LabelWithInputField
                            // isMandatory={true}
                            name="fName"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={contact.fName ?? ""}
                            id={`fname-${index}`}
                            label={t("First name")}
                            placeholder={t("First name")}
                            error={state.dynamicErrors[index]?.fName}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-5 col-md-4">
                          <LabelWithInputField
                            // isMandatory={true}
                            name="lName"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={contact.lName ?? ""}
                            id={`lname-${index}`}
                            label={t("Last name")}
                            placeholder={t("Last name")}
                            error={state.dynamicErrors[index]?.lName}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-4 col-md-6">
                          <LabelWithInputField
                            // isMandatory={true}
                            name="email"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={contact.email ?? ""}
                            // handleBlur={(event) => emailCheck(event, index)}
                            id={`email-${index}`}
                            label={t("Email")}
                            placeholder={t("Email")}
                            type="email"
                            error={state.dynamicErrors[index]?.email}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-4 col-md-6">
                          <LabelField
                            title={t("Phone number")}
                            // isMandatory={true}
                            key="PhoneInput"
                          />
                          <div
                            className="form-control field-shadow d-flex"
                            style={{ borderRadius: "0.5vw" }}
                          >
                            <PhoneInput
                              defaultCountry="BE"
                              international
                              placeholder={t("Enter phone number")}
                              onChange={(phNumber) =>
                                handlePhoneNumberChange(phNumber, index)
                              }
                              value={contact.phNumber}
                              className="w-100"
                            />
                          </div>
                          <div
                            className="height-20 text-danger"
                            style={{ marginBottom: "0.5vw" }}
                          >
                            {state.dynamicErrors[index]?.phNumber && (
                              <span className="text-danger">
                                {state.dynamicErrors[index]?.phNumber}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-4 col-md-6 position-relative">
                          <Calender
                            onChange={(e) => handleDateChange(e, "dob", index)}
                            selectedDate={
                              contact.dob ? new Date(contact.dob) : null
                            }
                            label={t("Date of birth")}
                            // isMandatory={true}
                            name="dob"
                            maxDate={below15}
                            error={
                              state.dynamicErrors[index]?.dob
                                ? state.dynamicErrors[index]?.dob.toString()
                                : undefined
                            }
                          />
                        </div>
                        {/* <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={false}
                        name="teleNumber"
                        handleChange={(event) => handleFieldChange(event, index)}
                        value={contact.teleNumber ?? ''}
                        id={`teleNumber-${index}`}
                        label={t("Telephone")}
                        placeholder={t("Telephone")}
                        type="number"
                        error={state.dynamicErrors[index]?.teleNumber}
                      />
                    </div> */}
                        <div className="col-sm-12 col-lg-4 col-md-6">
                          <LabelWithInputField
                            isMandatory={false}
                            name="gsm"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={contact.gsm ?? ""}
                            id={`gsm-${index}`}
                            label={t("Gsm")}
                            placeholder={t("Gsm")}
                            type="text"
                            error={state.dynamicErrors[index]?.gsm}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-4 col-md-6">
                          <SelectWithSearch
                            title={t("Role")}
                            name="roles"
                            isMandatory={false}
                            search={true}
                            options={state.rolesList}
                            value={contact.roles}
                            onChange={(e) =>
                              handleSelectChange(e, "roles", index)
                            }
                            isMulti={true}
                            isTranslate={true}
                          />
                          {/* <LabelWithInputField
                        isMandatory={false}
                        name="contact"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={contact.contact ?? ""}
                        isDisabled={false}
                        id={`contact-${index}`}
                        label={t("Contact")}
                        placeholder={t("Contact")}
                        type="text"
                        error={state.dynamicErrors[index]?.contact}
                      /> */}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <LabelWithInputField
                            isMandatory={false}
                            name="functionTitle"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={contact.functionTitle ?? ""}
                            id={`functionTitle-${index}`}
                            label={t("Function")}
                            placeholder={t("Function")}
                            type="text"
                            error={state.dynamicErrors[index]?.functionTitle}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-4 col-md-6">
                          <SelectWithSearch
                            title={t("Preferred language")}
                            name="language"
                            isMandatory={false}
                            search={true}
                            options={state.languages}
                            value={contact.language}
                            onChange={(e) =>
                              handleSelectChange(e, "language", index)
                            }
                            isMulti={false}
                            error={state.dynamicErrors[index]?.language}
                            isTranslate={true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <LabelWithInputField
                            name="linkedIn"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={contact.linkedIn ?? ""}
                            id="linkedIn"
                            label={t("Linkedin website url")}
                            type="text"
                            placeholder={t("Linkedin")}
                            error={state.dynamicErrors[index]?.linkedIn}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-4 col-md-6">
                          <SelectWithSearch
                            title={t("Locations")}
                            name="locations"
                            search={true}
                            options={state.locations}
                            value={contact.locations}
                            onChange={(e) =>
                              handleSelectChange(e, "locations", index)
                            }
                            isMulti={true}
                            isTranslate={true}
                          />
                        </div>
                        {/* <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        title="Function"
                        name="role"
                        isMandatory={false}
                        search={true}
                        options={state.rolesList}
                        placeHolder="Select"
                        value={contact.roles ?? ""}
                        onChange={(e) => handleSelectChange(e, "roles", index)}
                        className="select-field"
                      />
                    </div> */}
                        {/* <div className="col-md-12 mb-3">
                      <CheckBoxField
                        label={t("Decision maker")}
                        name="decisionMaker"
                        onChangeHandler={(event) =>
                          handleFieldChange(event, index)
                        }
                        className="me-5 pe-4 mb-3 mb-xl-0"
                        isChecked={contact.decisionMaker}
                      />
                      <CheckBoxField
                        label={t("Influencer")}
                        name="influencer"
                        onChangeHandler={(event) =>
                          handleFieldChange(event, index)
                        }
                        className="me-5 pe-4 mb-3 mb-xl-0"
                        isChecked={contact.influencer}
                      />
                      <CheckBoxField
                        label={t("Contact may be called")}
                        name="contactCalled"
                        onChangeHandler={(event) =>
                          handleFieldChange(event, index)
                        }
                        className="me-5 pe-4 mb-3 mb-xl-0"
                        isChecked={contact.contactCalled}
                      />
                      <CheckBoxField
                        label={t("Contact may be emailed")}
                        name="contactEmailed"
                        className="mb-3 mb-lg-0"
                        onChangeHandler={(event) =>
                          handleFieldChange(event, index)
                        }
                        isChecked={contact.contactEmailed}
                      />
                    </div> */}
                        <div className="col-md-12 marginBotttom1">
                          <CheckBoxField
                            label={t("Decision maker")}
                            name="allowSignature"
                            onChangeHandler={(event) =>
                              handleFieldChange(event, index)
                            }
                            isChecked={contact.allowSignature}
                            id={`allowSignature-${contact.id}`}
                            lineHeight="1vw"
                          />
                        </div>
                        <div className="col-lg-12 hideHeight marginBottomPoint5">
                          <LabelWithTextAreaField
                            name="info"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={contact.info ?? ""}
                            label={t("Info")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="col-md-12">
                  <Button
                    title={"+ " + t("Add another")}
                    handleClick={handleAddContact}
                    className="form-button float-end shadow-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default translate(Contacts);
