import React, { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import Reset from "static/images/Reset";
import LinkTo from "components/common/atoms/LinkTo";
import "../defaultLowCoefficents/defaultLowCoefficient.css";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { FormatPCList } from "components/common/utlis/FormatPCList";
import NoRecords from "components/common/atoms/NoRecords";

interface manageData {
  regionName: string;
  countryName: string;
  id: number;
  pcCoeffId: number;
  pcname: string;
}

interface dropDownsProps {
  region: OptionProps[];
  country: OptionProps[];
  pcId: Option | null;
}

const ManagePcCoefficients: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<dropDownsProps>({
    region: [],
    country: [],
    pcId: null,
  });

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    region: [] as OptionProps[],
    country: [] as OptionProps[],
    pcList: [] as OptionProps[],
  });

  const navigate = useNavigate();

  //state for to store the functions linked countries
  const [countries, setCountries] = useState([] as manageData[]);
  const [selectedRegion, setSelectedRegion] = useState<number[]>([]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCountryBySearch();
  }, []);

  //page index based on page number
  // let pageIndex = (currentPage - 1) * 20 + 1;

  //API call for to fetch countries
  const fetchCountryBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "pcCoefficient",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const regions = response.data["regions"];
      const countries = response.data["countries"];
      const pcOptions = FormatPCList(response.data["pcList"]);
      setDropDowns({
        region: regions,
        country: countries,
        pcList: pcOptions,
      });
      setCountries(response.data["data"]);
      setTotalPages(response.data["totalPages"]);
    }
  };

  const handleCountryChange = (selectedCountryIds: any) => {
    const region = dropDowns.country
      .filter((item: any) => selectedCountryIds.includes(item.id))
      .map((item: any) => item.region_id);

    const regionIds = Array.from(new Set(region));
    const selectedRegions = dropDowns.region
      .filter((item) => regionIds.includes(item.id))
      .map((region) => ({ value: region.id, label: region.name }));
    setFormData((prevData) => ({
      ...prevData,
      region: selectedRegions,
    }));
  };

  const getCountriesForRegion = () => {
    if (selectedRegion.length > 0) {
      const regionBasedCountries = dropDowns.country.filter((item: any) =>
        selectedRegion.includes(item.region_id)
      );
      return mapToSelect(regionBasedCountries);
    }

    return mapToSelect(dropDowns.country);
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "country") {
      const selectedCountryIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleCountryChange(selectedCountryIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "region") {
      const selectedRegionIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      setSelectedRegion(selectedRegionIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      formData.region.length === 0 &&
      formData.country.length === 0 &&
      formData.pcId === null
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchCountryBySearch();
    } else {
      fetchCountryBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      region: [],
      country: [],
      pcId: null,
    });
    setCurrentPage(1);
    fetchCountryBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchCountryBySearch(formData, newPage);
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("PC coefficients")} />
        <div className="form-height-dashboard">
          <div className="row search-bar pb-3">
            <div className="col-3">
              <SelectWithSearch
                search={true}
                options={mapToSelect(dropDowns.region)}
                onChange={(e) => handleSelectChange(e, "region")}
                isMulti={true}
                name="region"
                title={t("Region")}
                value={formData.region}
                isTranslate={true}
              />
            </div>
            <div className="col-3">
              <SelectWithSearch
                search={true}
                options={getCountriesForRegion()}
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={true}
                name="country"
                title={t("Country")}
                value={formData.country}
                isTranslate={true}
              />
            </div>
            <div className="col-3">
              <SelectWithSearch
                search={true}
                options={dropDowns.pcList}
                onChange={(e) => handleSelectChange(e, "pcId")}
                isMulti={false}
                name="pcId"
                title={t("Paritair committee")}
                value={formData.pcId}
                isTranslate={true}
              />
            </div>
            <div className="col-3 mt-34">
              <Button
                title=""
                type="reset"
                handleClick={handleReset}
                className="delete-btn shadow-none reset-btns"
              >
                <span title={t("Reset")}>
                  <Reset />
                </span>
              </Button>
              <Button
                title={t("Search")}
                type="submit"
                icon={faMagnifyingGlass}
                handleClick={handleSubmit}
                className="form-button shadow-none search-btns position-relative text-start"
              />
            </div>
          </div>

          {/* Display the table of city profiles */}
          <div className="position-relative tableMainWrapper">
            <div className="d-none d-lg-block">
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/add/pc/coefficient"
                    title={t("Add pc coefficients")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="row">
                <div className="col-md-6 ms-auto mb-3">
                  <LinkTo
                    pagelink="/add/pc/coefficient"
                    title={t("Add pc coefficients")}
                    icon={faPlus}
                    className="w-100 align-items-center justify-content-center"
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive tableSection">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4">{t("Region")}</th>
                    <th>{t("Country")}</th>
                    <th>{t("Paritair committee")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {countries && countries.length > 0 ? (
                    countries.map((country, index) => (
                      <tr
                        key={index}
                        className="border-bottom mb-3 box-shadow align-middle"
                      >
                        {/* <td className="ps-lg-4">{pageIndex++}</td> */}
                        <td className="ps-lg-4">
                          {t(`${country.regionName}`)}
                        </td>
                        <td>{t(`${country.countryName}`)}</td>
                        <td>{country.pcname}</td>
                        <td className="table-action-icons px-2">
                          <div className="d-none d-md-none d-lg-block">
                            <Link
                              to={`/edit/pc/coefficient/${country.pcCoeffId}`}
                              className="btn p-0 border-0 me-2"
                              title={t("Edit")}
                            >
                              <EditIcon />
                            </Link>
                          </div>
                          <div className="d-block d-md-block d-lg-none">
                            <Link
                              to={`/edit/pc/coefficient/${country.pcCoeffId}`}
                              className="btn p-0 border-0 w-100"
                              title={t("Edit")}
                            >
                              <Button
                                title={t("Edit")}
                                className=" mb-2 button-width edit-btn rounded-3"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={7} />
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4 px-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 align-self-center py-4">
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(ManagePcCoefficients);
