import React from 'react'

const User:React.FC = () => {
  return (
    <><svg xmlns="http://www.w3.org/2000/svg" width="1.8vw" height="21.8w" viewBox="0 0 41.284 41.284">
    <path id="User_R_" data-name="User (R)" d="M20.641,41.284A20.626,20.626,0,0,1,0,20.643,20.741,20.741,0,0,1,2.279,11.2,20.4,20.4,0,0,1,9.7,3.128,20.651,20.651,0,1,1,20.641,41.284Zm-.14-19.5a11.972,11.972,0,0,0-6.739,2.031,2.178,2.178,0,0,0-.951,1.813v3.807H28.189V25.63a2.181,2.181,0,0,0-.947-1.813A11.983,11.983,0,0,0,20.5,21.786Zm0-9.923a4.395,4.395,0,1,0,3.105,1.287A4.364,4.364,0,0,0,20.5,11.863Z" fill="#e55496"/>
  </svg>
  </>
  )
}

export default User