import React, { useEffect, useState } from "react";
import { CommonPcCoefficients, dropDownSelectedType } from "./Annotations";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Option,
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Title from "components/common/atoms/Title";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";
import RenderInputFields from "./RenderInputFields";
import "../defaultLowCoefficents/defaultLowCoefficient.css";
import {
  scrollToTop,
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import CustomNotify from "components/common/atoms/CustomNotify";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

const DefaultCoefficients = () => {
  const [data, setData] = useState<CommonPcCoefficients>({});
  const [dropDowns, setDropDowns] = useState({
    regionDropdown: [] as OptionProps[],
    countryDropdown: [] as OptionProps[],
    workUnitDropDown: [] as OptionProps[],
  });
  const [dropDownSelected, setDropDownSelected] =
    useState<dropDownSelectedType>({
      region: null,
      country: null,
      workUnit: null,
    });
  const [coeffType, setCoeffType] = useState({
    coeffName: [],
    type: [],
  });
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [error, setError] = useState(false);
  const initialdataErrors: { [key: string]: string } = {};
  const [dataError, setDataError] = useState<{ [key: string]: string }>(
    initialdataErrors
  );
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isEditMode = location.pathname.includes("edit");
  const navigate = useNavigate();
  const dependencies = isEditMode ? [] : [dropDownSelected.workUnit];

  useEffect(() => {
    const fetchNestedCofficientsObject = async () => {
      const url = ENDPOINTS.CONF_SETTINGS_EDIT;

      const data = {
        id: id,
        type: "defaultCoefficients",
        workUnit: dropDownSelected.workUnit,
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        const countryRegionDetails = response.data["countryRegion"];
        if (countryRegionDetails) {
          setDropDownSelected((prevData) => ({
            ...prevData,
            ...countryRegionDetails,
          }));
        }

        const regions = mapToSelect(response.data["regionList"]);
        const countries = response.data["countryList"];
        const unitList = mapToSelect(response.data["unitList"]);
        const coeffNames = response.data["coeffType"];
        const type = response.data["type"];
        setCoeffType({
          coeffName: coeffNames,
          type: type,
        });
        setDropDowns({
          regionDropdown: regions,
          countryDropdown: countries,
          workUnitDropDown: unitList,
        });
        setData(response.data["data"]);
      }
    };
    fetchNestedCofficientsObject();
  }, dependencies);

  const updateCofficientObj = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: number,
    empType: string,
    typeName: string,
    expLevelId: string,
    coeffId: string,
    index: string
  ) => {
    const value = event.target.value;
    const employeeType = parseInt(empType);
    const level = parseInt(expLevelId);
    const coeff = parseInt(coeffId);
    const currIndex = parseInt(index);

    const newData = { ...data };
    const newValue = value.replace(/[^0-9,]/g, "");
    const regex = /^(\d{1,2})(,\d{0,4})?$/;
    let matches = regex.exec(newValue);
    if (matches || value === "") {
      newData[category][employeeType][level]["coeffType"][coeff][currIndex][
        typeName
      ] = newValue;
    }

    const currentObject =
      newData[category][employeeType][level]["coeffType"][coeff];
    const min =
      typeof currentObject[1]["min"] === "string"
        ? currentObject[1]["min"]
        : "";
    const nice_to_have =
      typeof currentObject[2]["nice_to_have"] === "string"
        ? currentObject[2]["nice_to_have"]
        : "";

    // Ensure min and nice_to_have are primitive strings, not String objects
    if (
      min !== null &&
      min !== undefined &&
      nice_to_have !== null &&
      nice_to_have !== undefined &&
      typeof min === "string" &&
      typeof nice_to_have === "string" &&
      parseFloat(min.replace(",", ".")) >
        parseFloat(nice_to_have.replace(",", "."))
    ) {
      newData[category][employeeType][level]["coeffType"][coeff][2]["status"] =
        true;
      setError(true);
    } else {
      newData[category][employeeType][level]["coeffType"][coeff][2]["status"] =
        false;
      setError(false);
    }
    setData(newData);
  };

  //fetching countries list based on region
  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const regionBasedCountries = dropDowns.countryDropdown.filter(
        (country) =>
          country.region_id !== undefined &&
          selectedRegion === country.region_id
      );
      return mapToSelect(regionBasedCountries);
    }
    return [];
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setDropDownSelected((prevData) => ({
        ...prevData,
        country: null,
      }));
    }
    setDropDownSelected((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));

    dropDownsValidation(fieldName, selectedOption, true);
  };

  const dropDownsValidation = (
    name: string,
    value: string | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      region: [validateSelectField],
      country: [validateSelectField],
      workUnit: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...dropDownSelected, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(error).length > 0) {
      setDataError((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDataError(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const hasTrueValue = (obj: any): boolean => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        if (hasTrueValue(obj[key])) {
          return true; // If any nested object has true status, return true
        }
      } else if (key === "status" && obj[key] === true) {
        return true;
      }
    }

    return false; // If no true status is found, return false
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    if (dropDownsValidation(name, value)) {
      if (!hasTrueValue(data)) {
        setLoading(true);
        const payload = {
          countryRegionDetails: dropDownSelected,
          coefficientValues: data,
          id: id ? id : null,
          type: "defaultCoefficients",
        };
        const url = ENDPOINTS.CONF_SETTINGS_ADD;
        const response = await ApiCall.service(
          url,
          "POST",
          payload,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
          CustomNotify({
            type: "success",
            message: t(response.msg),
            autoClose: 2000,
          });
          navigate(-1);
        } else if (response.status === 400) {
          Object.entries(response.errors).map(([key, value]: [string, any]) =>
            // setDataError({ country: value[0] }),
            CustomNotify({
              type: "error",
              message: t(value[0]),
              autoClose: 2000,
            })
          );
        }
      } else {
        scrollToTop();
        setError(true);
      }
    } else {
      scrollToTop();
    }
    setLoading(false);
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Default coefficients",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title
        title={`${
          isEditMode
            ? t("Edit default coefficients")
            : t("Add default coefficients")
        }`}
      />
      <div>
        {error && (
          <div className="text-danger error-coefficients mb-2">
            <span>
              {t(
                "Please change the highlighted values, minimum value should be less than nice to have value."
              )}
            </span>
            <br />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="row search-bar">
            <div className="col-lg-4 col-md-6">
              <SelectWithSearch
                title={t("Region")}
                search={true}
                isMandatory={true}
                options={dropDowns.regionDropdown}
                isDisabled={isEditMode}
                onChange={(e) => handleSelectChange(e, "region")}
                isMulti={false}
                name="region"
                value={dropDownSelected.region ?? ""}
                error={dataError.region}
                isTranslate={true}
              />
            </div>
            <div className="col-lg-4 col-md-6">
              <SelectWithSearch
                title={t("Country")}
                search={true}
                isMandatory={true}
                isDisabled={isEditMode}
                options={getCountriesForRegion()}
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={false}
                name="country"
                value={dropDownSelected.country ?? ""}
                error={dataError.country}
                isTranslate={true}
              />
            </div>
            <div className="col-lg-4 col-md-6">
              <SelectWithSearch
                title={t("Work unit")}
                search={true}
                isMandatory={true}
                options={dropDowns.workUnitDropDown}
                isDisabled={isEditMode}
                onChange={(e) => handleSelectChange(e, "workUnit")}
                isMulti={false}
                name="workUnit"
                value={dropDownSelected.workUnit ?? ""}
                error={dataError.workUnit}
                isTranslate={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="defaultCoefficientHeight scrollBarDesign">
                <div className="row">
                  <div className="col-lg-5 px-0 firstPart col-md-7 col-fixed">
                    {data && Object.keys(data).length > 0 && (
                      <table className="table table-bordered defaultCoefficientTableLeftSection">
                        <thead>
                          <tr
                            className="TableHeader defaultCoefficientLeftSection"
                            style={{ height: "5.95vw" }}
                          >
                            <th rowSpan={2} className="text-center">
                              {t("Employee category types")}
                            </th>
                            <th
                              rowSpan={2}
                              className="text-center align-middle"
                              scope="rowgroup"
                            >
                              {t("Employee type")}
                            </th>
                            <th
                              rowSpan={2}
                              className="text-center align-middle"
                              scope="rowgroup"
                            >
                              {t("Level")}
                            </th>
                          </tr>
                          <tr className="TableHeader"></tr>
                        </thead>
                        <tbody>
                          {data &&
                            Object.keys(data).map(
                              (key: any) =>
                                data[key] &&
                                Object.entries(data[key]).map(
                                  ([key1, value]) => (
                                    <tr
                                      key={`emp-type-category-${key}-${key1}`}
                                      className="align-middle"
                                    >
                                      {value["categotyExist"] && (
                                        <th
                                          className="text-center"
                                          rowSpan={
                                            Object.keys(data[key]).length
                                          }
                                          scope="rowgroup"
                                        >
                                          {t(`${value["category"]}`)}
                                        </th>
                                      )}
                                      <th
                                        key={`emp-type-${key1}`}
                                        scope="rowgroup"
                                        className="employee-type-coefficient text-break"
                                      >
                                        {t(`${value["employeeType"]}`)}
                                      </th>
                                      <th className="p-0 text-break">
                                        {value &&
                                          Object.keys(value).map(
                                            (nestedKey, index) =>
                                              nestedKey !== "categotyExist" &&
                                              nestedKey !== "category" &&
                                              nestedKey !== "employeeType" && (
                                                <div
                                                  key={`level-${nestedKey}`}
                                                  className="pc-coefficient-level"
                                                >
                                                  <div className="employee-type-coefficient d-flex align-items-center ps-3 text-break">
                                                    {t(
                                                      `${value[nestedKey]["level"]}`
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                          )}
                                      </th>
                                    </tr>
                                  )
                                )
                            )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {data && Object.keys(data).length > 0 && (
                    <div className="col-lg-7 col-scrollable col-md-5 ps-0">
                      <table className="table table-bordered defaultCoefficientTableRightSection">
                        <thead className="thead">
                          <tr className="TableHeader">
                            {coeffType &&
                              coeffType.coeffName.length > 0 &&
                              coeffType.coeffName.map(
                                (value: any, key: number) => (
                                  <th
                                    key={`coeffName-${key}`}
                                    colSpan={Object.keys(coeffType.type).length}
                                    className="text-center"
                                    scope="colgroup"
                                    style={{ height: "2.5vw" }}
                                  >
                                    {t(`${value["coeff_name"]}`)}
                                  </th>
                                )
                              )}
                          </tr>
                          <tr className="TableHeader border-bottom-0">
                            {coeffType &&
                              coeffType.coeffName.length > 0 &&
                              coeffType.coeffName.map(
                                (value: any, key: number) =>
                                  Object.keys(coeffType.type).map(
                                    (value2: any, key2: number) => (
                                      <th
                                        className="text-center"
                                        key={`type-${key}-${key2}`}
                                        style={{ height: "3.45vw" }}
                                      >
                                        <span>{t(coeffType.type[value2])}</span>
                                      </th>
                                    )
                                  )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            Object.keys(data).map(
                              (key: any) =>
                                data[key] &&
                                Object.entries(data[key]).map(
                                  ([key1, value]) =>
                                    value &&
                                    Object.keys(value).map(
                                      (nestedKey, empIndex) =>
                                        nestedKey !== "categotyExist" &&
                                        nestedKey !== "category" &&
                                        nestedKey !== "employeeType" &&
                                        nestedKey !== "level" && (
                                          <tr
                                            key={`pc-coeff-${key}-${key1}-${nestedKey}`}
                                          >
                                            {Object.keys(
                                              value[nestedKey]["coeffType"]
                                            ).map(
                                              (
                                                coeffId: string,
                                                coeffIndex: any
                                              ) =>
                                                Object.entries(
                                                  value[nestedKey]["coeffType"][
                                                    coeffId
                                                  ]
                                                ).map(
                                                  ([levelKey, levelvalue]: [
                                                    string,
                                                    any
                                                  ]) =>
                                                    Object.entries(
                                                      value[nestedKey][
                                                        "coeffType"
                                                      ][coeffId][levelKey]
                                                    ).map(
                                                      ([type, typeValue]: [
                                                        string,
                                                        any
                                                      ]) =>
                                                        !type.includes(
                                                          "status"
                                                        ) ? (
                                                          <RenderInputFields
                                                            key={`inp-${nestedKey}-${coeffId}-${levelKey}-${key}`}
                                                            typeName={type}
                                                            typeValue={
                                                              typeValue
                                                            }
                                                            employeeCategoryId={
                                                              key
                                                            }
                                                            employeeType={key1}
                                                            expLevelId={
                                                              nestedKey
                                                            }
                                                            coeffId={coeffId}
                                                            index={levelKey}
                                                            errorStatus={
                                                              value[nestedKey][
                                                                "coeffType"
                                                              ][coeffId][
                                                                levelKey
                                                              ]["status"]
                                                            }
                                                            updateCofficientObj={
                                                              updateCofficientObj
                                                            }
                                                          />
                                                        ) : null
                                                    )
                                                )
                                            )}
                                          </tr>
                                        )
                                    )
                                )
                            )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row backPadding">
            <div className="col-md-4 align-self-center">
              <Link
                to=""
                className="back-btn text-decoration-underline"
                onClick={() => navigate(-1)}
              >
                {t("Back")}
              </Link>
            </div>
            {data && Object.keys(data).length > 0 && (
              <div className="col-md-8">
                {!loading ? (
                  <Button
                    title={t("Save")}
                    type="submit"
                    className="form-button float-end shadow-none"
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#ff4dae"
                    className="float-end"
                    height={"3vw"}
                    width={"3vw"}
                  />
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </AccessControl>
  );
};

export default translate(DefaultCoefficients);
