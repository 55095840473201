import React, { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import "./function.css";
import CustomNotify from "components/common/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import * as ENDPOINTS from "../../../../routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Reset from "static/images/Reset";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import LinkTo from "components/common/atoms/LinkTo";
import NoRecords from "components/common/atoms/NoRecords";

interface manageData {
  regionName: string;
  countryName: string;
  id: number;
}

interface dropDownsProps {
  region: OptionProps[];
  country: OptionProps[];
}

const ManageFunctions: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<dropDownsProps>({
    region: [],
    country: [],
  });

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    region: [] as OptionProps[],
    country: [] as OptionProps[],
  });

  //state for to store the functions linked countries
  const [countries, setCountries] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCountryBySearch();
  }, []);

  //page index based on page number
  let pageIndex = (currentPage - 1) * 20 + 1;

  //API call for to fetch countries
  const fetchCountryBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.MANAGE_FUNCTIONS,
      "POST",
      requestData,
      false,
      "company-creation"
    );

    if (response.status === 200) {
      const regions = mapToSelect(response.region);
      const countries = mapToSelect(response.countries);
      setDropDowns({
        region: regions,
        country: countries,
      });
      setCountries(response.data);
      setTotalPages(response.totalPages);
    }
  };

  const handleCountryChange = (selectedCountryIds: any) => {
    const fetchRegionForCountryDetails = async () => {
      const response = await ApiCall.service(
        `getRegionForCountryDetails`,
        "POST",
        selectedCountryIds,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        setFormData((prevData) => ({
          ...prevData,
          region: response.data,
        }));
      }
    };
    fetchRegionForCountryDetails();
  };

  const handleRegionChange = (selectedRegionIds: any) => {
    const fetchCountryDetailsForRegion = async () => {
      const response = await ApiCall.service(
        `getCountryForRegionDetails`,
        "POST",
        selectedRegionIds,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        const countries = mapToSelect(response.data);
        setDropDowns((prevData) => ({
          ...prevData,
          country: countries,
        }));
      }
    };
    fetchCountryDetailsForRegion();
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "country") {
      const selectedCountryIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleCountryChange(selectedCountryIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "region") {
      const selectedRegionIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleRegionChange(selectedRegionIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.region.length === 0 && formData.country.length === 0) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided."),
      });
      fetchCountryBySearch();
    } else {
      fetchCountryBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      region: [],
      country: [],
    });
    setCurrentPage(1);
    fetchCountryBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchCountryBySearch(formData, newPage);
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("Function profiles")} />
        <div className="form-height-dashboard">
          <div className="row search-bar">
            <div className="col-md-4">
              <SelectWithSearch
                search={true}
                options={dropDowns.region}
                // placeHolder="Select region"
                onChange={(e) => handleSelectChange(e, "region")}
                isMulti={true}
                className="select-field"
                name="region"
                title={t("Region")}
                value={formData.region}
                isTranslate={true}
              />
            </div>
            <div className="col-md-4">
              <SelectWithSearch
                search={true}
                options={dropDowns.country}
                // placeHolder="Select country"
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={true}
                className="select-field"
                name="country"
                title={t("Country")}
                value={formData.country}
                isTranslate={true}
              />
            </div>
            <div className="col-lg-4 col-xxl-3">
              <div className="row justify-content-end h-100">
                <div className="col-md-6 col-lg-12 d-flex justify-content-lg-start justify-content-end align-items-end">
                  <Button
                    title=""
                    type="reset"
                    handleClick={handleReset}
                    className="delete-btn shadow-none reset-btns"
                  >
                    <span title={t("Reset")}>
                      <Reset />
                    </span>
                  </Button>
                  <Button
                    title={t("Search")}
                    icon={faMagnifyingGlass}
                    type="submit"
                    handleClick={handleSubmit}
                    className="btn form-button rounded-3 shadow-none  px-3 search-btns position-relative"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Display the table of city profiles */}
          <div className="position-relative tableMainWrapper">
            <div className="d-none d-lg-block">
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/function/add"
                    title={t("Add function")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive tableSection">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Region")}</th>
                    <th>{t("Country")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {countries && countries.length > 0 ? (
                    countries.map((country) => (
                      <tr
                        key={country.id}
                        className="border mb-3 box-shadow align-middle"
                      >
                        <td>{country.regionName}</td>
                        <td>{country.countryName}</td>
                        <td className="table-action-icons px-2">
                          <div className="d-none d-md-none d-lg-block">
                            <Link
                              to={`/function/edit/${country.id}`}
                              className="btn p-0 border-0 me-2"
                              title={t("Edit function")}
                            >
                              <EditIcon />
                            </Link>
                            <Link
                              to={`/function/view/${country.id}`}
                              className="btn p-0 border-0 me-2"
                              title={t("View function")}
                            >
                              <ViewIcon />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={7} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pagination justify-content-center align-items-center my-3 mb-4 px-4">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(newPage) => handlePageChange(newPage)}
          />
        </div>
        <div className="col-md-6 align-self-center mb-3 px-4">
          <Link
            to="/config/settings"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(ManageFunctions);
