import { t } from "components/CentralDataMangement/translation/Translation";
import {
  CoeffObject,
  EmployeeObject,
} from "components/CompanyCreation/CooperationAgreement/annotations/CoopAgreementInterface";
import CustomNotify from "components/common/atoms/CustomNotify";
import * as CONST from "../../annotations/CoopAgreementConstants";

interface ChildProps {
  state: any;
  dispatch: any;
  userAuth: any;
  submitType: string;
}

interface MinValueObject {
  approvalStatus: boolean;
}

interface ValidationData {
  coefficientTab: boolean;
  coefficientTabMinError: boolean;
  compositionTab: boolean;
}
const ValidateData = ({
  state,
  dispatch,
  userAuth,
  submitType,
}: ChildProps): ValidationData => {
  const validationArray: boolean[] = [];
  const compositionValidationArray: boolean[] = [];
  const funDuplicateArray: boolean[] = [];
  const minValueArray: MinValueObject[] = [];
  const empDuplicateArray: boolean[] = [];
  const duplicateFunctions: Set<string> = new Set();
  const duplicateEmployeeTypes: Set<string> = new Set();
  const compositionMaxValidationArray: boolean[] = [];
  const dispatchStatus = (
    field: string,
    value: boolean | number | string,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number,
    coeffIndex: number | null
  ) => {
    dispatch({
      type: "UPDATE_BILLING_TAB_FIELD",
      field,
      value,
      pcIndex,
      funcIndex,
      empRowIndex,
      coeffIndex,
    });
  };
  const studentIds = [3,5,12,13];
  state.billing.forEach((pcItem: any, pcIndex: number) => {
    pcItem.functions.forEach((funcItem: any, funcIndex: number) => {
      //check for function duplicates and empty
      let funcErrorStatus =
        funcItem.function === null ||
        String(funcItem.function) === "" ||
        funcItem.function?.value === null ||
        funcItem.function?.value === ("" as any);

      // Determine the function value based on whether funcItem.function is an object or a string
      let funcValue;
      if (funcItem.function && typeof funcItem.function === "object") {
        // If funcItem.function is an object, use the 'label' for comparison
        funcValue = funcItem.function?.label?.trim()?.toLowerCase();
      } else if (funcItem.function && typeof funcItem.function === "string") {
        // If funcItem.function is a string, trim and convert to lowercase
        funcValue = funcItem.function.trim().toLowerCase();
      }

      if (funcValue !== "") {
        const initialSelectedFunction = state.amApprovedData[
          pcIndex
        ]?.functions[funcIndex]?.function?.label
          ?.trim()
          ?.toLowerCase();
        if (
          initialSelectedFunction !== undefined &&
          initialSelectedFunction !== null &&
          initialSelectedFunction !== ""
        ) {
          if (funcValue !== initialSelectedFunction) {
            dispatchStatus("funcChanged", true, pcIndex, funcIndex, 0, null);
          } else {
            dispatchStatus("funcChanged", false, pcIndex, funcIndex, 0, null);
          }
        }
        const key = `${pcItem.pc?.value}-${funcValue}`;
        if (duplicateFunctions.has(key)) {
          funcErrorStatus = true;
          funDuplicateArray.push(true);
        } else {
          duplicateFunctions.add(key);
        }
      }
      validationArray.push(funcErrorStatus);
      dispatchStatus(
        "funcErrorStatus",
        funcErrorStatus,
        pcIndex,
        funcIndex,
        0,
        null
      );

      funcItem.employeeTypes.forEach((empItem: any, empRowIndex: number) => {
        const errorFields = ["empTypeErrorStatus", "levelErrorStatus"];
        // Construct the key based on pcItem and funcItem
        const functionKey = funcItem?.function?.value ?? funcItem.function;
        const key = `${pcItem.pc?.value}-${functionKey}`;

        // Construct empType and level once outside the loop
        const empType = empItem["empType"]?.value ?? "";
        const level = empItem["level"]?.value ?? "";

        // Combine key with empType and level
        const combinedKey = `${key}-${empType}-${level}`;

        // Check if the key exists in duplicateEmployeeTypes
        const keyExists = duplicateEmployeeTypes.has(combinedKey);

        errorFields.forEach((field) => {
          // Check for duplicate employee types
          const fieldValue =
            empItem[field.replace("ErrorStatus", "") as keyof EmployeeObject];
          const initialSelectedEmpTypeOrLevel =
            state.amApprovedData[pcIndex]?.functions[funcIndex]?.employeeTypes[
              empRowIndex
            ]?.[field.replace("ErrorStatus", "")]?.value;

          const initialSelectedDate =
            state.amApprovedData[pcIndex]?.functions[funcIndex]?.employeeTypes[
              empRowIndex
            ]?.["startDate"];
          const startDateValue =
            empItem["startDate"] !== null
              ? new Date(empItem["startDate"])
              : null;
          const initialSelectedDateValue =
            initialSelectedDate !== null ? new Date(initialSelectedDate) : null;

          let empStatus =
            fieldValue === null ||
            fieldValue?.value === ("" as any) ||
            fieldValue?.value === null ||
            String(fieldValue) === "";
          if (
            fieldValue &&
            fieldValue?.value !== null &&
            fieldValue?.value !== ""
          ) {
            if (
              initialSelectedEmpTypeOrLevel !== undefined &&
              initialSelectedEmpTypeOrLevel !== null &&
              initialSelectedEmpTypeOrLevel !== "" &&
              initialSelectedDate !== undefined &&
              initialSelectedDate !== null &&
              startDateValue !== null
            ) {
              if (fieldValue?.value !== initialSelectedEmpTypeOrLevel) {
                dispatchStatus(
                  field.replace("ErrorStatus", "Changed"),
                  true,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  null
                );
              } else {
                dispatchStatus(
                  field.replace("ErrorStatus", "Changed"),
                  false,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  null
                );
              }
              if (startDateValue && initialSelectedDateValue) {
                const datesAreEqual =
                  initialSelectedDateValue.getFullYear() ===
                    startDateValue.getFullYear() &&
                  initialSelectedDateValue.getMonth() ===
                    startDateValue.getMonth() &&
                  initialSelectedDateValue.getDate() ===
                    startDateValue.getDate();
                if (!datesAreEqual) {
                  dispatchStatus(
                    "startDateChanged",
                    true,
                    pcIndex,
                    funcIndex,
                    empRowIndex,
                    null
                  );
                } else {
                  dispatchStatus(
                    "startDateChanged",
                    false,
                    pcIndex,
                    funcIndex,
                    empRowIndex,
                    null
                  );
                }
              }
            }
            if (keyExists) {
              empStatus = true;
              empDuplicateArray.push(true);
            } else {
              duplicateEmployeeTypes.add(combinedKey);
            }
          }
          validationArray.push(empStatus);
          dispatchStatus(
            field,
            empStatus,
            pcIndex,
            funcIndex,
            empRowIndex,
            null
          );
        });

        empItem.coefficients.forEach((coeff: any, coeffIndex: number) => {
          const filteredCoefficientsKeys = Object.keys(coeff).filter((key) =>
            key.endsWith("_error_status")
          );
          filteredCoefficientsKeys.forEach((field) => {
            if (studentIds.includes(empType) && field.replace("_error_status", "") === 'selection_%') {
              return;
            }
            const fieldValue = String(
              coeff[field.replace("_error_status", "") as keyof CoeffObject]
            );

            //getting min value from default coefficients
            const minValue =
              state.initialEmpCoeff &&
              Object.keys(state.initialEmpCoeff).length > 0
                ? String(
                    state.initialEmpCoeff?.[pcItem.pc?.value as number]?.[
                      empType
                    ]?.[level]?.[field.replace("_error_status", "")]["min"]
                  )
                : "0";
            const minmValueStr =
              minValue === "null" ? "0" : minValue.replace(",", ".");
            const minmValue = parseFloat(minmValueStr);

            //current value of the field
            const enteredValueStr = fieldValue.replace(",", ".");
            const enteredValue =
              enteredValueStr !== "" ? parseFloat(enteredValueStr) : 0;

            //getting approved data which is not updated one
            const amApprovedData =
              state.amApprovedData[pcIndex]?.functions[funcIndex]
                ?.employeeTypes[empRowIndex]?.coefficients[coeffIndex];
            const AmStatus =
              amApprovedData &&
              amApprovedData[field.replace("_error_status", "_approval_status")]
                ? amApprovedData[
                    field.replace("_error_status", "_approval_status")
                  ]
                : 0;
            const amApprovedDatavalueStr =
              amApprovedData &&
              amApprovedData[field.replace("_error_status", "_min")]
                ? String(
                    amApprovedData[field.replace("_error_status", "_min")]
                  ).replace(",", ".")
                : "";
            const amApprovedData2 =
              amApprovedDatavalueStr !== "" && amApprovedDatavalueStr !== null
                ? amApprovedDatavalueStr
                : amApprovedData &&
                  amApprovedData[field.replace("_error_status", "")]
                ? String(
                    amApprovedData[field.replace("_error_status", "")]
                  ).replace(",", ".")
                : "";
            const amApprovedDatavalue = parseFloat(amApprovedData2);

            //setting the min value
            const approvalValue =
              coeff[field.replace("_error_status", "_approval_status")];
            const updatedMinValue =
              AmStatus === 2 && amApprovedDatavalue < minmValue
                ? amApprovedDatavalue
                : approvalValue === 2
                ? enteredValue
                : minmValue;

            //checking error status and approval status
            const approvalStatusError = enteredValue < updatedMinValue;
            const apprvlStatusvalue =
              AmStatus === 1 || AmStatus === 3 ? 0 : AmStatus;
            const approvalStatusvalue =
              AmStatus === 2 && enteredValue < updatedMinValue
                ? 1
                : approvalValue === 2
                ? approvalValue
                : !approvalStatusError
                ? apprvlStatusvalue
                : 1;

            const initialAddedValue =
              amApprovedData &&
              amApprovedData?.[field.replace("_error_status", "")];
            let normalizedInitialAddedValue =
              initialAddedValue &&
              parseFloat(initialAddedValue.replace(",", ".") ?? null);

            if (userAuth.role === "OFFICE_MANAGER" && submitType === "submit") {
              const status = fieldValue === null || String(fieldValue) === "";
              !status &&
                approvalStatusError &&
                minValueArray.push({ approvalStatus: true });
              validationArray.push(status);
              const errorStatus =
                status ||
                approvalStatusError ||
                (AmStatus === 2 && enteredValue < updatedMinValue);
              dispatchStatus(
                field,
                errorStatus,
                pcIndex,
                funcIndex,
                empRowIndex,
                coeffIndex
              );

              if (!errorStatus) {
                if (
                  initialAddedValue !== undefined &&
                  initialAddedValue !== null &&
                  initialAddedValue !== ""
                ) {
                  if (
                    parseFloat(fieldValue.replace(",", ".")) !==
                    normalizedInitialAddedValue
                  ) {
                    dispatchStatus(
                      field.replace("_error_status", "_changed"),
                      true,
                      pcIndex,
                      funcIndex,
                      empRowIndex,
                      coeffIndex
                    );
                  } else {
                    dispatchStatus(
                      field.replace("_error_status", "_changed"),
                      false,
                      pcIndex,
                      funcIndex,
                      empRowIndex,
                      coeffIndex
                    );
                  }
                }
              }

              dispatchStatus(
                field.replace("_error_status", "_approval_status"),
                approvalStatusvalue,
                pcIndex,
                funcIndex,
                empRowIndex,
                0
              );
            } else if (submitType === "submit") {
              const status = fieldValue === null || String(fieldValue) === "";
              validationArray.push(status);
              if (approvalValue === 0) {
                dispatchStatus(
                  field,
                  status,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  coeffIndex
                );

                if (!status) {
                  if (
                    initialAddedValue !== undefined &&
                    initialAddedValue !== null &&
                    initialAddedValue !== ""
                  ) {
                    if (
                      parseFloat(fieldValue.replace(",", ".")) !==
                      normalizedInitialAddedValue
                    ) {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        true,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    } else {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        false,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    }
                  }
                }
              } else {
                !status &&
                  approvalValue !== 2 &&
                  approvalStatusError &&
                  minValueArray.push({ approvalStatus: true });
                const errorStatus =
                  status ||
                  approvalStatusError ||
                  (AmStatus === 2 && enteredValue < updatedMinValue);
                dispatchStatus(
                  field,
                  errorStatus,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  coeffIndex
                );
                if (!errorStatus) {
                  if (
                    initialAddedValue !== undefined &&
                    initialAddedValue !== null &&
                    initialAddedValue !== ""
                  ) {
                    if (
                      parseFloat(fieldValue.replace(",", ".")) !==
                      normalizedInitialAddedValue
                    ) {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        true,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    } else {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        false,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    }
                  }
                }
                dispatchStatus(
                  field.replace("_error_status", "_approval_status"),
                  approvalStatusvalue,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  0
                );
              }
            } else {
              if (
                userAuth.role === "OFFICE_MANAGER" ||
                (userAuth.role !== "OFFICE_MANAGER" && approvalValue !== 0)
              ) {
                approvalStatusError &&
                  minValueArray.push({ approvalStatus: true });
                const status =
                  approvalStatusError ||
                  (AmStatus === 2 && enteredValue < updatedMinValue);
                dispatchStatus(
                  field,
                  status,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  coeffIndex
                );
                dispatchStatus(
                  field.replace("_error_status", "_approval_status"),
                  approvalStatusvalue,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  0
                );

                if (!status) {
                  if (
                    initialAddedValue !== undefined &&
                    initialAddedValue !== null &&
                    initialAddedValue !== ""
                  ) {
                    if (
                      parseFloat(fieldValue.replace(",", ".")) !==
                      normalizedInitialAddedValue
                    ) {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        true,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    } else {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        false,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    }
                  }
                }
              } else {
                const status = fieldValue === null || String(fieldValue) === "";
                if (!status) {
                  if (
                    initialAddedValue !== undefined &&
                    initialAddedValue !== null &&
                    initialAddedValue !== ""
                  ) {
                    if (
                      parseFloat(fieldValue.replace(",", ".")) !==
                      normalizedInitialAddedValue
                    ) {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        true,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    } else {
                      dispatchStatus(
                        field.replace("_error_status", "_changed"),
                        false,
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex
                      );
                    }
                  }
                } else {
                  dispatchStatus(
                    field.replace("_error_status", "_changed"),
                    status,
                    pcIndex,
                    funcIndex,
                    empRowIndex,
                    coeffIndex
                  );
                }
              }
            }
          });
        });
      });
    });
  });
  const valid = validationArray.includes(true);
  const functionValid = funDuplicateArray.includes(true);
  const empValid = empDuplicateArray.includes(true);
  const coeffvalue = minValueArray.some((item) => item.approvalStatus === true);
  functionValid &&
    CustomNotify({
      type: "error",
      message: t("Duplicate profile exists for paritair commite") + "!!",
    });
  !functionValid &&
    empValid &&
    CustomNotify({
      type: "error",
      message: t("Duplicate levels with same employee type exists") + "!!",
    });
  !functionValid &&
    !empValid &&
    valid &&
    CustomNotify({
      type: "error",
      message: t("Please fill the highlighted fields") + "!!",
    });

  //for special agreements tab
  const compositionData = state.compositionData;
  const amApprovedCompsitionData = state.amApprovedCompositionData;
  const coefficientValues: any = [];
  compositionData.forEach((item: any, index: number) => {
    const initialSelectedCoeff =
      item.type === 1 &&
      (amApprovedCompsitionData?.[index]?.["value"]?.value ?? 0);
    const initialNiceToHave =
      item.type === 0 && amApprovedCompsitionData?.[index]?.["niceToHave"];
    const initialValue =
      item.type === 0 &&
      (initialNiceToHave ? parseFloat(initialNiceToHave.replace(",", ".")) : 0);

    const currentSelectedCoeff = item.type === 1 && (item?.value?.value ?? 0);
    const currentNiceToHave = item.type === 0 && item?.niceToHave;
    const currentVal =
      item.type === 0 &&
      (currentNiceToHave ? parseFloat(currentNiceToHave.replace(",", ".")) : 0);

    let valueChanged = false;
    if (item.type === 1) {
      if (initialSelectedCoeff !== currentSelectedCoeff) {
        valueChanged = true;
      }
    } else if (item.type === 0) {
      if (initialValue !== currentVal) {
        valueChanged = true;
      }
    }

    dispatch({
      type: CONST.UPDATE_COMPOSITION_FIELD,
      field: "isValueChanged",
      value: valueChanged,
      index: item.wageId,
    });
    if (item?.type === 0) {
      const minValueStr = item.min !== "" ? item.min.replace(",", ".") : "0";
      const minValue = parseFloat(minValueStr);
      const currentValueStr = item.niceToHave.replace(",", ".");
      const currentValue = parseFloat(currentValueStr);
      const maxValueStr = item?.max?.replace(",", ".");
      const maxValue = parseFloat(maxValueStr);

      const approvedValueStr = String(
        amApprovedCompsitionData[index]["minApproved"]
      ).replace(",", ".");
      const approvedValueStr2 =
        approvedValueStr !== "" || approvedValueStr !== null
          ? approvedValueStr
          : String(amApprovedCompsitionData[index]["niceToHave"]).replace(
              ",",
              "."
            );
      const approvedValue = parseFloat(approvedValueStr2);

      const updatedMinValue =
        item.approvalStatus === 2 && approvedValue < minValue
          ? approvedValue
          : minValue;

      if (currentValue < updatedMinValue) {
        const status =
          userAuth.role !== "OFFICE_MANAGER" && item.approvalStatus === 0
            ? 0
            : item.approvalStatus > 1
            ? item.approvalStatus
            : 1;
        const errorStatus =
          userAuth.role !== "OFFICE_MANAGER" &&
          (item.approvalStatus === 0 || item.approvalStatus === 2)
            ? false
            : true;
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "errorStatus",
          value: errorStatus,
          index: item.wageId,
        });
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "approvalStatus",
          value: status,
          index: item.wageId,
        });
        const isError =
          !errorStatus && item.approvalStatus === 0 && currentValue < minValue;
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "errorStatus",
          value: isError,
          index: item.wageId,
        });
        isError && compositionMaxValidationArray.push(true);
        errorStatus && compositionValidationArray.push(true);
      } else {
        const status = item.approvalStatus === 2 ? item.approvalStatus : 0;
        const isError = currentValue > maxValue;
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "errorStatus",
          value: isError,
          index: item.wageId,
        });
        isError && compositionMaxValidationArray.push(true);

        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "approvalStatus",
          value: status,
          index: item.wageId,
        });
      }
    } else {
      if (item?.wageId === 1) {
        // Convert to lowercase and replace spaces with underscores
        const selectedCoeffType = item?.value?.label
          .toLowerCase()
          .replace(/ /g, "_");
        state.billing.forEach((pcItem: any) => {
          pcItem.functions.forEach((funcItem: any) => {
            funcItem.employeeTypes.forEach((empItem: any) => {
              empItem.coefficients.forEach((coeff: any) => {
                // Check if the coefficient exists before pushing it to the array
                if (coeff.hasOwnProperty(selectedCoeffType)) {
                  coefficientValues.push(coeff[selectedCoeffType]);
                }
              });
            });
          });
        });
        // Check if coefficientValues includes zero (as a string or number)
        const includesZero = coefficientValues.includes('0') || coefficientValues.includes(0);
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "errorStatus",
          value: includesZero,
          index: 1,
        });

        includesZero && CustomNotify({
          type: "error",
          message: t(
            "Holiday coefficient value must be greater than 0"
          ),
        })
      }
    }
  });
  const compositionTabStatus = compositionValidationArray.includes(true);
  const compositionMaxStatus = compositionMaxValidationArray.includes(true);
  dispatch({
    type: CONST.UPDATE_INVOICE_FIELD,
    field: "coeffMaxStatus",
    value: compositionMaxStatus,
  });
  compositionMaxStatus &&
    CustomNotify({
      type: "error",
      message: t(
        "Coefficient values must be between the minimum and maximum allowed values"
      ),
    });
  compositionMaxStatus &&
    dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 3, error: true });

  if (!valid && (coeffvalue || compositionTabStatus)) {
    const status = coeffvalue ? coeffvalue : compositionTabStatus;
    dispatch({
      type: CONST.UPDATE_INVOICE_FIELD,
      field: "coeffStatus",
      value: status,
    });
  } else if (!coeffvalue && !compositionTabStatus) {
    dispatch({
      type: CONST.UPDATE_INVOICE_FIELD,
      field: "coeffStatus",
      value: false,
    });
  }
  return {
    coefficientTab: valid || false,
    coefficientTabMinError: coeffvalue || false,
    compositionTab: compositionTabStatus || false,
  };
};

export default ValidateData;
