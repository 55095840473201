import React, { useState, useEffect } from "react";
import { CREATE_TODO, MATCHING_LIST } from "routes/ApiEndpoints";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import Pagination from "components/common/atoms/Pagination";
import Title from "components/common/atoms/Title";
import { int } from "aws-sdk/clients/datapipeline";
import MatchingCandidateProfileCard from "./MatchingCandidateProfileCard";
import MatchingVacancyProfileCard from "./MatchingVacancyProfileCard";
import { MatchingCandidateProfileCardType } from "./MatchingCandidateProfileCard";
import { MatchingVacancyProfileCardType } from "./MatchingVacancyProfileCard";
import "./Matching.css";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import RightArrow from "static/images/RightArrow";
import { CALL_3CX, CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import PhoneCallIcon from "static/images/PhoneCall";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import CustomNotify from "components/common/atoms/CustomNotify";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Button from "components/common/atoms/Button";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";
import MatchingModalPopUp from "./MatchingModalPopUp";
import NoRecords from "components/common/atoms/NoRecords";
import TextEllipsis from "components/common/atoms/TextEllipsis";

export interface MatchingVacanciesProps {
  vacancyId: int;
  function: string;
  company: string;
  location: string;
  wage: string;
  score: int;
  companyPhoneNumber: string;
  companyId: number | null;
  contactId: number | null;
  userId: number | null;
}

export interface MatchingCandidatesProps {
  candidateId: int;
  function: string;
  name: string;
  email: string;
  mobNumber: string;
  score: int;
  vacancyId?: number;
  userId?: number | null;
}

const MatchingList: React.FC = () => {
  const location = useLocation();
  const [matchingVacanciesList, setMatchingVacanciesList] = useState(
    [] as MatchingVacanciesProps[]
  );
  const [matchingCandidatesList, setMatchingCandidatesList] = useState(
    [] as MatchingCandidatesProps[]
  );
  const navigate = useNavigate();
  const userAuth = useSelector(selectAuth);

  // /****** FOR MULTIPLE PRESENTATION OF CANDIDATE TO MULTIPLE FUNCTIONS/COMPANIES */
  // State for selected vacancies
  const [selectedVacancies, setSelectedVacancies] = useState<
    { vacancyId: number; functionName: string; company: string }[]
  >([]);

  const handleVacanciesCheckboxChange = (vacancy: any) => {
    setSelectedVacancies((prevSelected) => {
      if (prevSelected.some((item) => item.vacancyId === vacancy.vacancyId)) {
        return prevSelected.filter(
          (item) => item.vacancyId !== vacancy.vacancyId
        );
      } else {
        return [
          ...prevSelected,
          {
            vacancyId: vacancy.vacancyId,
            functionName: vacancy.function,
            company: vacancy.company,
            candidateId: id,
            submitType: "accept",
            stage: 4,
            type: "candidate",
          },
        ];
      }
    });
  };
  // ******** END************************

  // /****** FOR MULTIPLE PRESENTATION OF VACANCY TO MULTIPLE CANDIDATES */
  // State for selected vacancies
  const [selectedCandidates, setSelectedCandidates] = useState<
    { candidateId: number; functionName: string }[]
  >([]);

  const handleCandidatesCheckboxChange = (candidate: any) => {
    setSelectedCandidates((prevSelected) => {
      if (
        prevSelected.some((item) => item.candidateId === candidate.candidateId)
      ) {
        return prevSelected.filter(
          (item) => item.candidateId !== candidate.candidateId
        );
      } else {
        return [
          ...prevSelected,
          {
            candidateId: candidate.candidateId,
            functionName: candidate.function,
            vacancyId: id,
            submitType: "accept",
            stage: 4,
            type: "vacancy",
            candidate: candidate.name,
          },
        ];
      }
    });
  };

  // ******** END************************

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      candidateId: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
      isScreening: true,
      userId: null,
    });

  const [VacancyProfileCard, setVacancyProfileCard] =
    useState<MatchingVacancyProfileCardType>({
      companyName: "",
      website: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      country: "",
      functions: "",
      companyId: null,
    });

  const { id, type } = useParams();

  const fetchMatchingList = async (page = 1, loading = true) => {
    const body = {
      id: id,
      type: type,
      page: page,
    };

    const response = await ApiCall.service(
      MATCHING_LIST,
      "POST",
      body,
      loading,
      "central-data-management"
    );

    if (response.status === 200 && response.data) {
      if (type === "candidate") {
        setProfileCardData((prev) => ({
          ...prev,
          ...response.data.candidateInfo.general[0],
          ...{ cvData: response.data.candidateInfo?.cvData },
          ...{ profilePicData: response.data.candidateInfo?.profilePicData },
          ...{ functions: response.data.candidateInfo?.functions },
        }));
        setMatchingVacanciesList(
          response.data.list as MatchingVacanciesProps[]
        );
        setTotalPages(response.data.totalPages);
      } else if (type === "vacancy") {
        setVacancyProfileCard({
          ...response.data.vacancyInfo.general[0],
          ...{ functions: response.data.vacancyInfo.functions },
        });
        setMatchingCandidatesList(
          response.data.list as MatchingCandidatesProps[]
        );
        setTotalPages(response.data.totalPages);
      } else {
        setMatchingVacanciesList([]);
        setMatchingCandidatesList([]);
      }
    }
  };

  useEffect(() => {
    fetchMatchingList();
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchMatchingList(newPage, false);
  };

  const multiplePresent = async (presentationTo: string) => {
    let data = {};
    if (type === "candidate") {
      data = {
        presentationData: selectedVacancies,
        candidateId: id,
        userId: userAuth.userId,
        logInfo: "Presentation",
        stage: 4,
        type: "candidate",
        submitType: "accept",
        todoType: "presentation",
        presentationType: "multiple",
        presentationTo: presentationTo
      };
      navigate(
        `/todos/presentation/candidate/multiple/${presentationTo}/null/${id}/null/null/null/null?destination=/matching-list/candidate/${id}`,
        { state: { data } }
      );
    } else if (type === "vacancy") {
      data = {
        presentationData: selectedCandidates,
        candidateId: id,
        userId: userAuth.userId,
        logInfo: "Presentation",
        stage: 4,
        type: "vacancy",
        submitType: "accept",
        todoType: "presentation",
        presentationType: "multiple",
        presentationTo: presentationTo
      };
      navigate(
        `/todos/presentation/vacancy/multiple/${presentationTo}/${id}/null/null/null/null/null?destination=/matching-list/vacancy/${id}`,
        { state: { data } }
      );
    }
  };

  const singlePresent = async (
    canOrVacId: number | null,
    functionName: string,
    presentationTo: string
  ) => {
    if (type === "candidate") {
      const todoData = {
        candidateId: id,
        stage: "Matching",
        submitType: "skip",
      };
      const skipScreeningResponse = await ApiCall.service(
        "updateTodo",
        "POST",
        todoData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (skipScreeningResponse.status === 200) {
        const data = {
          candidateId: id,
          vacancyId: canOrVacId,
          stage: 4,
          source: 3,
        };
        const response = await ApiCall.service(
          CREATE_TODO,
          "post",
          data,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
          navigate(
            `/todos/presentation/candidate/single/${presentationTo}/${canOrVacId}/${id}/${functionName}?destination=/matching-list/${type}/${id}`
          );
        }
      }
    } else if (type === "vacancy") {
      const todoData = {
        candidateId: canOrVacId,
        stage: "Matching",
        submitType: "skip",
      };
      const skipScreeningResponse = await ApiCall.service(
        "updateTodo",
        "POST",
        todoData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (skipScreeningResponse.status === 200) {
        if (
          skipScreeningResponse.msg &&
          skipScreeningResponse.msg[0] === "error"
        ) {
          CustomNotify({
            type: skipScreeningResponse.msg[0],
            message: t(skipScreeningResponse.msg[1]),
          });
        } else {
          const data = {
            candidateId: canOrVacId,
            vacancyId: id,
            stage: 4,
            source: 3,
          };
          const response = await ApiCall.service(
            CREATE_TODO,
            "post",
            data,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          );
          if (response.status === 200) {
            navigate(
              `/todos/presentation/vacancy/single/${presentationTo}/${id}/${canOrVacId}/${functionName}?destination=/matching-list/${type}/${id}`,
              { state: { type } }
            );
          }
        }
      }
    }
  };
  const rightArrowComponent = <RightArrow width="1vw" height="1vw"/>; // Create an instance of the RightArrow component

  const getBackNavigationPath = (state: string) => {
    let link = "/manage-candidates";
    switch (state) {
      case "todos":
        link = `/todos/overview`;
        break;
      case "vacancies":
        link = `/manage/vacancies`;
        break;
      case "dashboard":
        link = `/dashboard`;
        break;
      default:
        link = `/manage-candidates`;
    }

    return link;
  };

  // Sales note
  const [salesnote, setSalesNote] = useState({
    name: "",
    phone: "",
    id: null,
    navigate: "",
    companyId: null,
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null,
  });

  const handleMeeting = async (
    name: string,
    phone: string | null,
    id: any | null,
    companyId: any,
    contactId: number | null,
    type: number,
    navigate?: string
  ) => {
    setSalesNote({
      name: name,
      phone: phone !== null ? phone : "",
      id: id,
      companyId: companyId,
      navigate: navigate ?? "",
      activeRecordingId: contactId,
      triggerMeeting: true,
      type: type, // For company contacts type is 3, candidates  is 1, companies is 2
    });
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };
  // Sales note end

  // Modal popup
  const [modalPopup, setModalPopup] = useState({
    id: null as number | null,
    type: "" as string | undefined,
    show: false as boolean,
    score: 0 as number,
    title: "" as string,
  })

  const [canOrVacId, setCanOrVacId] = useState(null as number | null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [score, setScore] = useState<number>(0);
  const records = matchingVacanciesList && matchingVacanciesList.length > 0 ? matchingVacanciesList : matchingCandidatesList;

  const onClosePopUp = () => {
    setModalPopup((prev) => ({
      ...prev,
      show: false,
    }));
  };

  const handleClick = async (canOrVacId: number, score: number) => {
    setCanOrVacId(canOrVacId);
    setModalPopup((prev) => ({
      ...prev,
      show: true,
      title: type === 'vacancy' ? "Matching candidate detail" : "Matching vacancy detail",
    }));

    const index = records.findIndex((record) => {
      return type === "vacancy"
        ? (record as MatchingCandidatesProps).candidateId === canOrVacId
        : (record as MatchingVacanciesProps).vacancyId === canOrVacId;
    });

    // If a matching record is found, set the currentIndex
    if (index !== -1) {
      setCurrentIndex(index);
    }
    setScore(score);
  }

  // Handle next button click
  const handleNext = () => {
    if (currentIndex < records.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setCanOrVacId(type === "vacancy"
        ? (records[newIndex] as MatchingCandidatesProps).candidateId
        : (records[newIndex] as MatchingVacanciesProps).vacancyId);
      setScore(records[newIndex].score);
    }
  };

  // Handle previous button click
  const handlePrevious = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setCanOrVacId(type === "vacancy"
        ? (records[newIndex] as MatchingCandidatesProps).candidateId
        : (records[newIndex] as MatchingVacanciesProps).vacancyId);
      setScore(records[newIndex].score);

    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="row pt-2" style={{ marginBottom: "2vw" }}>
        <div className="col-md-12 text-end">
          {type === "candidate" && (
            <MatchingCandidateProfileCard profileCardProps={profileCardData} />
          )}
          {type === "vacancy" && (
            <MatchingVacancyProfileCard profileCardProps={VacancyProfileCard} />
          )}

          {type === "candidate" && selectedVacancies.length > 0 && (
            <div className="mt-2">
              <div className="row">
                <div className="col-7"></div>
                <div className="col-md-3 align-self-center candidate-cv-download">
                  <div className=" matchingCandidateBtn">
                    <Button
                      className="form-button matchingBtn d-flex align-items-center justify-content-between mb-2"
                      handleClick={() => multiplePresent('company')}
                      title={t("Present to company")}
                    >
                      <span className="ms-2">{rightArrowComponent}</span>
                    </Button>
                    <Button
                      className="form-button matchingBtn d-flex align-items-center justify-content-between"
                      handleClick={() => multiplePresent('candidate')}
                      title={t("Present to candidate")}
                    >
                      <span className="ms-2">{rightArrowComponent}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {type === "vacancy" && selectedCandidates.length > 0 && (
            <div className="float-end justify-content-end">
              <Button
                className="form-button matchingBtn d-flex align-items-center justify-content-between mb-2"
                handleClick={() => multiplePresent('company')}
                title={t("Present to company")}
              >
                <span className="ms-2">{rightArrowComponent}</span>
              </Button>
              <Button
                className="form-button matchingBtn d-flex align-items-center justify-content-between"
                handleClick={() => multiplePresent('candidate')}
                title={t("Present to candidate")}
              >
                <span className="ms-2">{rightArrowComponent}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
      <Title title={t("Potential matches")} className="matching-list" />
      <div className="form-height-matching-list">
        <div className="table-responsive tableSection">
          <table className="table table-hover">
            <thead>
              {matchingVacanciesList && matchingVacanciesList.length > 0 ? (
                <tr className="TableHeader manage-matching-list-table">
                  <th className="ps-lg-4" style={{ width: "6%" }}>
                    {t("Present")}
                  </th>
                  <th style={{ width: "18%" }}>{t("Function")}</th>
                  <th style={{ width: "18%" }}>{t("Company")}</th>
                  <th style={{ width: "13%" }}>{t("Location")}</th>
                  <th style={{ width: "10%" }}>{t("Wage")}</th>
                  <th style={{ width: "15%" }}>{t("Score")}</th>
                  <th style={{ width: "15%" }}>{t("Actions")}</th>
                </tr>
              ) : (
                <tr className="TableHeader candidateMatchingTable">
                  <th className="ps-lg-4" style={{ width: "6%" }}>
                    {t("Present")}
                  </th>
                  <th style={{ width: "15%" }}>{t("Function")}</th>
                  <th style={{ width: "10%" }}>{t("Name")}</th>
                  <th style={{ width: "15%" }}>{t("Email")}</th>
                  <th style={{ width: "12%" }}>{t("Phone")}</th>
                  <th style={{ width: "15%" }}>{t("Score")}</th>
                  <th style={{ width: "15%" }}>{t("Actions")}</th>
                </tr>
              )}
            </thead>
            <tbody>
              {matchingVacanciesList && matchingVacanciesList.length > 0 ? (
                matchingVacanciesList.map((item, index) => (
                  <tr
                    key={index}
                    className="border-bottom mb-3 box-shadow align-middle"
                  >
                    <td className="ps-lg-4 text-break">
                      <CheckBoxField
                        label=""
                        name={`checkbox-${item.vacancyId}`}
                        onChangeHandler={() =>
                          handleVacanciesCheckboxChange(item)
                        }
                        value={item.vacancyId}
                        isChecked={selectedVacancies.some(
                          (vacancy) => vacancy.vacancyId === item.vacancyId
                        )}
                      />
                    </td>
                    <td data-label={t("Function")} className="text-break">
                      <a
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          handleClick(item.vacancyId, item.score);
                        }}
                        className="cursor-pointer"
                        href="#"
                      >
                        <TextEllipsis label={item.function} title={item.function} width="15vw" />
                      </a>
                    </td>
                    <td data-label={t("Company")} className="text-break">
                      <TextEllipsis label={item.company} title={item.company} width="15vw" /></td>
                    <td data-label={t("Location")} className="text-break">{item.location}</td>
                    <td data-label={t("Wage")} className="text-break">{item.wage}</td>
                    <td data-label={t("Score")} className="text-break">
                      <div className="d-flex align-items-center justify-content-center">
                        {/* <td className="table-action-icons px-2"> */}
                        <span
                          className="color-dark-pink"
                          style={{ width: "2.5vw" }}
                        >
                          {Math.round(item.score)}%
                        </span>
                        <div className="progress-bar-container">
                          <div
                            className="progress-bar"
                            style={{ width: `${item.score}%` }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 presentWrapper">
                      <div className="text-center text-lg-start d-flex align-items-center">
                        <span className="table-action-icons">
                          <a
                            title={t("Call")}
                            className="text-decoration-none marginRight1 cursor-pointer"
                            onClick={() =>
                              handleMeeting(
                                item.company,
                                item.companyPhoneNumber,
                                item.userId,
                                item.companyId,
                                item.contactId,
                                3,
                                CALL_3CX
                              )
                            }
                          >
                            <PhoneCallIcon />
                          </a>
                        </span>
                        {selectedVacancies &&
                          selectedVacancies.length === 0 && (
                            <>
                              <Button
                                className="form-button me-2"
                                handleClick={() => singlePresent(item.vacancyId, item.function, 'company')}
                                toolTipName={t("Present to company")}
                              >
                                <div className="d-flex align-items-center">
                                  <TextEllipsis title={t("Present to company")} label={t("Present to company")} width="3vw" />
                                  <span className="ms-2">{rightArrowComponent}</span>
                                </div>
                              </Button>

                              <Button
                                className="form-button me-2"
                                handleClick={() => singlePresent(item.vacancyId, item.function, 'candidate')}
                                toolTipName={t("Present to candidate")}
                              >
                                <div className="d-flex align-items-center">
                                  <TextEllipsis title={t("Present to candidate")} label={t("Present to candidate")} width="3vw" />
                                  <span className="ms-2">{rightArrowComponent}</span>
                                </div>
                              </Button>
                            </>
                          )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : matchingCandidatesList &&
                matchingCandidatesList.length > 0 ? (
                matchingCandidatesList.map((item, index) => (
                  <tr
                    key={index}
                    className="border-bottom marginBottom1 box-shadow align-middle"
                  >
                    <td className="ps-lg-4 text-break">
                      <CheckBoxField
                        label=""
                        name={`checkbox-${item.candidateId}`}
                        onChangeHandler={() =>
                          handleCandidatesCheckboxChange(item)
                        }
                        value={item.candidateId}
                        isChecked={selectedCandidates.some(
                          (candidate) =>
                            candidate.candidateId === item.candidateId
                        )}
                      />
                    </td>
                    <td data-label={t("Function")}>
                      <TextEllipsis title={t(`${item.function}`)} label={t(`${item.function}`)} width="15vw" />
                    </td>
                    <td data-label={t("Name")} className="text-break">

                      <a
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          handleClick(item.candidateId, item.score);
                        }}
                        className="cursor-pointer"
                        href="#"
                      >
                        <TextEllipsis label={item.name} title={item.name} width="10vw" />
                      </a>
                    </td>
                    <td data-label={t("Email")} className="text-break">
                      <TextEllipsis label={item.email} title={item.email} width="12vw" />
                    </td>
                    <td data-label={t("Phone")} className="text-break">
                      {item.mobNumber}
                    </td>
                    <td data-label={t("Score")} className="text-break">
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="color-dark-pink"
                          style={{ width: "4vw" }}
                        >
                          {Math.round(item.score)}%
                        </span>
                        <div className="progress-bar-container">
                          <div
                            className="progress-bar"
                            style={{ width: `${item.score}%` }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 text-break presentWrapper">
                      <div className="text-center text-lg-start d-flex align-items-center">
                        <span className="table-action-icons">
                          <a
                            className="text-decoration-none marginRightPoint5 cursor-pointer"
                            onClick={() =>
                              handleMeeting(
                                item.name,
                                item.mobNumber,
                                item.userId,
                                null,
                                item.candidateId,
                                1,
                                CALL_3CX
                              )
                            }
                            title={t("Call")}
                          >
                            <PhoneCallIcon />
                          </a>
                        </span>
                        {selectedCandidates &&
                          selectedCandidates.length === 0 && (
                            // <button
                            //   className="btn px-3 form-button button-width ms-2 "
                            //   title="Present"
                            //   onClick={() =>
                            //     singlePresent(item.candidateId, item.function)
                            //   }
                            // >
                            //   <div className="d-flex align-items-center justify-content-center">
                            //     {t("Present")}
                            //     <span className="ms-2">
                            //       {rightArrowComponent}
                            //     </span>
                            //   </div>
                            // </button>

                            <>
                              <Button
                                className="form-button me-2"
                                handleClick={() => singlePresent(item.candidateId, item.function, 'company')}
                              >
                                <div className="d-flex align-items-center">
                                  <TextEllipsis title={t("Present to company")} label={t("Present to company")} width="3vw" />
                                  <span className="ms-2">{rightArrowComponent}</span>
                                </div>
                              </Button>
                              <Button
                                className="form-button me-2"
                                handleClick={() => singlePresent(item.candidateId, item.function, 'candidate')}
                              >
                                <div className="d-flex align-items-center">
                                  <TextEllipsis title={t("Present to candidate")} label={t("Present to candidate")} width="3vw" />
                                  <span className="ms-2">{rightArrowComponent}</span>
                                </div>
                              </Button>
                            </>
                          )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <NoRecords headerLength={7} />
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="pagination justify-content-center align-items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(newPage) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
      </div >
      <div className="col-md-6 align-self-center backPadding">
        <Link
          to={getBackNavigationPath(location.state?.origin)}
          className="back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      {/* Sales note */}
      <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
      {/* Modal popup */}

      <MatchingModalPopUp
        title={modalPopup.title}
        type={type}
        show={modalPopup.show}
        canOrVacId={canOrVacId}
        onClose={onClosePopUp}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        currentIndex={currentIndex}
        totalRecords={records.length}
        score={score}
      />
    </AccessControl >
  );
};

export default translate(MatchingList);
