import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Calender from "components/common/molecules/Calender";
import TimeInput from "components/common/molecules/TimeInput";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { t, translate } from "../translation/Translation";
import "./taskDeterminer.css";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { ConfigData, TaskProps, TodoErrors } from "./TaskAnnotations";

interface FormProps {
  data: ConfigData;
  formData: TaskProps;
  handleChange: (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => void;
  handleSelectChange: (selectedOption: any, fieldName: string, type: string) => void;
  handleTimeChange: (name: string, value: string, type: string) => void;
  handleDateChange: (date: Date | null, type: string) => void;
  errors: Partial<TodoErrors>;
  type: string;
  isView: boolean;
}

const Task: React.FC<FormProps> = ({
  data,
  formData,
  handleChange,
  errors,
  handleTimeChange,
  handleDateChange,
  handleSelectChange,
  type,
  isView = false
}) => {
  const findTaskTypeByValue = (value: number | undefined) => {
    return data.taskTypes.find(type => type.value === value);
  }

  const tableDetails = [
    { label: t("Company"), group: "1", modelKey: "company", column: "company_name", notNullColumn: "bbright_id", customFetch: true, concat: "city" },
    { label: t("Employee"), group: "2", modelKey: "employee", customFetch: true, notNullColumn: "bbright_id", concat: "city" },
    { label: t("Vacancy"), group: "3", modelKey: "vacancy", column: "alias_name", notNullColumn: "bbright_id" }
  ];

  function filterByGroup(selectedGroup: string) {
    return tableDetails.filter(item => item.group !== String(selectedGroup));
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <SelectWithSearch
            title={t("Office")}
            isMandatory={true}
            search={true}
            options={data.offices ?? []}
            onChange={(e) => handleSelectChange(e, "office", type)}
            isMulti={false}
            name="office"
            value={formData.office}
            error={errors.office}
            isDisabled={isView}
            isTranslate={true}
          />
        </div>
        <div className="col-md-4 todoTimePicker d-grid justify-content-start" style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
          <div className="position-relative">
            <Calender
              isMandatory={true}
              onChange={(date) => handleDateChange(date || null, type)}
              name="date"
              label={t("Date")}
              selectedDate={formData.date ? new Date(formData.date) : null}
              error={errors.date}
              isDisabled={isView}
            />
          </div>
          <div className="w-100 time-section followUpTime">
            <TimeInput
              setTime={(e) => handleTimeChange("time", e, type)}
              index={1}
              type="time"
              value={formData.time ?? ""}
              title={t("Time")}
              error={errors.time}
              isDisabled={isView}
              isMandatory={true}
            />
          </div>
        </div>
        <div className="col-md-4 autoCompleteFunction">
          <AutoCompleteDropdown
            label={`${t("Follow up for")}?`}
            microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
            onChange={(e) => handleSelectChange(e, "followUpFor", type)}
            value={formData.followUpFor}
            modelKey={''}
            columnName=""
            isGrouped={true}
            shouldCreateNew={true}
            multiTables={tableDetails}
            isDisabled={isView}
            error={errors.followUpFor}
            isMandatory={true}
          />
        </div>
        <div className="col-md-4">
          <SelectWithSearch
            title={t("Type of follow up")}
            isMandatory={true}
            search={true}
            options={data.taskTypes.filter(task => task?.group === formData.followUpFor?.group) ?? []}
            onChange={(e) => handleSelectChange(e, "taskType", type)}
            isMulti={false}
            name="taskType"
            value={formData.taskType}
            error={errors.taskType}
            isDisabled={isView}
            isTranslate={true}
          />
        </div>
        {findTaskTypeByValue(formData.taskType?.value)?.is_multilink &&
          <>
            <div className="col-md-4 autoCompleteFunction">
              <AutoCompleteDropdown
                label={t("Regarding")}
                microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
                onChange={(e) => handleSelectChange(e, "regarding", type)}
                value={formData.regarding}
                modelKey={'functions'}
                columnName="function_name"
                isGrouped={true}
                shouldCreateNew={true}
                multiTables={filterByGroup(formData.followUpFor?.group)}
                isDisabled={isView}
              />
            </div>
            {(formData.taskType?.value === 22 || formData.taskType?.value === 24) && formData.regarding?.group == "1" &&
              <div className="col-md-4">
                <SelectWithSearch
                  title={t("Select vacancy")}
                  isMandatory={false}
                  search={true}
                  options={data?.vacancies ?? []}
                  onChange={(e) => handleSelectChange(e, "vacancy", type)}
                  isMulti={false}
                  name="vacancy"
                  value={formData.vacancy}
                  error={errors.vacancy}
                  isDisabled={isView}
                />
              </div>
            }
          </>
        }
        <LabelWithTextAreaField
          name="info"
          handleChange={(e) => handleChange(e, type)}
          label={t("Info")}
          value={formData.info}
          isDisabled={isView}
          isMandatory={true}
          error={errors.info}
        />
      </div>
    </>
  );
};
export default translate(Task);
