import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import "../../css/vacancy.css";
import Navigation from "../form-navigation/Navigation";
import {
  REQUIREMENT,
  UPDATE_FIELDS,
  UPDATE_FIELD_ERROR,
} from "../../annotation/VacancyConstants";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import {
  scrollToTop,
  validateForm,
  validateMultiSelectField,
} from "components/common/services/ValidationService";
import {
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";

const VacancyRequirementDetails = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | Date
      | object[]
      | Option
      | null
      | undefined
      | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      driverLicence: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...state.employment, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: UPDATE_FIELD_ERROR,
      field: name,
      error: validationErrors[name],
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: "" });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;

    if (name === "equiWorkExperience") {
      const checked = (event.target as HTMLInputElement).checked;
      const newValue = checked ? 1 : 0;
      dispatch({
        type: UPDATE_FIELDS,
        template: REQUIREMENT,
        field: name,
        value: newValue,
      });
    } else {
      dispatch({
        type: UPDATE_FIELDS,
        template: REQUIREMENT,
        field: name,
        value: value,
      });
    }
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      template: REQUIREMENT,
      field: name,
      value: selectedOption,
    });
    if (name === "driverLicence") {
      validation(name, selectedOption, true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    dispatch({
      type: UPDATE_FIELDS,
      template: REQUIREMENT,
      field: name,
      value: content,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3.4vw" }}>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  title={t("Years of experience")}
                  name="experience"
                  placeHolder={t("Select")}
                  search={true}
                  options={state.options.experienceOptions}
                  value={state.requirement.experience}
                  onChange={(e) => handleSelectChange(e, "experience")}
                  isMulti={false}
                  className="select-field"
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 mt-35">
                <CheckBoxField
                  label={t("Equivalent by work experience")}
                  name="equiWorkExperience"
                  onChangeHandler={handleFieldChange}
                  isChecked={state.requirement.equiWorkExperience === 1}
                  id="equiWorkExperience"
                  lineHeight="25px"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  title={t("Driver licences")}
                  name="driverLicence"
                  placeHolder={t("Select")}
                  search={true}
                  options={state.options.driverLicOptions}
                  value={state.requirement.driverLicence}
                  onChange={(e) => handleSelectChange(e, "driverLicence")}
                  isMulti={true}
                  className="select-field"
                  error={state.errors.driverLicence}
                  isMandatory={state.general.pushToVDAB === 1}
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  title={t("Studies")}
                  name="education"
                  placeHolder={t("Select")}
                  search={true}
                  options={state.options.educationOptions}
                  value={state.requirement.education}
                  onChange={(e) => handleSelectChange(e, "education")}
                  isMulti={true}
                  className="select-field"
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  title={t("Additional certificates")}
                  name="additional"
                  placeHolder={t("Select")}
                  search={true}
                  options={state.options.additionalOptions}
                  value={state.requirement.additional}
                  onChange={(e) => handleSelectChange(e, "additional")}
                  isMulti={true}
                  className="select-field"
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="other"
                  handleChange={handleFieldChange}
                  value={state.requirement.other}
                  id="other"
                  label={t("Other")}
                  type="text"
                />
              </div>
              <div className="col-md-12 hideHeight">
                <LabelWithCKEditorField
                  label={t("Profile description")}
                  name="profileDescription"
                  value={state.requirement.profileDescription}
                  placeholder={t("Type here") + "..."}
                  handleChange={(event, editor) =>
                    handleCKEditorChange(event, editor, "profileDescription")
                  }
                  className="field-shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </form>
  );
};

export default translate(VacancyRequirementDetails);
