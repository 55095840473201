import { t } from "components/CentralDataMangement/translation/Translation";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import LabelField from "./LabelField";

interface Option {
  value?: number | string | null;
  label?: string;
}

interface DropdownSelectProps {
  search: boolean;
  options: Option[];
  isMulti?: boolean;
  value: any;
  onChange: (value: any) => void;
  placeHolder?: string;
  name: string;
  id?: string;
  error?: number | null | undefined | string;
  className?: string;
  title?: string;
  isMandatory?: boolean;
  isDisabled?: boolean;
  isMenuPlacement?: boolean;
  isSelectOption?: boolean;
  style?: any;
  isTranslate?: boolean;
  prefixClassName?: string;
}

const SelectWithSearch: React.FC<DropdownSelectProps> = ({
  search,
  options,
  isMulti,
  value,
  id,
  onChange,
  placeHolder,
  name,
  error,
  className,
  title,
  isMandatory,
  isDisabled = false,
  isMenuPlacement = false,
  isSelectOption = true,
  style,
  isTranslate = false,
  prefixClassName
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const defaultPlaceholder = placeHolder || t("Select");
  const emptyOption = isSelectOption ? { value: null, label: "Select" } : null;
  const updatedOptions = emptyOption ? [emptyOption, ...options] : options;

  const transformedLabel = (label: string): string => {
    if (label === "") {
      return "";
    }

    //check labels having parenthesis (), in the starting of a string
    const match = label.match(/^\s*\((.*?)\)\s*(.*)/);
    const endingMatch = label.match(/^(.*)\s*\((\d+)\)\s*$/);

    if (match) {
      const contentInParentheses = match[1]; // Extract the content within parentheses
      const restLabel = match[2].trim(); // Extract the rest of the label after the parentheses

      // Apply translation to the second part of the label
      const translatedRestLabel = restLabel ? `${t(restLabel)}` : "";

      return `(${contentInParentheses}) ${translatedRestLabel}`
    } else if (endingMatch) {
      const restLabel = endingMatch[1].trim(); // Extract the text before the parentheses
      const numericPart = endingMatch[2]; // Extract the numeric content inside the parentheses

      // Apply translation to the rest of the label
      const translatedRestLabel = restLabel ? `${t(restLabel)}` : "";

      return `${translatedRestLabel} (${numericPart})`;
    }
    else {
      const [numericPart, ...rest] = label.split(" - ");
      const restLabel = rest.join(" - ");
      return rest.length > 0 ? `${numericPart} - ${t(restLabel)}` : numericPart;
    }
  };

  let translateOptions;
  if (name === 'city' || name === 'modalCity' || name === 'pc' || name === 'domCity' || name === 'biCity' ||
    name === 'PC2' || name === 'PC' || name === 'lowCity' || name === 'mediumCity' || name === 'highCity' ||
    name === 'attestType') {
    translateOptions = (opts: Option[]) =>
      opts.map(option => {
        const label = option.label || "";
        return {
          ...option,
          label: transformedLabel(label)
        };
      });

  } else {
    translateOptions = (opts: Option[]) =>
      opts.map(option => ({
        ...option,
        label: t(option.label || "")
      }));
  }

  const translatedOptions = isTranslate ? translateOptions(updatedOptions) : updatedOptions;
  const translatedMultiOptions = isTranslate ? translateOptions(options) : options;

  let translateValue;
  if (name === 'city' || name === 'modalCity' || name === 'pc' || name === 'domCity' || name === 'biCity' ||
    name === 'PC2' || name === 'PC' || name === 'lowCity' || name === 'mediumCity' || name === 'highCity' ||
    name === 'attestType') {
    translateValue = (val: any) => {
      if (Array.isArray(val)) {
        return val.map(item => {
          const label = item.label || '';
          return {
            ...item,
            label: transformedLabel(label)
          };
        });

      } else if (val) {
        const label = val.label || "";
        return {
          ...val,
          label: transformedLabel(label)
        };
      }
      return val;
    };

  } else {
    translateValue = (val: any) => {
      if (Array.isArray(val)) {
        return val.map(item => ({
          ...item,
          label: t(item.label || "")
        }));
      } else if (val) {
        return {
          ...val,
          label: t(val.label || "")
        };
      }
      return val;
    };
  }

  const translatedValue = isTranslate ? translateValue(value) : value;
  const menuPortalTarget = useRef(document.body);
  return (
    <div>
      <LabelField title={title} isMandatory={isMandatory} />
      <Select
        isSearchable={search}
        id={id}
        className={`form-control p-0 form-select field-shadow  select-field ${className}`}
        options={isMulti ? translatedMultiOptions : translatedOptions}
        isMulti={isMulti}
        value={translatedValue}
        placeholder={defaultPlaceholder}
        onChange={onChange}
        name={name}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
            border: "0",
            minHeight: style?.minHeight || "2.344vw", // Ensure minHeight is applied from style prop or use fallback
          }),
          menu: (provided: any, state) => ({
            ...provided,
            overflow: "visible",
          }),
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          menuList: (provided: any, state) => ({
            ...provided,
            maxHeight: "150px",
            overflowY: "auto",
          }),
          singleValue: (baseStyles: any, state) => ({
            ...baseStyles,
            color: isDisabled ? "#212529" : baseStyles.color,
          }),
          multiValue: (baseStyles: any, state) => ({
            ...baseStyles,
            color: isDisabled ? "#212529" : baseStyles.color,
          }),
          indicatorSeparator: (baseStyles: any) => ({
            ...baseStyles,
            display: "none",
          }),
        }}
        isDisabled={isDisabled}
        menuPlacement={isMenuPlacement ? "top" : "auto"}
        classNamePrefix={`${prefixClassName} multiSelectDropdown`}
        menuPortalTarget={menuPortalTarget.current} // Set the target for rendering the menu outside the DOM hierarchy
        noOptionsMessage={() => t("No records")}
        menuIsOpen={isMulti ? menuIsOpen : undefined} // Control the menu based on isMulti
        onFocus={() => isMulti && setMenuIsOpen(true)} // Open menu when focused
        onBlur={() => isMulti && setMenuIsOpen(false)}
      // menuIsOpen
      />

      <div className="height-20" style={{ marginBottom: "0.5vw" }}>
        {error && (
          <div className="text-danger">{error !== undefined ? error : ""}</div>
        )}
      </div>
    </div>
  );
};

export default SelectWithSearch;