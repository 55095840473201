import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
  offices: OptionProps[];
  taskTypes: OptionProps[];
  status: OptionProps[];
}
export const createFilterConfig = (dropDownList: dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.offices,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: 'search'
    },
    {
      name: "taskStart",
      fieldType: "date",
      filterType: "search",
      placeholder: "Start",
      group: "one"
    },
    {
      name: "taskEnd",
      fieldType: "date",
      filterType: "search",
      placeholder: "End",
      group: "one"
    },
    {
      name: "createdBy",
      fieldType: "text",
      placeholder: "Created by",
      filterType: 'search'
    },
    {
      name: "taskType",
      options: dropDownList.taskTypes,
      fieldType: "multiSelect",
      placeholder: "Follow up type",
      filterType: 'search',
      isMulti: true
    },
    {
      name: "status",
      options: dropDownList.status,
      fieldType: "singleSelect",
      placeholder: "Status",
      filterType: 'search',
    },
    {
      name: "company",
      fieldType: "text",
      placeholder: "Company",
      filterType: 'search',
    },
    {
      name: "candidate",
      fieldType: "text",
      placeholder: "Candidate",
      filterType: 'search',
    },
    {
      name: "vacancy",
      fieldType: "text",
      placeholder: "Vacancy / Function",
      filterType: 'search',
    },
    // {
    //   name: "company",
    //   fieldType: "autoSearchDropdown",
    //   placeholder: "Company",
    //   filterType: 'search',
    //   model: "company",
    //   microservice: COMPANY_MICROSERVICE,
    //   column: "company_name",
    //   filter: true,
    //   filterInfo: [
    //     { column: "company_status", value: 1, condition: "where" },
    //     { column: "company_status", value: 0, condition: "orWhere" }
    //   ] as FilterProps[],
    //   isMulti: true
    // },
    // {
    //   name: "candidate",
    //   fieldType: "autoSearchDropdown",
    //   placeholder: "Candidate",
    //   filterType: 'search',
    //   model: "candidate",
    //   microservice: CANDIDATE_MICROSERVICE,
    //   column: "first_name",
    //   concat: "last_name",
    //   valueColumn: "candidate_id",
    //   filter: true,
    //   filterInfo: [
    //     { column: "candidates.candidate_status", value: 0, condition: "where" },
    //     { column: "candidates.candidate_status", value: 1, condition: "orWhere" },
    //   ] as FilterProps[],
    //   isMulti: true
    // },
    // {
    //   name: "vacancy",
    //   fieldType: "autoSearchDropdown",
    //   placeholder: "Vacancy / Function",
    //   filterType: 'search',
    //   microservice: CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
    //   model: "vacancy",
    //   column: "alias_name",
    //   notNullColumn: "bbright_id",
    //   isMulti: true
    // },
    {
      name: "followUpStart",
      fieldType: "date",
      filterType: "search",
      placeholder: "Start",
      group: "two"
    },
    {
      name: "followUpEnd",
      fieldType: "date",
      filterType: "search",
      placeholder: "End",
      group: "two"
    },
    {
      name: "followedBy",
      fieldType: "text",
      placeholder: "Followed by",
      filterType: 'search'
    }
  ];
};
