import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import React, { useEffect, useRef, useState } from "react";
import CloseFile from "static/images/CloseFile";
import { ApiCall } from "components/common/services/ApiServices";
import { FETCH_FOLLOWUP_INFO } from "routes/ApiEndpoints";
import AddPlusIcon from "static/images/AddPlusIcon";
import LoadingIcon from "../utlis/LoadingIcon";
import SuccessionPopup from "components/CentralDataMangement/taskDeterminer/SuccessionPopup";
import Button from "./Button";

export interface FollowUpEntity {
  officeId?: number | string | null;
  entityId: number | string | null;
  isInBright: boolean;
}
interface RightSideBarProps {
  onClose: () => void;
  sidebarWidth?: string | number;
  title?: string;
  getWidth?: (value: any) => void;
  entityType: "company" | "candidate" | "vacancy";
  recordsLimit?: number;
  officeId?: number | string | null;
  entityId: number | string | null;
  isInBright: boolean;
  className?: string;
  titleClassName?: string;
  destination?: string;
  contentScroll?: string;
  isInPopUp?: boolean;
}

interface FollowUpData {
  id: number;
  date: string;
  time: string;
  info: string;
  relatedEntity: string;
  createdby: string;
  taskType: string;
}

export interface EntityData {
  id: null | number | string;
  type: null | number | string;
  typeId: null | number | string;
  office: null | number | string;
  taskType?: null | number | string;
}

const RightSideBar: React.FC<RightSideBarProps> = ({
  onClose,
  sidebarWidth,
  getWidth,
  className,
  titleClassName,
  title,
  entityType,
  entityId = null,
  officeId = null,
  isInBright = false,
  recordsLimit = 10,
  contentScroll,
  isInPopUp = false
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [followUps, setFollowUps] = useState<FollowUpData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1); // Pagination state
  const [hasMore, setHasMore] = useState<boolean>(true); // To track if more records are available

  //start of popup modal related code
  const initialData = {
    id: null,
    office: null,
    type: null,
    typeId: null
  }
  const [showModal, setShowModal] = useState(false);
  const [entity, setEntity] = useState<EntityData>(initialData);
  //end of popup modal related code

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      fetchFollowUps(1, true);
    }, 10);

    if (sidebarRef.current && getWidth) {
      const sidebarWidth = sidebarRef.current.offsetWidth;
      getWidth(sidebarWidth);
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarWidth, entityId]);

  const fetchFollowUps = async (pageNumber: number, initFetch: boolean = false) => {
    if (initFetch) {
      setFollowUps([]);
      setPage(pageNumber);
      setHasMore(true);
    } else if (loading || !hasMore) {
      return;
    }
    setLoading(true);
    const data = {
      entityType: entityType,
      entityId: entityId,
      office: officeId,
      nOR: recordsLimit,
      page: pageNumber
    };
    const response = await ApiCall.service(
      FETCH_FOLLOWUP_INFO,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200 && response.data.length > 0) {
      setFollowUps((prev) => [...prev, ...response.data]);
      setPage(pageNumber + 1); // Increment the page for next fetch
    } else {
      setHasMore(false); // No more records available
    }
    setLoading(false);
  };

  const handleScroll = () => {
    if (sidebarRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = sidebarRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100 && !loading) {
        fetchFollowUps(page); // Fetch more data when user scrolls to the bottom
      }
    }
  };

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (sidebarRef.current) {
        sidebarRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, hasMore]); // Re-attach event listeners when `loading` or `hasMore` changes

  const handleModalClose = () => {
    setShowModal(false);
    setEntity(initialData);
  };

  const refresh = () => {
    fetchFollowUps(1, true);
    handleModalClose();
  }

  return (
    <>
      <div ref={sidebarRef} className={className} style={{ padding: "1.25vw" }}>
        {onClose && (
          <div className="row p-0">
            <div className="col-12 text-end p-0">
              <Button handleClick={onClose} className="table-action-icons border-0 bg-transparent pe-2">
                <span data-bs-toggle="tooltip" title={t("Close")}>
                  <CloseFile />
                </span>
              </Button>
            </div>
          </div>
        )}
        <div
          className={`${titleClassName} row p-0`}
          style={{ marginTop: "1vw" }}
        >
          <div className="col-9 text-break ">
            <div className="tab-subtitle text-break">{t(`${title ?? ""}`)}</div>
          </div>
          <div className="col-3 pe-1">
            {isInBright &&
              <button
                onClick={() => { setShowModal(true); setEntity({ id: null, typeId: entityId?.toString() ?? null, type: entityType, office: null }) }}
                className="table-action-icons border-0 p-0 bg-transparent float-end">
                <span data-bs-toggle="tooltip" title={t("Add follow-ups")}>
                  <AddPlusIcon />
                </span>
              </button>
            }
          </div>
        </div>
        <div className={`${contentScroll} recruiter-action-tabs`}>
          {loading && page === 1 ? (
            <LoadingIcon />
          ) : (
            <>
              {
                followUps.length > 0 ? (
                  <>{
                    followUps.map((data: FollowUpData, index) => (
                      <span className="text-decoration-none" key={index} onClick={() => { setEntity({ ...initialData, id: data.id }); setShowModal(true) }}>
                        <div className="card border-0 card-shadow justify-content-center followUpCard cursor-pointer">
                          <div className="followUpTimings mb-2">{`${data.date ?? ""} ${data.time ?? ""} - ${data.taskType ?? ""} (${data.createdby ?? ""})`}</div>
                          <div className="mb-2">{data.relatedEntity ?? "-"}</div>
                          <div>{data.info.substring(0, 60)}</div>
                        </div>
                      </span>
                    ))
                  }</>
                ) : (<div className="text-center color-dark-pink">{t("No follow-ups found")}</div>)
              }
            </>
          )}
          {loading && page > 1 && <LoadingIcon />} {/* Show loading icon for subsequent loads */}
          {isInPopUp && hasMore &&
            <div className="text-center">
              <Button
                title={t("Show more")}
                className="form-button shadow-none"
                handleClick={handleScroll} />
            </div>
          }
          {!hasMore && followUps.length > 0 && <div className="text-center mt-2">{t("No more follow-ups")}</div>}
        </div>
      </div>
      {showModal &&
        <SuccessionPopup
          entity={entity}
          show={showModal}
          onHide={handleModalClose}
          refresh={refresh}
        />
      }

    </>
  );
};

export default translate(RightSideBar);
