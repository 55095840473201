import React, { useState } from "react";
import { Link } from "react-router-dom";
import { t } from "components/CentralDataMangement/translation/Translation";
import "../CandidateCreation/create-candidate/css/Candidate.css";
import CardCircle from "components/common/atoms/CardCircle";
import { FILE_URL } from "../../routes/ApiEndpoints";
import axios from "axios";
import * as ENDPOINTS from "../../routes/ApiEndpoints";
import DownloadIcon from "static/images/Download";
import RightArrow from "static/images/RightArrow";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import EditIcon from "static/images/EditIcon";
import { CALL_3CX } from "Constants";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";

export type MatchingCandidateProfileCardType = {
  candidateId?: number | null;
  firstName: string;
  lastName: string;
  functions: any;
  mobNumber?: string;
  email?: string;
  street?: string;
  number?: string;
  box?: string;
  zipCode?: string;
  city?: string;
  picFileName?: string;
  picFilePath?: string;
  country?: string;
  cvData?: any;
  profilePicData?: any;
  isScreening?: boolean;
  source?: string;
  isMatching?: boolean;
  isEdit?: boolean;
  userId?: number | null;
  brightId?: number | null;
};

export interface MatchingProfileCardProps {
  profileCardProps: MatchingCandidateProfileCardType;
}

const MatchingProfileCard: React.FC<MatchingProfileCardProps> = (props) => {

  const functionList =
    props.profileCardProps.functions === undefined
      ? []
      : props.profileCardProps.functions;

  let address = [];

  if (props.profileCardProps.street) {
    address.push(props.profileCardProps.street);
  }
  if (props.profileCardProps.number) {
    address.push(props.profileCardProps.number);
  }
  if (props.profileCardProps.zipCode) {
    address.push(props.profileCardProps.zipCode);
  }

  if (props.profileCardProps.city) {
    address.push(props.profileCardProps.city);
  }

  if (props.profileCardProps.country) {
    address.push(props.profileCardProps.country);
  }

  let fullAddress = address.join(", ");

  const downloadFile = async (
    id: number | null,
    name: string | null,
    path: string | null,
    uploadPath: string | null
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVICE}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
      );
      if (response.status === 200) {
        const fileUrl = response.data.url;
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = fileUrl;
        link.target = "_blank";
        link.download = response.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  // Sales note
  const [salesnote, setSalesNote] = useState({
    name: '',
    phone: '' as string | undefined,
    id: null,
    navigate: '',
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null
  });

  const handleMeeting = async (name: string, id: any | null, candidateId: any, phone?: string | null, navigate?: string) => {
    setSalesNote({
      name: name,
      phone: phone !== null ? phone : '',
      id: id,
      navigate: navigate ?? '',
      activeRecordingId: candidateId,
      triggerMeeting: true,
      type: 1 // For company contacts type is 3, candidates  is 1, companies is 2
    });
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-md-7">
          <div
            style={{
              marginLeft: "3.5vw",
            }}
          >
            <div
              className="card position-relative border-0"
              style={{
                backgroundColor: "var(--profileBgColor)",
                padding: "2.5vw",
                borderRadius:"1vw"
              }}
            >
              <div className="cv-uplod">
                <CardCircle
                  position="absolute"
                  width="7.813vw"
                  height="7.813vw"
                  backgroundColor="var(--primaryBrandColor)"
                  left="0vw"
                  top={"50%"}
                  transform={`translate(-3.5vw, -3.9vw)`} // Centering horizontally and vertically
                  className="me-5 flex-column cursor-pointer justify-content-center"
                  color="var(--color-white)"
                  inputClass={"mb-2"}
                >
                  {props.profileCardProps?.profilePicData?.fileName !== "" &&
                    props.profileCardProps?.profilePicData?.fileName !==
                    undefined ? (
                    <img
                      src={
                        props.profileCardProps?.profilePicData?.filePath?.startsWith(
                          "https"
                        )
                          ? `${props.profileCardProps?.profilePicData?.filePath}`
                          : `${FILE_URL}/${props.profileCardProps?.profilePicData?.filePath}`
                      }
                      alt={t("Uploaded")}
                      style={{
                        backgroundColor: "var(--color-white)",
                        borderRadius: "50%",
                        boxShadow: "rgb(248 216 235) 1px 0px 10px 3px",
                      }}
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <img
                      src="/static/images/Person.svg"
                      alt="Profile"
                      width="100%"
                      height="100%"
                    />
                  )}
                </CardCircle>
              </div>
              <div className="row">
                <div className="col-md-10 ms-auto">
                  <div className="d-flex align-items-start justify-content-between text-break">
                    {(props.profileCardProps.firstName ||
                      props.profileCardProps.lastName) && (
                        <div className={`color-dark-pink name-text text-start`}>
                          {props.profileCardProps.firstName}{" "}
                          {props.profileCardProps.lastName}
                        </div>
                      )}
                    {props?.profileCardProps?.isEdit && (
                      <div className="float-end table-action-icons px-2">
                        <Link
                          to={`/candidate/edit/${props.profileCardProps.candidateId}`}
                          className="btn p-0 border-0 me-2 "
                          title={t("Edit")}
                        >
                          <EditIcon />
                        </Link>
                      </div>
                    )}
                  </div>
                  <div
                    className="job-title text-start"
                    style={{ marginTop: "0.8vw", marginBottom: "1vw" }}
                  >
                    <span>{t("looking for a job as")}</span>
                    <span>
                      {functionList.length > 0 && (
                        <strong> {functionList.join(", ")}</strong>
                      )}
                    </span>
                  </div>
                  <div className="candidate-person-details">
                    {props.profileCardProps.mobNumber && (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginBottom: "1vw" }}
                      >
                        <a
                          onClick={() => handleMeeting(props.profileCardProps.firstName, props.profileCardProps.userId, props.profileCardProps.candidateId, props.profileCardProps.mobNumber, CALL_3CX)}
                          className="cursor-pointer link-color"
                        >
                          <img
                            src="../../static/images/mobile.png"
                            alt={t("Mobile")}
                            className="profileCardImage"
                          />
                          <span className={`ms-3`}>
                            {props.profileCardProps.mobNumber}
                          </span>
                        </a>
                      </div>
                    )}
                    {props.profileCardProps.email && (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginBottom: "1vw" }}
                      >
                        <a
                          href={`mailto:${props.profileCardProps.email}`}
                          target="_blank"
                          className="link-color"
                        >
                          <img
                            src="../../static/images/mail.png"
                            alt={t("Mail")}
                            className="profileCardImage"
                          />
                          <span className={`ms-3`}>
                            {props.profileCardProps.email}
                          </span>
                        </a>
                      </div>
                    )}
                    {fullAddress && (
                      <div className="d-flex align-items-start">
                        <img
                          src="../../static/images/home.png"
                          alt={t("Address")}
                          className="profileCardImage"
                        />
                        <span className={`ms-3`}>
                          <div className="mb-2 text-start">{fullAddress}</div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 align-self-center candidate-cv-download">
          <div className="shout-icon d-flex align-items-center">
            <div className="d-flex">
              <img
                src="../../static/images/megaphone.svg"
                alt={t("Megaphone")}
                className="megaPhoneIcon"
              />
              <div className="available-text mb-2 fraunces-italic color-dark-pink">
                <div>{t("Available")}</div>
                <div>{t("for work!")}</div>
              </div>
            </div>
          </div>
          {props.profileCardProps?.cvData?.fileId &&
            props.profileCardProps?.cvData?.fileName &&
            props.profileCardProps?.cvData?.filePath && (
              <div className="d-flex matchingCandidateBtn">
                <span
                  onClick={(event) =>
                    downloadFile(
                      props.profileCardProps.cvData.fileId
                        ? props.profileCardProps.cvData.fileId
                        : null,
                      props.profileCardProps.cvData.fileName
                        ? props.profileCardProps.cvData.fileName
                        : "",
                      props.profileCardProps.cvData.filePath
                        ? props.profileCardProps.cvData.filePath
                        : "",
                      "candidate-cv"
                    )
                  }
                  className="cursor-pointer"
                >
                  <div
                    className="form-button candidateDownload matchingBtn d-flex justify-content-between align-items-center"
                    title={t("Download")}
                  >
                    <span className="me-3">{t("Download CV")}</span>
                    <DownloadIcon />
                  </div>
                </span>
              </div>
            )}
          {props.profileCardProps.isScreening && (
            <div className="d-flex matchingCandidateBtn">
              <Link
                className="form-button matchingBtn"
                title=""
                to={`/screening/${props.profileCardProps.candidateId}`}
                state={{ origin: props.profileCardProps.source }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  {t("Screening")} &nbsp;
                  <span className="ms-3">
                    <RightArrow />
                  </span>
                </div>
              </Link>
            </div>
          )}
          {props.profileCardProps?.isMatching && (
            <div className="d-flex matchingCandidateBtn">
              <Link
                className="form-button matchingBtn"
                title=""
                to={`/matching-list/candidate/${props.profileCardProps.candidateId}`}
                state={{ origin: props.profileCardProps.source }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  {t("Matching")} &nbsp;
                  <span className="ms-3">
                    <RightArrow />
                  </span>
                </div>
              </Link>
            </div>
          )}
        </div>
        {/* Sales note */}
        <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
      </div>
    </AccessControl>
  );
};

export default MatchingProfileCard;
