import React, { useState, useEffect } from "react";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link } from "react-router-dom";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Calender from "components/common/molecules/Calender";
import Button from "components/common/atoms/Button";
import EditIcon from "../../../../../static/images/EditIcon";
import ViewIcon from "../../../../../static/images/ViewIcon";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import Reset from "static/images/Reset";
import LinkTo from "components/common/atoms/LinkTo";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import NoRecords from "components/common/atoms/NoRecords";

interface inflationDataProps {
  regionName: string;
  countryName: string;
  fromDate: string;
  endDate: string;
  inflationId: number;
}

interface inflationProps {
  regions: OptionProps[];
  countries: OptionProps[];
  fromDate: Date | null;
  endDate: Date | null;
}

const ManageInflations = () => {
  const [formData, setFormData] = useState<inflationProps>({
    regions: [],
    countries: [],
    fromDate: null,
    endDate: null,
  });

  const [inflationDetails, setInflationDetails] = useState({
    regions: [] as OptionProps[],
    countries: [] as OptionProps[],
  });

  const [inflationData, setInflationData] = useState(
    [] as inflationDataProps[]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRegion, setSelectedRegion] = useState<number[]>([]);
  const itemsPerPage = 20;
  let pageIndex = (currentPage - 1) * itemsPerPage + 1;
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchInflationsData();
  }, []);

  const fetchInflationsData = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "inflation",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const regions = response.data["regions"];
      const countries = response.data["countries"];
      setInflationDetails({
        regions: regions,
        countries: countries,
      });
      setInflationData(response.data["data"]);
      setTotalPages(response.data["totalPages"]);
    }
    return;
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "countries") {
      const selectedCountryIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleCountryChange(selectedCountryIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "regions") {
      const selectedRegionIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      setSelectedRegion(selectedRegionIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleCountryChange = (selectedCountryIds: any) => {
    const region = inflationDetails.countries
      .filter((item: any) => selectedCountryIds.includes(item.id))
      .map((item: any) => item.region_id);

    const regionIds = Array.from(new Set(region));
    const selectedRegions = inflationDetails.regions
      .filter((item) => regionIds.includes(item.id))
      .map((region) => ({ value: region.id, label: region.name }));
    setFormData((prevData) => ({
      ...prevData,
      region: selectedRegions,
    }));
  };

  const getCountriesForRegion = () => {
    if (selectedRegion.length > 0) {
      const regionBasedCountries = inflationDetails.countries.filter(
        (item: any) => selectedRegion.includes(item.region_id)
      );
      return mapToSelect(regionBasedCountries);
    }

    return mapToSelect(inflationDetails.countries);
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: date,
    }));
  };

  const handleReset = () => {
    setFormData({
      regions: [],
      countries: [],
      fromDate: null,
      endDate: null,
    });
    setCurrentPage(1);
    fetchInflationsData();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchInflationsData(formData, newPage);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      formData.regions.length === 0 &&
      formData.countries.length === 0 &&
      formData.fromDate === null &&
      formData.endDate === null
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchInflationsData();
    } else {
      fetchInflationsData(formData, currentPage);
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Inflations",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Inflations")} />
      <div>
        <form action="">
          <div className="row search-bar">
            <div className="col-3">
              <SelectWithSearch
                search={true}
                options={mapToSelect(inflationDetails.regions)}
                onChange={(e) => handleSelectChange(e, "regions")}
                isMulti={true}
                name="region"
                title={t("Region")}
                value={formData.regions}
                isTranslate={true}
              />
            </div>
            <div className="col-3">
              <SelectWithSearch
                search={true}
                options={getCountriesForRegion()}
                onChange={(e) => handleSelectChange(e, "countries")}
                isMulti={true}
                title={t("Country")}
                name="country"
                value={formData.countries}
                isTranslate={true}
              />
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-6 position-relative">
                  <Calender
                    onChange={(date) => handleDateChange(date, "fromDate")}
                    selectedDate={formData.fromDate}
                    name="inflationFromDate"
                    minDate={new Date()}
                    maxDate={null}
                    isMandatory={false}
                    label={t("From date")}
                  />
                </div>
                <div className="col-6 position-relative">
                  <Calender
                    onChange={(date) => handleDateChange(date, "endDate")}
                    selectedDate={formData.endDate}
                    name="inflationEndDate"
                    minDate={formData.fromDate ?? new Date()}
                    maxDate={null}
                    isMandatory={false}
                    label={t("To date")}
                  />
                </div>
              </div>
            </div>
            <div className="col-2 mt-34">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <Button
                  title={t("Search")}
                  type="submit"
                  icon={faMagnifyingGlass}
                  handleClick={handleSubmit}
                  className="form-button shadow-none search-btns text-start position-relative"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Inflations",
                  create: true,
                },
              ]}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/add/inflation"
                    title={t("Add inflation")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection manageInflationTable paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4">{t("Region")}</th>
                    <th>{t("Country")}</th>
                    <th>{t("Start date")}</th>
                    <th>{t("End date")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Inflations",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {inflationData && inflationData.length > 0 ? (
                    inflationData.map((inflation) => (
                      <tr
                        key={inflation.inflationId}
                        className="border-bottom mb-3 box-shadow align-middle"
                      >
                        {/* <td>{pageIndex++}</td> */}
                        <td className="ps-lg-4" data-label={t("Region")}>
                          {t(`${inflation.regionName}`)}
                        </td>
                        <td data-label={t("Country")}>
                          {t(`${inflation.countryName}`)}
                        </td>
                        <td data-label={t("Start date")}>
                          {inflation.fromDate}
                        </td>
                        <td data-label={t("End date")}>{inflation.endDate}</td>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Inflations",
                              update: true,
                              delete: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                        >
                          <td className="table-action-icons px-2">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Inflations",
                                    update: true,
                                  },
                                ]}
                              >
                                {inflation.endDate === null && (
                                  <Link
                                    to={`/edit/${inflation.inflationId}/inflation`}
                                    className="marginRightPoint5"
                                    title={t("Edit")}
                                  >
                                    <EditIcon />
                                  </Link>
                                )}
                              </AccessControl>
                              <Link
                                to={`/view/${inflation.inflationId}/inflation`}
                                title={t("View")}
                              >
                                <ViewIcon />
                              </Link>
                           
                          </td>
                        </AccessControl>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={7} />
                
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4 px-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-4 align-self-center">
          <Link
            to="/config/settings"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageInflations);
