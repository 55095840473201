import { FollowUp } from "../Dashboard";
import { Fragment } from "react";
import { t } from "components/CentralDataMangement/translation/Translation";
import CloseFile from "static/images/CloseFile";
import { Link } from "react-router-dom";
import RightArrow from "static/images/RightArrow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const FollowUpTable: React.FC<{ followUps: FollowUp[], handleFollowUpClose: (id: string) => void }> = ({
  followUps, handleFollowUpClose
}) => {
  return <>
    <div className="dashboard-work-table">
      <table className="table table-borderless">
        <tbody>
          <>
            {followUps && followUps.length > 0 ? (
              followUps.map((followUp, index) => (
                <Fragment key={`follow-up-${index}`}>
                  <tr
                    key={`follow-up-${index}`}
                    className="mb-2 box-shadow align-middle"
                  >
                    <td
                      className="ps-lg-4"
                      data-label={t("Office")}
                    >
                      {followUp.office}
                    </td>
                    <td
                      className="ps-lg-4"
                      data-label={t("Follow-up for")}
                    >
                      {followUp.followUpFor}
                    </td>
                    <td
                      className="ps-lg-4"
                      data-label={t("Follow-up type")}
                    >
                      {followUp.taskType}
                    </td>
                    <td
                      className="ps-lg-4"
                      data-label={t("Time")}
                    >
                      {followUp.time}
                    </td>
                    <td className="table-action-icons">
                      {!followUp.status &&
                        <span
                          className="p-0 border-0 me-2 cursor-pointer"
                          title={t("Close")}
                          onClick={() => handleFollowUpClose(followUp.id)}
                        >
                          <CloseFile />
                        </span>
                      }
                      <Link key={`link2${index}`}
                        to={`/edit/follow-up/${followUp.id}`}
                        state={{ origin: "dashboard" }}
                        title={t("View")}
                      >
                        <RightArrow width={"2vw"} height={"2vw"} />
                      </Link>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr className="border rounded-3">
                <td
                  colSpan={4}
                  className="border-0 text-center py-3 no-records"
                >
                  <span className="text-danger w-100 d-block text-center">
                    {
                      <>
                        {t("No assigned follow-ups for you")}
                      </>
                    }
                  </span>
                </td>
              </tr>)
            }
          </>
        </tbody>
      </table>
    </div>
    <Link
      to={"/manage/follow-up"}
      className="link-btn text-decoration-none"
    >
      {t("Overview")}
      <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
    </Link>
  </>
}
export default FollowUpTable;