import { Option } from "components/common/services/ValidationService";
import { ApiCall } from "../services/ApiServices";
import { STOP_RECORDING } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, ERROR, SUCCESS } from "Constants";
import CustomNotify from "../atoms/CustomNotify";
import { t } from "components/CentralDataMangement/translation/Translation";

const genders: Option[] = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
];

export function getGenderById(id: number): Option | undefined {
  return genders.find((gender) => gender.value === id);
}

const withHoldingTax: Option[] = [{ value: 3, label: "18.725%" }];

export function getWithHoldingTaxForSeasonalWorker() {
  return { value: 3, label: "18.725%" };
}

export function formatDate(date: Date | null): string {
  // Ensure date is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "";
  }

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(date.getFullYear()); // Full 4-digit year

  // Format as dd/mm/yyyy
  return `${day}/${month}/${year}`;
}

export function processMobileNumber(mobile: any) {
  // Check if the mobile number contains any alphabetic characters
  if (/[a-zA-Z]/.test(mobile)) {
    return null; // If it contains alphabetic characters, return null
  }

  // Remove all characters except digits and +
  mobile = mobile.replace(/[^0-9+]/g, "");

  // If the mobile number starts with +, return it as is
  if (mobile.startsWith("+")) {
    return mobile;
  }

  // If it does not start with +, prepend +32
  return "+32" + mobile;
}

export function normalizeDate(date: string | Date | undefined | null) {
  if (!date) return null;

  // Convert to Date object if it's a string
  const dateObj = typeof date === "string" ? new Date(date) : date;

  // Check if the date is valid
  if (isNaN(dateObj.getTime())) return null;

  // Return the date in 'YYYY-MM-DD' format
  return dateObj.toISOString().split("T")[0];
}

// Stoping the sales note recording
export const stopRecording = async (agentId: number, setSalesNote: any) => {
  try {
    setSalesNote((prev: any) => ({
      ...prev,
      activeRecordingId: null,
      triggerMeeting: false,
      isPlay: false,
    }));

    const data = {
      agentId: agentId,
    };

    const response = await ApiCall.service(
      STOP_RECORDING,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: SUCCESS, message: t('Recording stopped successfully') });
    }
    else if (response.status === 503) {
      CustomNotify({ type: ERROR, message: t(response.error) });
    }
    else {
      CustomNotify({ type: ERROR, message: t('An error occurred while stopping the recording') });
    }
  } catch (error) {
    CustomNotify({
      type: "error",
      message: "An error occurred while stopping the recording",
    });
  }
};

export const createQueryParams = (filters: Record<string, any>): string => {
  const queryStringArray: string[] = [];
  const currentUrl = window.location.href;
  const currentBaseUrl = currentUrl.split("?")[0];

  for (const [key, value] of Object.entries(filters)) {
    // Include the key only if it exists in filters
    if (filters.hasOwnProperty(key)) {
      if (value !== undefined) {
        if (Array.isArray(value) || typeof value === "object") {
          if (value?.hasOwnProperty("value")) {
            // If the "value" field is null, treat the key as `null`
            queryStringArray.push(
              `${encodeURIComponent(key)}=${
                value?.value === null
                  ? "null"
                  : encodeURIComponent(JSON.stringify(value))
              }`
            );
          } else {
            queryStringArray.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(
                JSON.stringify(value)
              )}`
            );
          }
        } else {
          // Handle normal values (strings, numbers, booleans)
          queryStringArray.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
          );
        }
      }
    }
  }

  // Join the array of query strings with "&"
  return `${currentBaseUrl}?${queryStringArray.join("&")}`;
};

export const filterAndMerge = <T extends Record<string, any>>(
  defaultObject: T,
  sourceObject: Record<string, any>
): Partial<T> => {
  return Object.keys(defaultObject).reduce((acc, key) => {
    if (key in sourceObject) {
      acc[key as keyof T] = sourceObject[key]; // Only include keys that exist in both
    }
    return acc;
  }, {} as Partial<T>); // Initialize accumulator as Partial<T>
};

export const getQueryParamsFromRedux = (
  urlParams: string,
  paramFilter?: any
): Record<string, any> => {
  // Get the current base URL using window.location
  const currentUrl = window.location.href;
  const currentBaseUrl = currentUrl.split("?")[0]; // Extract the base URL without query parameters
  const expectedBaseUrl = urlParams?.split("?")[0];

  // Check if the current base URL matches the expected base URL
  if (
    !currentBaseUrl.startsWith(expectedBaseUrl) &&
    !paramFilter
  ) {
    return {}; // Return an empty object if URLs don't match
  }

  const decodedValue = decodeURIComponent(urlParams?.split("?")[1]);
  const params: Record<string, any> = {};

  // Create URLSearchParams from the modified value
  const searchParams = new URLSearchParams(decodedValue);

  // Convert entries to an array
  const entries = Array.from(searchParams.entries());

  const allowedKeys = ["from", "to", "createdAt", "taskStart", "taskEnd", "followUpStart", "followUpEnd"];
  entries.forEach(([key, value]) => {
    try {
      const parsedValue = JSON.parse(value);
      if (allowedKeys.includes(key)) {
        const parsedDate = new Date(parsedValue);
        if (!isNaN(parsedDate.getTime())) {
          params[key] = parsedDate;
        } else {
          params[key] = null;
        }
      } else if (Array.isArray(parsedValue)) {
        params[key] = parsedValue.map((item) =>
          typeof item === "object" ? item : JSON.parse(item)
        );
      } else {
        params[key] = parsedValue;
      }
    } catch (error) {
      params[key] = decodeURIComponent(value);
    }
  });

  return params;
};

export const isFilterDataEmpty = (filterData: any): boolean => {
  return Object.values(filterData).every((value) => {
    // Check if the value is an empty string, null, or an empty array
    return (
      value === "" ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    );
  });
};

export const formatDateAndTime = (dateString: Date | null) => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad with zero
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0'); // Get hours and pad with zero
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes and pad with zero

  return `${day}/${month}/${year}, ${hours}:${minutes}`; // Return formatted date string
};




const CODE_LENGTHS: any = {
  AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
  CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
  FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
  HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
  LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
  MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
  RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
  AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
  SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
};

// Function to validate IBAN length only
export function isValidIBANNumber(iban: string) {

  const formattedIban = formatIban(iban);
  const code = extractCountryCode(formattedIban);

  // Check if the country code is valid
  if (!code || !(code in CODE_LENGTHS)) {
    return `Invalid IBAN: Country code '${code || ''}' is missing or incorrect.`;
  }

  // Check if the IBAN length matches the country requirement
  if (formattedIban.length !== CODE_LENGTHS[code]) {
    return `Invalid IBAN: Expected length ${CODE_LENGTHS[code]} for country code ${code}, but got ${formattedIban.length}.`;
  }

  // If the length matches, IBAN is valid for this check
  return true;
}

// Helper function to format IBAN (keep only alphanumeric characters)
function formatIban(iban: string) {
  return String(iban).toUpperCase().replace(/[^A-Z0-9]/g, '');
}

// Helper function to extract the country code
function extractCountryCode(iban: string) {
  return iban.slice(0, 2);
}
