import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../../static/images/EditIcon";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import ModalPopup from "components/common/atoms/ModalPopup";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { DELETE_VACANCY, GET_VACANCY_PDF, MANAGE_VACANCY } from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import ArchiveNew from "static/images/ArchiveNew";
import LinkTo from "components/common/atoms/LinkTo";
import * as CONST from "../../../../Constants";
import HeartIcon from "static/images/HeartIcon";
import HeartFillICon from "static/images/HeartFillICon";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import FollowUpIcon from "static/images/FollowUpIcon";
import RightSideBar, {
  FollowUpEntity,
} from "components/common/atoms/RightSideBar";
import {
  createQueryParams,
  filterAndMerge,
  formatDateAndTime,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import SearchFields from "components/common/atoms/SearchFields";
import { createFilterConfig } from "./Filters";
import ResetBtn from "components/common/atoms/ResetBtn";
import NavBar from "components/common/atoms/NavBar";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import InfoIcon from "static/images/InfoIcon";
import GetQueryParams from "components/common/services/GetQueryParams";
import CloneIcon from "static/images/CloneIcon";
import MatchingIcon from "static/images/MatchingIcon";
import ViewIcon from "static/images/ViewIcon";
import { SearchProps, VacancyData } from "../annotation/VacancyInterfaces";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";
import NoRecords from "components/common/atoms/NoRecords";
import DownloadIcon from "static/images/Download";
import ResumeIcon from "static/images/ResumeIcon";

// Initialize search data based on query params
export const defaultVacancySearchData: SearchProps = {
  office: [],
  companyName: [],
  companyType: null,
  function: null,
  aliasName: "",
  consultant: "",
  posting: null,
  vacancyStatus: null,
  from: "",
  to: "",
  hotlist: null,
  currentPage: 1,
  recordsPerPage: 10,
  orderByColumn: "",
  orderIn: "",
  fromDashboard: false
};

const ManageVacancies: React.FC = () => {
  const authUser = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [entity, setEntity] = useState<FollowUpEntity>({
    entityId: null,
    isInBright: false,
  });
  const [sidebarWidth, setSidebarWidth] = useState(""); // State to store sidebar width
  const [vacancies, setVacancies] = useState<VacancyData[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
    cursor: null,
  });

  const [dropdownList, setDropdownList] = useState({
    offices: [],
    functions: [],
    status: [],
    posting: [],
  });

  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );

  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const filters = createFilterConfig(dropdownList);

  //active tab status
  const defaultTabData = {
    tabFieldName: "companyType",
    tabFieldValue: null,
  };

  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    office: authUser?.role === CONST.RECRUITER ? [authUser?.officeId, authUser?.correspondingOffice?.id] : [],
    companyName: [],
    companyType: null,
    function: null,
    aliasName: "",
    consultant: "",
    posting: null,
    vacancyStatus: null,
    from: "",
    to: "",
    hotlist: null,
    currentPage: 1,
    recordsPerPage: 10,
    orderByColumn: "",
    orderIn: "",
    fromDashboard: false
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);

  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  const [activeTab, setActiveTab] = useState({
    ...defaultTabData,
    ...filteredTabData,
  });

  const navItems = [
    { label: "All", fieldName: "companyType", value: null },
    { label: "Customer", fieldName: "companyType", value: 2 },
    { label: "Prospect", fieldName: "companyType", value: 1 },
    { label: "Hotlist", fieldName: "hotlist", value: 1 },
  ];

  useEffect(() => {
    dispatch(clearDestinationPath());
    const filterData = { ...searchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const response = await ApiCall.service(
      MANAGE_VACANCY,
      "POST",
      data,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      const { vacanciesData, dropdownData, paginationData } = response.data;
      setVacancies(vacanciesData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  //** Archive functions */
  const ArchiveCompany = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleCloseArchiveModal = () => setShowModal(!showModal);

  const handleArchive = async () => {
    setShowModal(false);
    if (deleteId) {
      const response = await ApiCall.getService(
        `${DELETE_VACANCY}/${deleteId}`,
        "GET",
        CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        setVacancies((prevdata) =>
          prevdata.filter((vacancy) => vacancy.id !== deleteId)
        );
      }
    }
  };

  //** follow ups */
  const handleIconClick = (
    event: any,
    id: string | number | null,
    officeId: string | number | null,
    brightId: string | number | null
  ) => {
    event.preventDefault(); // Prevent the default link action
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        officeId: null,
        isInBright: false,
      });
      setIsSidebarOpen(false);
    } else {
      setEntity({
        entityId: id ?? null,
        officeId: officeId ?? null,
        isInBright: brightId !== null ? true : false,
      });
      setIsSidebarOpen(true);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setEntity({
      entityId: null,
      officeId: null,
      isInBright: false,
    });
  };

  //** hotlist /*
  const handleHotlist = (
    vacancyId: number | null,
    hotlisted: boolean | null
  ) => {
    const status = hotlisted == null || hotlisted == false ? true : false;

    const updatedVacancies = vacancies.map((vacancy) => {
      if (vacancy.id === vacancyId && vacancyId != undefined) {
        return { ...vacancy, hotlisted: status };
      }
      return vacancy;
    });

    setVacancies(updatedVacancies);
  };

  const submitHotlist = async () => {
    setLoading(true);
    const data = {
      type: "vacancy",
      data: vacancies,
    };
    const response = await ApiCall.service(
      "hotlist",
      "POST",
      data,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    } else if (response.status === 400) {
      CustomNotify({ type: "error", message: t(response.msg) });
    } else {
      CustomNotify({ type: "error", message: t("Something went wrong") });
    }
    setLoading(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  //** search filters */

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData({ ...defaultSearchData, office: [] });
    setActiveTab(defaultTabData);
  };

  const handleNavItemClick = (tabFieldName: string, tabFieldValue: any) => {
    setSearchData((prev) => {
      const updatedState: any = { ...prev };
      updatedState[tabFieldName] = tabFieldValue;
      updatedState["currentPage"] = 1;

      navItems.forEach((item) => {
        if (item.fieldName !== tabFieldName) {
          updatedState[item.fieldName] = null;
        }
      });

      return updatedState;
    });
    setActiveTab({ tabFieldName, tabFieldValue });
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      [fieldName]: date,
    }));
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  const getVacancyPdf = async (id: number) => {
    const data = {
      type: "vacancyCorner",
    };
    const url = `${GET_VACANCY_PDF}/${id}`
    const response = await ApiCall.service(
      url,
      "POST",
      data,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      window.open(response.data.filePath, "_blank");
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Vacancy",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div
        className={`main-container ${isSidebarOpen ? "sidebar-open" : ""
          } rightSidebar`}
        style={{ marginRight: isSidebarOpen ? sidebarWidth : undefined }}
      >
        <Title title={t("Vacancies")} />
        <div className="form-height-dashboard">
          <div className="search-bar">
            {authUser.role !== CONST.EMPLOYER && (
              <div className="row">
                <div className="col-md-12 company-tabs">
                  <NavBar
                    navItems={navItems}
                    onNavItemClick={handleNavItemClick}
                    className="custom-navbar"
                    activeItem={activeTab}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Vacancy",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/vacancy"
                    title={t("Create vacancy")}
                    icon={faPlus}
                    className="me-3"
                  />
                </AccessControl>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Vacancy",
                      update: true,
                    },
                  ]}
                >
                  {vacancies.length > 0 && (
                    <Button
                      title={t("Update hotlist")}
                      className="form-button"
                      handleClick={submitHotlist}
                    />
                  )}
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive tableSection">
              <table className="table manageVacancies border-0 mb-0">
                <thead>
                  <tr className="tableSearchFields">
                    <SearchFields
                      searchData={searchData}
                      filters={filters}
                      handleSearchChange={handleSearchChange}
                      handleSelectChange={handleSelectChange}
                      handleDateChange={handleDateChange}
                    />
                    <th className="border-0">
                      <div className="d-flex align-items-center w-100">
                        <ResetBtn handleResetClick={handleReset} />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="tableContainer scrollBarDesign">
                <table className="table table-hover manageVacancies">
                  <thead>
                    <tr className="TableHeader">
                      <th onClick={() => handleOrderByColumn("office")}>
                        {t("Office")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "office"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th>{t("Company")}</th>
                      <th onClick={() => handleOrderByColumn("function")}>
                        {t("Function")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "function"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("aliasName")}>
                        <div className="d-flex">
                          <TextEllipsis
                            label={t("Function alias name")}
                            title={t("Function alias name")}
                            width="10.5vw"
                          />
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "aliasName"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </div>
                      </th>
                      <th>{t("Posting")}</th>
                      <th>{t("Status")}</th>
                      <th>{t("Consultant")}</th>
                      <th>{t("Created at")}</th>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Vacancy",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                      >
                        <th>{t("Action")}</th>
                      </AccessControl>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <SpinnerWrapper headerLength={9} />
                    ) : (
                      <>
                        {isSidebarOpen && (
                          <RightSideBar
                            title={"Follow-ups"}
                            entityType={"vacancy"}
                            officeId={entity.officeId}
                            entityId={entity.entityId}
                            isInBright={entity.isInBright}
                            onClose={handleCloseSidebar}
                            getWidth={getWidth}
                            recordsLimit={10}
                            className="right-sidebar p-3"
                            titleClassName="mt-4"
                            destination="/manage/vacancies"
                          />
                        )}
                        {vacancies && vacancies.length > 0 ? (
                          vacancies.map((item, index) => {
                            let color =
                              item.vacancyStatus === 0
                                ? "bg-success"
                                : item.vacancyStatus === 1
                                  ? "bg-warning"
                                  : "bg-danger";
                            let unitsLeftCoopAgrmnt =
                              item.unit_count !== item.total_count;
                            return (
                              <tr
                                key={index}
                                className="border-bottom mb-3 box-shadow align-middle"
                              >
                                <td
                                  data-label={t("Office")}
                                  className="text-break"
                                >
                                  {item.officeName}
                                </td>
                                <td
                                  data-label={t("Company")}
                                  className="text-break"
                                >
                                  {item.company}
                                </td>
                                <td data-label={t("Function")}>
                                  <TextEllipsis
                                    label={t(`${item.function}`)}
                                    title={t(`${item.function}`)}
                                    width="10vw"
                                  />
                                </td>
                                <td
                                  data-label={t("Function alias name")}
                                  className="text-break"
                                >
                                  <a href={`/vacancy/overview/${item.id}`}>
                                    <TextEllipsis
                                      label={t(`${item.aliasName}`)}
                                      title={t(`${item.aliasName}`)}
                                      width="11.5vw"
                                    />
                                  </a>
                                </td>

                                <td
                                  data-label={t("Type")}
                                  className="text-break"
                                >
                                  {item.webOrVDAB}
                                </td>
                                <td data-label={t("Status")}>
                                  <span
                                    className={`d-inline-block rounded-circle status-icon ${color}`}
                                    data-toggle="tooltip"
                                    style={{ marginLeft: "0.8vw" }}
                                    title={t(
                                      `${CONST.vacancyStatus[
                                      item.vacancyStatus ?? 0
                                      ]
                                      }`
                                    )}
                                  ></span>
                                </td>
                                <td
                                  data-label={t("Consultant")}
                                  className="text-break"
                                >
                                  {item.consultant}
                                </td>
                                <td
                                  data-label={t("Created at")}
                                  className="text-break"
                                >
                                  {item.created}
                                </td>
                                <td className="table-action-icons px-2">
                                  <ActionIconWrapper initialVisibleCount={4}>
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Follow-up",
                                          read: true,
                                        },
                                      ]}
                                    >
                                      <Link
                                        to={""}
                                        title={t("View follow ups")}
                                        onClick={(e) => {
                                          handleIconClick(
                                            e,
                                            item.id ?? null,
                                            item.officeId ?? null,
                                            item.brightId ?? null
                                          );
                                        }}
                                      >
                                        <FollowUpIcon />
                                      </Link>
                                    </AccessControl>
                                    <Link
                                      to={`/matching-list/vacancy/${item.id}`}
                                      className="table-action-icons"
                                      state={{ origin: "vacancies" }}
                                    >
                                      <span title={t("Matching candidates")}>
                                        <MatchingIcon />
                                      </span>
                                    </Link>
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Vacancy",
                                          update: true,
                                        },
                                      ]}
                                    >
                                      <div
                                        onClick={(e) =>
                                          handleHotlist(item.id, item.hotlisted)
                                        }
                                        className="d-inline-block"
                                        title={
                                          item.hotlisted != null &&
                                            item.hotlisted
                                            ? t("Remove from hotlist")
                                            : t("Add to hotlist")
                                        }
                                      >
                                        {item.hotlisted != null &&
                                          item.hotlisted ? (
                                          <HeartFillICon />
                                        ) : (
                                          <HeartIcon />
                                        )}
                                      </div>
                                    </AccessControl>
                                    <Link
                                      to={""}
                                      title={t("Download")}
                                      onClick={() => getVacancyPdf(item.id)}
                                    >
                                      <ResumeIcon />
                                    </Link>
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Vacancy",
                                          update: true,
                                        },
                                      ]}
                                    >
                                      <div className="d-inline-block">
                                        <Link
                                          to={`/vacancy/${item.id}`}
                                          title={t("Edit")}
                                        >
                                          <EditIcon />
                                        </Link>
                                      </div>
                                    </AccessControl>
                                    <Link
                                      to={`/vacancy/overview/${item.id}`}
                                      title={t("View")}
                                    >
                                      <ViewIcon />
                                    </Link>
                                   

                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Vacancy",
                                          delete: true,
                                        },
                                      ]}
                                    >
                                      <div
                                        title={t("Archive")}
                                        onClick={() => ArchiveCompany(item.id)}
                                        className="d-inline-block cursor-pointer"
                                      >
                                        <ArchiveNew />
                                      </div>
                                    </AccessControl>
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Vacancy",
                                          create: true,
                                        },
                                      ]}
                                    >
                                      <Link
                                        to={`/clone/vacancy/${item.id}`}
                                        title={t("Clone")}
                                      >
                                        <CloneIcon />
                                      </Link>
                                    </AccessControl>
                                    <div>
                                      <UserInfoTooltip
                                        createdBy={item.created_by}
                                        createdAt={formatDateAndTime(
                                          item.created_at
                                        )}
                                        updatedBy={item.updated_by}
                                        updatedAt={formatDateAndTime(
                                          item.updated_at
                                        )}
                                      />
                                    </div>
                                  </ActionIconWrapper>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <NoRecords headerLength={10} />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {!loading && (
                <div className="pagination d-block">
                  <PaginationNew
                    currentPage={searchData.currentPage}
                    totalPages={paginationData.totalPages}
                    totalRecords={paginationData.totalRecords}
                    recordsPerPage={searchData.recordsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={(newPage: any) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link to="/dashboard" className=" back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleCloseArchiveModal}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive") + "?"}
          onCloseButtonClick={handleCloseArchiveModal}
          onConfirmButtonClick={handleArchive}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </div>
    </AccessControl>
  );
};
export default translate(ManageVacancies);
