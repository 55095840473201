import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // If you're using react-router
import ButtonWithChildren from "../atoms/ButtonWithChildren";
import { createQueryParams } from "./HelperFunctions";
import { t } from "components/CentralDataMangement/translation/Translation";

interface ButtonItem {
  label?: string;
  icon?: ReactElement;
  path?: string;
}

interface CommonButtonGroupProps {
  buttons: ButtonItem[];
  filters: { [key: string]: any };
}

const ActionButtons: React.FC<CommonButtonGroupProps> = ({
  buttons,
  filters,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (path: string, label: string) => {
    if (label !== "Overview") {
      dispatch(clearDestinationPath());
      // Add currentPage and recordsPerPage to filters
      const updatedFilters = {
        ...filters, // Spread existing filters
        currentPage: 1, // Add currentPage
        recordsPerPage: 10, // Add recordsPerPage
      };

      const searchUrl = createQueryParams(updatedFilters);

      dispatch(setDestinationPath(searchUrl));
      navigate(path);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="d-inline-flex align-items-center">
      {buttons.map((button, index) => (
        <ButtonWithChildren
          key={index}
          onClick={() => handleClick(button.path ?? "", button.label ?? "")}
          className="form-manage-button headerBtn marginRightPoint5"
        >
          <>{button.icon}</>
          {button.label && <span style={{ marginLeft: "0.3vw" }}>{t(button.label)}</span>}
        </ButtonWithChildren>
      ))}
    </div>
  );
};

export default ActionButtons;
