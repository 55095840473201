import React, { useRef, useEffect } from 'react';

interface AutoCompleteDropdownProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string;
}

const AutoCompleteDropDownForMap = React.forwardRef<HTMLInputElement, AutoCompleteDropdownProps>((props, ref) => {
  const { onChange, ...otherProps } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref && inputRef.current) {
      (ref as React.MutableRefObject<HTMLInputElement>).current = inputRef.current;
    }
  }, [ref]);

  return <input ref={inputRef} {...otherProps} onChange={onChange} className="form-control field-shadow" />;
});

export default AutoCompleteDropDownForMap;
