import React from 'react'

const UnArchive: React.FC = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5vw" height="1.5vw" viewBox="0 -5 35 43" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.12709 0.047208C4.76479 0.115567 4.23843 0.368496 3.84194 0.655604C3.67105 0.785486 3.03531 1.55794 2.26969 2.55599C0.827309 4.44953 0.635904 4.71613 0.396647 5.20148C0.374852 5.24507 0.354253 5.28421 0.334783 5.32121C0.312278 5.36398 0.291283 5.40387 0.271697 5.44445C-0.000745237 6.00885 -0.000693614 6.70478 7.61203e-05 17.0809C0.000119169 17.6612 0.000164464 18.2718 0.000164464 18.9143C0.000164464 27.4729 0.0206722 31.5197 0.0753594 31.8C0.19157 32.4767 0.635904 33.2971 1.15543 33.8166C1.67496 34.3361 2.49527 34.7804 3.17202 34.8967C3.7394 34.9992 31.2608 34.9992 31.8281 34.8967C32.5049 34.7804 33.3252 34.3361 33.8447 33.8166C34.3643 33.2971 34.8086 32.4767 34.9248 31.8C34.9795 31.5197 35 27.4729 35 18.9143C35 7.61456 34.9863 6.39776 34.8906 5.99445C34.7676 5.50226 34.5488 5.03058 34.2275 4.55207C33.79 3.90949 30.9668 0.546229 30.7481 0.409511C30.625 0.334316 30.3858 0.21127 30.2149 0.136075C29.9145 0.00636871 29.6073 0.00619279 17.7195 -0.000615576L17.671 -0.000643354C10.8419 -0.00747926 5.29799 0.0130285 5.12709 0.047208ZM29.8184 2.8636L30.6934 3.8343L24.0694 3.85481C20.4258 3.86164 14.4376 3.86164 10.7736 3.85481L4.10171 3.8343L4.59389 3.21907C4.86049 2.87727 5.21596 2.43977 5.38002 2.24153L5.68764 1.88607H28.9502L29.8184 2.8636ZM13.6034 26.3984V22.5019H6.80163L12.1541 17.1562L17.4998 11.8037L22.8455 17.1562L28.198 22.5019H21.3963V26.3984H13.6034Z" fill="currentColor" />
      </svg>


    </>
  )
}

export default UnArchive;