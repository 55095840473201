import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList  {
  offices: OptionProps[],
  cities: OptionProps[],
  compStatus: OptionProps[],
  pc: OptionProps[],
  functions: OptionProps[],
}
export const createFilterConfig = (dropDownList:dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.offices,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: 'search'
    },
    {
      name: "company",
      fieldType: "text",
      placeholder: "Company",
      filterType: 'search'
    },
    {
      name: "vat",
      fieldType: "text",
      placeholder: "VAT",
      type: 'number',
      filterType: 'search'
    },
    {
      name: "location",
      fieldType: "text",
      placeholder: "Location",
      filterType: 'search'
    },
    {
      name: "city",
      options: dropDownList.cities,
      fieldType: "singleSelect",
      placeholder: "City",
      filterType: 'search',
      prefixClass: 'selectSection'
    },
    {
      name: "email",
      fieldType: "text",
      placeholder: "Email",
      filterType: 'search'
    },
    {
      name: "phone",
      fieldType: "text",
      placeholder: "Phone number",
      filterType: 'search'
    },
    {
      name: "CompStatus",
      options: dropDownList.compStatus,
      fieldType: "singleSelect",
      placeholder: "Status",
      filterType: 'search',
      prefixClass: 'selectSection'
    },
    {
      name: "street",
      fieldType: "text",
      placeholder: "Street",
      filterType: 'advSearch',
      label: "Street",
      autoComplete: "off"
    },
    {
      name: "pc",
      options: dropDownList.pc,
      fieldType: "singleSelect",
      placeholder: "PC",
      filterType: 'advSearch',
      label: "PC",
      autoComplete: "off"
    },
    {
      name: "function",
      options: dropDownList.functions,
      fieldType: "singleSelect",
      placeholder: "Function",
      filterType: 'advSearch',
      label: "Function",
      autoComplete: "off"
    }
  ];
};
