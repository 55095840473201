import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
  offices: OptionProps[];
  compStatus: OptionProps[];
}
export const createFilterConfig = (dropDownList: dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.offices,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: 'search'
    },
    {
      name: "company",
      fieldType: "text",
      placeholder: "Company",
      filterType: 'search'
    },
    {
      name: "firstName",
      fieldType: "text",
      placeholder: "First name",
      filterType: 'search'
    },
    {
      name: "lastName",
      fieldType: "text",
      placeholder: "Last name",
      filterType: 'search'
    },
    {
      name: "email",
      fieldType: "text",
      placeholder: "Email",
      filterType: 'search'
    },
    {
      name: "phone",
      fieldType: "text",
      placeholder: "Phone number",
      filterType: 'search',
      type: 'number',
    },
    {
      name: "gsm",
      fieldType: "text",
      placeholder: "GSM",
      filterType: 'search',
      type: 'number',
    },
    {
      name: "function",
      fieldType: "text",
      placeholder: "Function",
      filterType: 'search'
    },
    {
      name: "role",
      fieldType: "text",
      placeholder: "Role",
      filterType: 'search'
    },
    {
      name: "location",
      fieldType: "text",
      placeholder: "Location",
      filterType: 'search'
    },
    {
      name: "CompStatus",
      options: dropDownList.compStatus,
      fieldType: "singleSelect",
      placeholder: "Status",
      filterType: 'search'
    },
  ];
};
