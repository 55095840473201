export const SetAddressData = async (data: any, dispatch: any, state: any) => {
  let { street, nr, bus, postal, city, country, coordinates } = data;

  //*** set lat and lang */
  let lat = "";
  let lng = "";

  if (coordinates !== "") {
    [lat, lng] = coordinates
      .replace("Lat: ", "")
      .replace("Lng: ", "")
      .split(", ");
  }

  dispatch({
    type: "UPDATE_GENERAL_FIELD",
    field: "initialLat",
    value: lat,
  });

  dispatch({
    type: "UPDATE_GENERAL_FIELD",
    field: "initialLn",
    value: lng,
  });

  //****  */

  // Match country with case-insensitive comparison
  if (country !== "") {
    country = state.dropDowndata.countryList.find((option: any) =>
      option.label.toLowerCase().includes(country.toLowerCase())
    );
  }

  // If country matches, filter cities by removing prefixes and using case-insensitive comparison
  if (country && country.value === 29 && city !== "") {
    city = state.dropDowndata.citiesList.find((option: any) =>
      option.name.toLowerCase().includes(city.toLowerCase())
    ) || null;
  }

  let resedentialAdd = {
    street: street,
    number: nr,
    city: country && country.value === 29 ? city : null,
    country: country,
    zipCode: postal,
    box: bus,
    cityOther: country === "" || (country && country.value !== 29) ? city : "",
  };

  Object.entries(resedentialAdd).forEach(([key, value]) => {
    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field: key,
      value: value,
    });
  });

  if (state.general.domCheckbox === 1 || state.general.domCheckbox === true) {
    Object.entries(resedentialAdd).forEach(([key, value]) => {
      const domField = `dom${key.charAt(0).toUpperCase()}${key.slice(1)}`; // Convert key to domKey format
      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: domField,
        value: value,
      });
    });
  }
};
