import React, { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import * as ENDPOINTS from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import "./cities.css";
import Reset from "static/images/Reset";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "components/common/atoms/LinkTo";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import NoRecords from "components/common/atoms/NoRecords";

interface manageData {
  regionName: string;
  countryName: string;
  id: number;
}

interface dropDownsProps {
  region: OptionProps[];
  country: OptionProps[];
}

const ManageCityProfile: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<dropDownsProps>({
    region: [],
    country: [],
  });

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    region: [] as OptionProps[],
    country: [] as OptionProps[],
  });

  //state for to store the functions linked countries
  const [countries, setCountries] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCountryBySearch();
  }, []);

  //page index based on page number
  // let pageIndex = (currentPage - 1) * 20 + 1;

  //API call for to fetch countries
  const fetchCountryBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_CITIES_DETAILS,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      const regions = mapToSelect(response.regions);
      const countries = mapToSelect(response.countries);
      setDropDowns({
        region: regions,
        country: countries,
      });
      setCountries(response.data);
      setTotalPages(response.totalPages);
    }
  };

  const handleCountryChange = (selectedCountryIds: any) => {
    const fetchRegionForCountryDetails = async () => {
      const response = await ApiCall.service(
        `${ENDPOINTS.GET_REGION_FOR_COUNTRIES}`,
        "POST",
        selectedCountryIds,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        setFormData((prevData) => ({
          ...prevData,
          region: response.data,
        }));
      }
    };
    fetchRegionForCountryDetails();
  };

  const handleRegionChange = (selectedRegionIds: any) => {
    const fetchCountryDetailsForRegion = async () => {
      const response = await ApiCall.service(
        `${ENDPOINTS.GET_COUNTRIES_FOR_REGION}`,
        "POST",
        selectedRegionIds,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        const countries = mapToSelect(response.data);
        setDropDowns((prevData) => ({
          ...prevData,
          country: countries,
        }));
      }
    };
    fetchCountryDetailsForRegion();
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "country") {
      const selectedCountryIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleCountryChange(selectedCountryIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "region") {
      const selectedRegionIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleRegionChange(selectedRegionIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.region.length === 0 && formData.country.length === 0) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchCountryBySearch();
    } else {
      fetchCountryBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      region: [],
      country: [],
    });
    setCurrentPage(1);
    fetchCountryBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchCountryBySearch(formData, newPage);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "City profiles",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("City profiles")} />
      <div className="row search-bar">
        <div className="col-4">
          <SelectWithSearch
            search={true}
            options={dropDowns.region}
            onChange={(e) => handleSelectChange(e, "region")}
            isMulti={true}
            title={t("Region")}
            name="region"
            value={formData.region}
            isTranslate={true}
          />
        </div>
        <div className="col-4">
          <SelectWithSearch
            search={true}
            options={dropDowns.country}
            onChange={(e) => handleSelectChange(e, "country")}
            isMulti={true}
            name="country"
            title={t("Country")}
            value={formData.country}
            isTranslate={true}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              icon={faMagnifyingGlass}
              handleClick={handleSubmit}
              className="form-button shadow-none search-btns position-relative text-start"
            />
          </div>
        </div>
      </div>
      {/* Display the table of city profiles */}
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "City profiles",
                  create: true,
                },
              ]}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/add/city-profile"
                    title={t("Add city")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4" style={{ width: "35%" }}>
                      {t("Region")}
                    </th>
                    <th style={{ width: "35%" }}>{t("Country")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "City profiles",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {countries && countries.length > 0 ? (
                    countries.map((country) => (
                      <tr
                        key={country.id}
                        className="border-bottom mb-3 box-shadow align-middle"
                      >
                        {/* <td className="ps-lg-4">{pageIndex++}</td> */}
                        <td className="ps-lg-4" data-label={t("Region")}>
                          {t(`${country.regionName}`)}
                        </td>
                        <td data-label={t("Country")}>
                          {t(`${country.countryName}`)}
                        </td>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "City profiles",
                              update: true,
                              delete: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                        >
                          <td className="table-action-icons px-2">
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "City profiles",
                                  update: true,
                                },
                              ]}
                            >
                              <Link
                                to={`/edit/city-profile/${country.id}`}
                                title={t("Edit")}
                                className="marginRightPoint5"
                              >
                                <EditIcon />
                              </Link>
                            </AccessControl>
                            <Link
                              to={`/view/city-profile/${country.id}`}
                              className="btn p-0 border-0 me-2"
                              title={t("View")}
                            >
                              <ViewIcon />
                            </Link>
                          </td>
                        </AccessControl>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={7} />
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4 px-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Back button */}
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to="/config/settings"
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageCityProfile);
