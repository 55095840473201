import { intialJobApplicationSearchData } from "components/CandidateCreation/JobApplications/manage-job-applications/Interface";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { defaultVacancySearchData } from "components/CentralDataMangement/vacancies/manage-vacancy/ManageVacancies";
import CardTitle from "components/common/atoms/CardTitle";
import Count from "components/common/atoms/Count";
import DashboardCard from "components/common/atoms/DashboardCard";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { createQueryParams, filterAndMerge } from "components/common/utlis/HelperFunctions";
import { clearDestinationPath, setDestinationPath } from "features/NavigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const DashboardImage: React.FC<{ newVacancies: number, newCandidates: number, officeId: number | null }> = ({ newVacancies = 0, newCandidates = 0, officeId }) => {
  const dashboardCardProps = {
    value_punctuation: t("to"),
    width: "0.2vw",
    height: "1.563vw",
    backgroundColor: "#f3d0e3",
    className: "recruitment-card-num ms-2",
  };
  const dispatch = useDispatch();

  

  const setNewVacancyOrJobApplicationsFilters = (type: 'vacancy' | 'jobApplications') => {
    const currentDate = new Date();
    const last30thDayDate = new Date();
    last30thDayDate.setDate(currentDate.getDate() - 30);

    dispatch(clearDestinationPath());

    const initialFilters = {
      office: [officeId],
      from: last30thDayDate,
      to: currentDate
    }

    const updateAndDispatch = (filters: any) => {
      const searchUrl = createQueryParams(filters);
      dispatch(setDestinationPath(searchUrl));
    }

    if (type === "vacancy") {
      const updatedFilters = filterAndMerge(defaultVacancySearchData, {...initialFilters, vacancyStatus: {value: 0, label: "Open"}, fromDashboard: true});
      updateAndDispatch(updatedFilters);
    } else if (type === "jobApplications") {
      const updatedFilters = filterAndMerge(intialJobApplicationSearchData, initialFilters);
      updateAndDispatch(updatedFilters);
    }
  }

  const cardTitleProps = {
    className: "mb-3 recruitment-card-title",
  };
  return (
    <div className="col-6">
      <div
        className="new-vacancies-cadidates d-grid"
        style={{ marginBottom: "1vw", gridTemplateColumns: "repeat(2, 1fr)", gap: "1vw" }}
      >
        <AccessControl
          requiredPermissions={[
            {
              permission: "New companies",
              read: true,
            },
          ]}
          renderNoAccess={false}
        >
          <div>
            <Link to={"/manage-companies"}>
              <DashboardCard
                className={`card border-0 card-shadow justify-content-center new-vacancies-cadidates-card`}
              >
                <div className="row">
                  <div className="col-md-12 align-self-center">
                    <CardTitle title={t("New companies")} {...cardTitleProps} />
                    <Count
                      value={0}
                      totalValue={t("go")}
                      {...dashboardCardProps}
                    ></Count>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
        </AccessControl>
        <AccessControl
          requiredPermissions={[
            {
              permission: "New vacancies",
              read: true,
            },
          ]}
          renderNoAccess={false}
        >
          <div onClick={() => setNewVacancyOrJobApplicationsFilters('vacancy')}>
            <Link to={"/manage/vacancies"}>
              <DashboardCard
                className={`card border-0 card-shadow justify-content-center new-vacancies-cadidates-card`}
              >
                <div className="row">
                  <div className="col-md-12 align-self-center">
                    <CardTitle
                      title={t("New vacancies")}
                      {...cardTitleProps}
                    ></CardTitle>
                    <Count
                      value={newVacancies}
                      totalValue={t("go")}
                      {...dashboardCardProps}
                    ></Count>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
        </AccessControl>
        <AccessControl
          requiredPermissions={[
            {
              permission: "New candidates",
              read: true,
            },
          ]}
          renderNoAccess={false}
        >
          <div onClick={() => setNewVacancyOrJobApplicationsFilters('jobApplications')}>
            <Link to={"/manage/job-applications"}>
              <DashboardCard
                className={`card border-0 card-shadow justify-content-center new-vacancies-cadidates-card`}
              >
                <div className="row">
                  <div className="col-md-12 align-self-center">
                    <CardTitle
                      title={t("New candidates")}
                      {...cardTitleProps}
                    />
                    <Count
                      value={newCandidates}
                      totalValue={t("go")}
                      {...dashboardCardProps}
                    />
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
        </AccessControl>
      </div>
      <div className="position-relative" style={{ height: "25vw" }}>
        <img
          src="../static/images/Welcome-dashboard.png"
          alt="Dashbaord"
          className="img-fluid rounded-4 h-100 object-fit-cover w-100"
        />
      </div>
    </div>
  );
};

export default translate(DashboardImage);
