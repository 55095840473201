import React, { useEffect, useState } from "react";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import Calender from "components/common/molecules/Calender";
// import RenderInflationInputFields from "./RenderInflationInputFields";
import { LabelWithInputFieldNoError } from "components/common/molecules/LabelWithInputFieldNoError";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/common/atoms/Button";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { scrollToTop } from "components/common/services/ValidationService";
import CustomNotify from "components/common/atoms/CustomNotify";
import "./inflation.css";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { MANAGE_INFLATIONS } from "routes/RouteConstants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import NoRecords from "components/common/atoms/NoRecords";

interface RegionCountry {
  region: Option | null;
  regionError: boolean;
  country: Option | null;
  countryError: boolean;
  fromDate: Date | null;
  fromDateError: boolean;
  tableStatus: boolean;
  dbError: string | null;
  dbDate: Date | null;
}
interface RangeProfileTypes {
  min: string | null;
  desired: string | null;
  max: string | null;
}

interface EmployeeTypeObj {
  categotyExist: boolean;
  category: string | null;
  employeeType: string;
  inflationFactor: string;
  inflationFactorStatus: boolean;
  [key: number]: RangeProfileTypes;
}

interface EmployeeCategoryType {
  [key: number]: EmployeeTypeObj;
}

interface InitialState {
  [key: number]: EmployeeCategoryType;
}

const InflationCoefficientsForm = () => {
  const params = useParams();
  const id = params.id;
  const location = useLocation();
  const navigate = useNavigate();
  const isViewMode = location.pathname.includes("view");
  //initialize basic state
  const [formFields, setFormFields] = useState<RegionCountry>({
    region: null,
    regionError: false,
    country: null,
    countryError: false,
    fromDate: null,
    fromDateError: false,
    tableStatus: false,
    dbError: null,
    dbDate: null,
  });
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [data, setData] = useState<InitialState>({});
  const [dropdownDetails, setDropdownDetails] = useState({
    regions: [] as OptionProps[],
    countries: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchNestedInflationsObj = async () => {
      const url = ENDPOINTS.CONF_SETTINGS_EDIT;

      const data = {
        id: id,
        type: "inflation",
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        setData(response.data.inflationObj);
        setDropdownDetails({
          regions: mapToSelect(response.data["regions"]),
          countries: response.data["countries"],
        });
        setFormFields((prevData) => ({
          ...prevData,
          region: response.data["region"],
          country: response.data["country"],
          fromDate:
            response.data["fromDate"] !== null
              ? new Date(response.data["fromDate"])
              : null,
          dbDate:
            id && response.data["fromDate"] !== null
              ? new Date(response.data["fromDate"])
              : null,
        }));
      }
    };
    fetchNestedInflationsObj();
  }, [id]);

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    fileIdError: string
  ) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setFormFields((prevData) => ({
        ...prevData,
        country: null,
      }));
    }
    setFormFields((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption.value !== "" ? selectedOption : null,
      [fileIdError]: selectedOption.value === "" ? true : false,
    }));
  };

  const handleDateChange = (date: Date | null) => {
    const updatedDate = date !== null ? date : null;
    setFormFields((prevData) => ({
      ...prevData,
      fromDate: updatedDate,
      fromDateError: updatedDate === null ? true : false,
      dbError: null,
    }));
  };

  const updateIndexBasedCoefficients = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: number,
    empType: string
  ) => {
    const value = event.target.value;
    const employeeType = parseInt(empType);
    const newData = { ...data };
    const newValue = value.replace(/[^0-9,]/g, "");
    const regex = /^(\d{1,2})(,\d{0,4})?$/;
    let matches = regex.exec(newValue);
    if (matches || value === "") {
      newData[category][employeeType]["inflationFactor"] = newValue;
      if (value === "" || value === null) {
        newData[category][employeeType]["inflationFactorStatus"] = true;
      } else {
        newData[category][employeeType]["inflationFactorStatus"] = false;
      }
    }
    setData(newData);
  };

  function hasNonEmptyInflation(nestedObj: any) {
    var booleanStatus = false;
    for (const key in nestedObj) {
      for (const key1 in nestedObj[key]) {
        const newObj = { ...data };
        if (
          nestedObj[key][key1].inflationFactor === null ||
          nestedObj[key][key1].dbFromDateinflationFactor === ""
        ) {
          newObj[parseInt(key)][parseInt(key1)].inflationFactorStatus = true;
          setData(newObj);
          booleanStatus = true;
        } else {
          newObj[parseInt(key)][parseInt(key1)].inflationFactorStatus = false;
          setData(newObj);
        }
      }
    }
    return booleanStatus;
  }

  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const regionBasedCountries = dropdownDetails.countries.filter(
        (country) =>
          country.region_id !== undefined &&
          selectedRegion === country.region_id
      );
      return mapToSelect(regionBasedCountries);
    }
    return [];
  };

  const checkFormValidation = () => {
    setFormFields((prevData) => ({
      ...prevData,
      regionError:
        formFields?.region?.hasOwnProperty("value") &&
        formFields.region.value !== null
          ? false
          : true,
      countryError:
        formFields?.country?.hasOwnProperty("value") &&
        formFields.country.value !== null
          ? false
          : true,
      fromDateError: formFields.fromDate !== null ? false : true,
      tableStatus: hasNonEmptyInflation(data),
    }));
    return (
      formFields?.region?.hasOwnProperty("value") &&
      formFields.region.value !== null &&
      formFields?.country?.hasOwnProperty("value") &&
      formFields.country.value !== null &&
      formFields.fromDate !== null &&
      hasNonEmptyInflation(data)
    );
  };

  const decideFromDate = (date: Date | null, flow: string) => {
    if (flow === "edit" && date !== null && date > new Date()) {
      return date;
    } else {
      return new Date();
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formIsValid = checkFormValidation();

    if (
      formIsValid !== undefined &&
      formIsValid !== true &&
      formFields.fromDate !== null
    ) {
      const payload = {
        fromDate: formFields.fromDate ? formFields.fromDate : null,
        region: formFields.region,
        country: formFields.country,
        inflations: data,
        id: id ? id : null,
        type: "inflation",
      };

      const url = ENDPOINTS.CONF_SETTINGS_ADD;
      const response = await ApiCall.service(
        url,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        navigate(MANAGE_INFLATIONS);
        CustomNotify({
          type: "success",
          message: t(response.msg),
          autoClose: 2000,
        });
      }
      if (response.status === 400) {
        scrollToTop();
        setFormFields((prevData) => ({
          ...prevData,
          dbError: response.errors.error[0],
        }));
      }
    } else {
      scrollToTop();
      CustomNotify({
        type: "error",
        message: t("Please check the errors"),
        autoClose: 2000,
      });
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Inflations",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title
        title={
          isViewMode
            ? t("View inflation")
            : id
            ? t("Edit inflation")
            : t("Add inflation")
        }
      />
      <form onSubmit={handleSubmit}>
        {formFields.dbError && (
          <span className="text-danger">{formFields.dbError}</span>
        )}
        <div className="row search-bar">
          <div className="col-lg-4 col-md-6 position-relative">
            <Calender
              onChange={(date) => handleDateChange(date)}
              selectedDate={formFields.fromDate}
              label={t("From date")}
              name="inflationStartDate"
              minDate={
                id
                  ? decideFromDate(formFields.dbDate, "edit")
                  : decideFromDate(new Date(), "create")
              }
              maxDate={null}
              isMandatory={true}
              isDisabled={isViewMode}
              error={
                formFields.fromDateError ? t("This field is required") : null
              }
            />
          </div>
          <div className="col-lg-4 col-md-6">
            <SelectWithSearch
              title={t("Region")}
              search={true}
              options={dropdownDetails.regions}
              isDisabled={id ? true : false}
              onChange={(e) => handleSelectChange(e, "region", "regionError")}
              isMulti={false}
              name="region"
              value={formFields.region}
              error={
                formFields.regionError ? t("This field is required") : null
              }
              isTranslate={true}
            />
            {}
          </div>
          <div className="col-lg-4 col-md-6">
            <SelectWithSearch
              title={t("Country")}
              search={true}
              options={getCountriesForRegion()}
              isDisabled={id ? true : false}
              onChange={(e) => handleSelectChange(e, "country", "countryError")}
              isMulti={false}
              name="country"
              value={formFields.country}
              error={
                formFields.countryError ? t("This field is required") : null
              }
              isTranslate={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="addInflationTable">
              <table className="table table-bordered">
                <thead>
                  <tr className="TableHeader align-middle">
                    <th className="text-center" style={{ width: "35%" }}>
                      {t("Employee category types")}
                    </th>
                    <th
                      className="text-center align-middle"
                      scope="rowgroup"
                      style={{ width: "35%" }}
                    >
                      {t("Employee type")}
                    </th>
                    <th className="text-center align-middle" scope="rowgroup">
                      {t("Inflations")}
                    </th>
                    {/* <th colSpan={3} className="text-center" scope="colgroup">
                  Payroll
                </th>
                <th colSpan={3} className="text-center" scope="colgroup">
                  Selectie
                </th> */}
                  </tr>
                  {/* <tr className="">
                <th className="text-center">
                  <strong>Min</strong>
                </th>
                <th className="text-center">
                  <strong>Desired</strong>
                </th>
                <th className="text-center">
                  <strong>Nice to have</strong>
                </th>
                <th className="text-center">
                  <strong>Min</strong>
                </th>
                <th className="text-center">
                  <strong>Desired</strong>
                </th>
                <th className="text-center">
                  <strong>Nice to have</strong>
                </th>
              </tr> */}
                </thead>
                <tbody>
                  {data && Object.keys(data).length > 0 ? (
                    Object.keys(data).map(
                      (key: any) =>
                        data[key] &&
                        Object.entries(data[key]).map(([key1, value]) => (
                          <tr key={key1} className="align-middle inflationData">
                            {value["categotyExist"] && (
                              <td
                                className="text-center text-break inflationData"
                                rowSpan={Object.keys(data[key]).length}
                                scope="rowgroup"
                              >
                                {t(`${value["category"]}`)}
                              </td>
                            )}
                            <td scope="row" className="text-break">
                              {t(`${value["employeeType"]}`)}
                            </td>
                            <td
                              className={`form-group p-0  ${
                                data[key][parseInt(key1)].inflationFactorStatus
                                  ? "error-border"
                                  : ""
                              }`}
                            >
                              <LabelWithInputFieldNoError
                                type="text"
                                className="coefficient w-100 h-100 border-0  text-center rounded-0"
                                value={
                                  value["inflationFactor"]
                                    ? value["inflationFactor"]
                                    : ""
                                }
                                handleChange={(e) =>
                                  updateIndexBasedCoefficients(e, key, key1)
                                }
                                isDisabled={isViewMode}
                              />
                            </td>
                            {/* <RenderInflationInputFields
            inputObj={value[0]}
          />
          <RenderInflationInputFields
            inputObj={value[1]}
          /> */}
                          </tr>
                        ))
                    )
                  ) : (
                    <NoRecords headerLength={3} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12">
            {formFields.tableStatus && (
              <p className="text-danger">
                {t("Fill in all the blank fields that are highlighted") + "."}
              </p>
            )}
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            <Link
              to="/manage/inflations"
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          {!isViewMode && (
            <div className="col-md-8">
              <Button
                title={t("Submit")}
                type="submit"
                className="form-button float-end shadow-none"
              />
            </div>
          )}
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(InflationCoefficientsForm);
