export const SetAddressData = async (data: any, dispatch: any, state: any, addressType: number) => {
  let { street, nr, bus, postal, city, country, coordinates } = data;

  //*** set lat and lang */
  let lat = "";
  let lng = "";

  if (coordinates !== "") {
    [lat, lng] = coordinates
      .replace("Lat: ", "")
      .replace("Lng: ", "")
      .split(", ");
  }

  const fieldToUpdate = [
    { field: "initialLat", value: lat },
    { field: "initialLn", value: lng },
    { field: "biInitialLat", value: lat },
    { field: "biInitialLn", value: lng },
  ];

  // Filter fields based on the addressType
  const fieldsToProcess =
    addressType === 0
      ? fieldToUpdate.slice(0, 2) // First two fields
      : fieldToUpdate.slice(2);  // Remaining fields (biInitialLat and biInitialLn)

  // Iterate over the filtered array and dispatch updates
  fieldsToProcess.forEach(({ field, value }) => {
    dispatch({
      type: "UPDATE_ADDRESS_FIELD",
      field,
      value,
    });
  });

  // Match country with case-insensitive comparison
  if (country !== "") {
    country = state.countryList.find((option: any) =>
      option.label.toLowerCase().includes(country.toLowerCase())
    );
  }

  // If country matches, filter cities by removing prefixes and using case-insensitive comparison
  if (country && country.value === 29 && city !== "") {
    city = state.cityList.find((option: any) =>
      option.label.toLowerCase().includes(city.toLowerCase())
    ) || null;
  }

  let officialAddress = {
    street: street,
    number: nr,
    city: country && country.value === 29 ? city : null,
    country: country,
    zipCode: postal,
    box: bus,
  };


  if (addressType == 0) {
    Object.entries(officialAddress).forEach(([key, value]) => {
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: key,
        value: value,
      });
    });
  }

  if (state.address.sameAddress == true || addressType == 1) {
    Object.entries(officialAddress).forEach(([key, value]) => {
      const billingFieldName =
        "bi" + key.charAt(0).toUpperCase() + key.slice(1);
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: billingFieldName,
        value: value,
      });
    });
  }
};
