export const SetCandidateData = (
  data: any,
  dispatch: any,
  id: string | null | undefined = null,
  state: any,
  applicationId: string | null | undefined = null
) => {
  const dropdownData = data.dropDownDetails;
  // Dispatch the action to update the dropdown data
  dispatch({ type: "SET_DROPDOWN_DATA", payload: dropdownData });
  dispatch({
    type: "UPDATE_COMPETENCES",
    payload: data?.competences,
  });
  dispatch({ type: "UPDATE_CHILD_COMPETENCES_DATA" });
  if (id || applicationId) {
    fillingContactForm(data, dispatch); // Common function to fill contact form for edit flow and parse resume flow
  } else {
    // Fields to update (consultant, office)
    const fieldsToUpdate = ["consultant", "office"];

    fieldsToUpdate.forEach((field) => {
      const value = data?.generalDetails?.[field] || null; // Use null if the field is not present
      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field,
        value,
      });
    });
  }
};

type DynamicActionType =
  | "UPDATE_GENERAL_FIELD"
  | "UPDATE_JOB_PROFILE_FIELD"
  | "UPDATE_PROFILE_FIELD"
  | "UPDATE_INTERNAL_INFO_FIELD";

export const fillingContactForm = (data: any, dispatch: any) => {
  const fieldMappings = [
    {
      fieldPrefix: "profile",
      key: "profileCardDetails",
    },
    {
      fieldPrefix: "general",
      key: "generalDetails",
    },
    {
      fieldPrefix: "job_profile",
      key: "jobProfileDetails",
    },
    {
      fieldPrefix: "other",
      key: "otherDetails",
    },
    {
      fieldPrefix: "internal_info",
      key: "internalInfoDetails",
    },
    {
      fieldPrefix: "permit",
      key: "workPermit",
    },
  ];

  fieldMappings.forEach(({ fieldPrefix, key }) => {
    if (key in data && data[key]) {
      const tabData = data[key];
      if (tabData && Object.entries(tabData).length > 0) {
        Object.entries(tabData).forEach(([field, value]) => {
          const dynamicActionType: DynamicActionType =
            `UPDATE_${fieldPrefix.toUpperCase()}_FIELD` as DynamicActionType;
          if (
            [
              "dateOfBirth",
              "greenCertificate",
              "studentCertificate",
              "horecaCertificate",
              "date",
              "perFrom",
              "perTo",
            ].includes(field) &&
            value &&
            typeof value === "string"
          ) {
            value = new Date(value);
          }
          
          if (field === "ssn") {
            const ssn: string = value as string;
            const pattern = /^(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})$/;
            const formattedSsn = ssn.replace(pattern, "$1.$2.$3-$4.$5");

            dispatch({
              type: dynamicActionType,
              field,
              value: formattedSsn,
            });
          } else {
            dispatch({
              type: dynamicActionType,
              field,
              value: value as string | null | number,
            });
          }
        });
      }
    }
  });

  const emergencyContactDetails = data["emergencyContactDetails"] ?? [];
  if (emergencyContactDetails && emergencyContactDetails.length > 0) {
    emergencyContactDetails.forEach(
      (emergencyContact: Record<string, any>, index: number) => {
        Object.entries(emergencyContact).forEach(([field, value]) => {
          dispatch({
            type: "EDIT_EMERGENCY_CONTACT_FIELD",
            field,
            value,
            index,
          });
        });
      }
    );
  }

  const studyDetails = data["studyDetails"] ?? [];
  if (studyDetails && studyDetails.length > 0) {
    studyDetails.forEach((study: Record<string, any>, index: number) => {
      Object.entries(study).forEach(([field, value]) => {
        if (
          (field === "from" || field === "to") &&
          value &&
          typeof value === "string"
        ) {
          value = new Date(value);
        }
        dispatch({
          type: "EDIT_STUDY_FIELD",
          field,
          value,
          index,
        });
      });
    });
  }

  const workDetails = data["workDetails"] ?? [];
  if (workDetails && workDetails.length > 0) {
    workDetails.forEach((work: Record<string, any>, index: number) => {
      Object.entries(work).forEach(([field, value]) => {
        if (
          (field === "exp_from" || field === "exp_to") &&
          value &&
          typeof value === "string"
        ) {
          value = new Date(value);
        }
        dispatch({
          type: "EDIT_WORK_FIELD",
          field,
          value,
          index,
        });
      });
    });
  }

  const certificateDetails = data["certificateDetails"] ?? [];
  if (certificateDetails && certificateDetails.length > 0) {
    certificateDetails.forEach(
      (certificate: Record<string, any>, index: number) => {
        Object.entries(certificate).forEach(([field, value]) => {
          if (
            (field === "valid" || field === "validUntill") &&
            value &&
            typeof value === "string"
          ) {
            value = new Date(value);
          }
          dispatch({
            type: "EDIT_CERTIFICATE_FIELD",
            field,
            value,
            index,
          });
        });
      }
    );
  }

  const documentDetails = data["documentDetails"] ?? [];
  if (documentDetails && documentDetails.length > 0) {
    documentDetails.forEach((document: Record<string, any>, index: number) => {
      Object.entries(document).forEach(([field, value]) => {
        if (
          (field === "validFrom" || field === "validTo" || field === "uploadedAt") &&
          value &&
          typeof value === "string"
        ) {
          value = new Date(value);
        }
        dispatch({
          type: "EDIT_DOCUMENT_FIELD",
          field,
          value,
          index,
        });
      });
    });
  }
};
