import { useEffect, useState } from "react";

import { ApiCall } from "components/common/services/ApiServices";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { t } from "components/CentralDataMangement/translation/Translation";

import Spinner from "react-bootstrap/esm/Spinner";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, OFFICE_MANAGER, RECRUITER } from "Constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ResumeIcon from "static/images/ResumeIcon";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { createFilterConfig } from "./Filters";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
// import AdvancedSearchBtn from "components/common/atoms/AdvancedSearchBtn";
import Title from "components/common/atoms/Title";
import AdvancedSearch from "components/common/molecules/AdvancedSearch";
import NavBar from "components/common/atoms/NavBar";
import {
  JobApplicationData,
  JobApplicationSearchData,
  dropDownList,
  intialDropDownList,
  intialJobApplicationSearchData,
} from "./Interface";
import {
  GET_JOB_APPLICATIONS_DETAILS,
  PHONE_CALL_URL,
} from "routes/ApiEndpoints";
import RightArrow from "static/images/RightArrow";
import "../jobapplication.css";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import NoRecords from "components/common/atoms/NoRecords";
import { selectAuth } from "features/auth/AuthSlice";

const ManageJobApplications: React.FC = () => {
  //*********Manage job applications page start *****************************************************************************************************/

  const [loading, setLoading] = useState(true);
  const authUser = useSelector(selectAuth);

  const [dropdownList, setDropdownList] =
    useState<dropDownList>(intialDropDownList);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jobApplications, setJobApplications] = useState<JobApplicationData[]>(
    []
  );
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
    cursor: null,
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const queryParams = getQueryParamsFromRedux(destinationPath);
  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: JobApplicationSearchData =
    {...intialJobApplicationSearchData, office: authUser?.role === RECRUITER || authUser?.role === OFFICE_MANAGER ? [authUser?.officeId, authUser?.correspondingOffice?.id] : []};

  //advanced search
  const defaultAdvSearchData = {};

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const filteredAdvSearchData = filterAndMerge(
    defaultAdvSearchData,
    queryParams
  );
  //active tab status
  const defaultTabData = {
    tabFieldName: "type",
    tabFieldValue: 1,
  };
  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  const [activeTab, setActiveTab] = useState({
    ...defaultTabData,
    ...filteredTabData,
  });

  const navItems = [
    { label: "New", fieldName: "type", value: 1 },
    { label: "In treatment", fieldName: "type", value: 2 },
    { label: "Completed", fieldName: "type", value: 3 },
    { label: "Everything", fieldName: "type", value: 4 },
  ];

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<JobApplicationSearchData>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  const [advSearchData, setAdvSearchData] = useState({
    ...defaultAdvSearchData,
    ...filteredAdvSearchData,
  });

  useEffect(() => {
    const filterData = { ...searchData, ...advSearchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };
    const response = await ApiCall.service(
      GET_JOB_APPLICATIONS_DETAILS,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      const { jobApplicationData, dropdownData, paginationData } =
        response.data;
      setJobApplications(jobApplicationData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** sorting */
  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: date,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const [searchModal, setSearchModal] = useState(false);

  const handleCloseSearchModal = () => {
    setSearchModal(false);
  };

  const handleSearchClick = () => {
    setSearchModal(true);
  };

  const handleSearchConfirm = () => {
    const filterData = { ...searchData, ...advSearchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    fetchDetails(filterData); // Pass the new search data directly
  };

  const clearAdvSearchFilter = () => {
    setAdvSearchData(defaultAdvSearchData);
    const filterData = { ...searchData, ...defaultAdvSearchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    fetchDetails(filterData);
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData({...defaultSearchData, office: []});
    setAdvSearchData(defaultAdvSearchData);
    setActiveTab(defaultTabData);
  };

  const handleNavItemClick = (tabFieldName: string, tabFieldValue: any) => {
    setSearchData((prev) => {
      const updatedState: any = { ...prev };
      updatedState[tabFieldName] = tabFieldValue;
      updatedState["currentPage"] = 1;

      navItems.forEach((item) => {
        if (item.fieldName !== tabFieldName) {
          updatedState[item.fieldName] = null;
        }
      });

      return updatedState;
    });
    setActiveTab({ tabFieldName, tabFieldValue });
  };

  // *******Manage job applications page end**************************************************************************************************************************

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Job application",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("Applications")} />
        <div className="search-bar">
          <div className="row">
            <div className="col-md-12 company-tabs">
              <NavBar
                navItems={navItems}
                onNavItemClick={handleNavItemClick}
                className="custom-navbar"
                activeItem={activeTab}
              />
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <div className="row">
            <div className="col-12">
              <div
                className={`table-responsive tableSection`}
                style={{ paddingTop: "0.5vw" }}
              >
                <table className="table manageApplications border-0 mb-0">
                  <thead>
                    <tr className="tableSearchFields">
                      <SearchFields
                        searchData={searchData}
                        filters={filters}
                        handleSearchChange={handleSearchChange}
                        handleSelectChange={handleSelectChange}
                        handleDateChange={handleDateChange}
                      />
                      <th className="border-0">
                        <div className="d-flex align-items-center w-100">
                          <ResetBtn handleResetClick={handleReset} />
                          {/* <AdvancedSearchBtn
                        handleSearch={handleSearchClick}
                        clearAdvSearchFilter={clearAdvSearchFilter}
                        filterData={advSearchData}
                      /> */}
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div className="tableContainer manageApplications scrollBarDesign">
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader">
                        <th onClick={() => handleOrderByColumn("office")}>
                          {t("Office")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "office"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th
                          onClick={() => handleOrderByColumn("candidateName")}
                        >
                          {t("Candidate")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "candidateName"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th onClick={() => handleOrderByColumn("email")}>
                          {t("Email")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "email"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th className="align-middle">{t("Phone number")}</th>
                        <th onClick={() => handleOrderByColumn("vacancyName")}>
                          {t("Job offer")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "vacancyName"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th className="align-middle">{t("Company name")}</th>
                        <th className="align-middle">
                          {t("Date of application")}
                        </th>
                        <th className="align-middle">{t("Channel")}</th>
                        <th className="align-middle">{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <SpinnerWrapper headerLength={10} />
                      ) : (
                        <>
                          {jobApplications && jobApplications.length > 0 ? (
                            jobApplications.map((jobApplication, index) => {
                              return (
                                <tr
                                  key={jobApplication.applicationId}
                                  className="border-bottom mb-3 box-shadow align-middle"
                                >
                                  <>
                                    <td
                                      data-label={t("office")}
                                      className="align-middle text-break"
                                    >
                                      {jobApplication.office !== " - "
                                        ? jobApplication.office
                                        : ""}
                                    </td>
                                    <td
                                      data-label={t("candidate")}
                                      className="align-middle text-break"
                                    >
                                      <TextEllipsis
                                        title={jobApplication.candidate}
                                        label={jobApplication.candidate}
                                        width="11.5vw"
                                      />
                                    </td>
                                    <td
                                      className="text-break"
                                      data-label={t("Email")}
                                    >
                                      <a
                                        href={`mailto:${jobApplication.email}`}
                                        target="_blank"
                                      >
                                        <TextEllipsis
                                          title={jobApplication.email}
                                          label={jobApplication.email}
                                          width="13vw"
                                        />
                                      </a>
                                    </td>
                                    <td
                                      className="text-break"
                                      data-label={t("Phone")}
                                    >
                                      <a
                                        href={
                                          jobApplication.phone !== null &&
                                          jobApplication.phone !== "" &&
                                          jobApplication.phone !== undefined
                                            ? `${PHONE_CALL_URL}${jobApplication.phone.replace(
                                                "+",
                                                "%2B"
                                              )}`
                                            : ""
                                        }
                                        target="_blank"
                                      >
                                        {jobApplication.phone}
                                      </a>
                                    </td>
                                    <td
                                      data-label={t("jobOffer")}
                                      className="align-middle text-break"
                                    >
                                      <TextEllipsis
                                        title={jobApplication.vacancyName}
                                        label={jobApplication.vacancyName}
                                        width="8vw"
                                      />
                                    </td>
                                    <td
                                      data-label={t("jobOffer")}
                                      className="align-middle text-break"
                                    >
                                      <TextEllipsis
                                        title={jobApplication.companyName}
                                        label={jobApplication.companyName}
                                        width="11.5vw"
                                      />
                                    </td>
                                    <td
                                      data-label={t("date")}
                                      className="align-middle text-break"
                                    >
                                      {jobApplication.date}
                                    </td>
                                    <td
                                      data-label={t("channel")}
                                      className="align-middle text-break"
                                    >
                                      {jobApplication.channel}
                                    </td>
                                  </>
                                  <td className="table-action-icons px-2 align-middle">
                                    <Link
                                      to={`/view/job-application/${jobApplication.applicationId}`}
                                      className="marginRightPoint5"
                                    >
                                      <span
                                        data-bs-toggle="tooltip"
                                        title={t("Proceed")}
                                      >
                                        <RightArrow
                                          width={"2vw"}
                                          height={"2vw"}
                                        />
                                      </span>
                                    </Link>
                                    <div className="d-inline-block">
                                      {jobApplication.absJobsCv && (
                                        <Link
                                          to={jobApplication.absJobsCv}
                                          title={t("View resume")}
                                          target="_blank"
                                        >
                                          <ResumeIcon />
                                        </Link>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <NoRecords headerLength={10} />
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                {!loading && (
                  <div className="pagination d-block">
                    <PaginationNew
                      currentPage={searchData.currentPage}
                      totalPages={paginationData.totalPages}
                      totalRecords={paginationData.totalRecords}
                      recordsPerPage={searchData.recordsPerPage}
                      onItemsPerPageChange={handleItemsPerPageChange}
                      onPageChange={(newPage: any) => handlePageChange(newPage)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row backPadding">
            <div className="col-md-6 align-self-center">
              <Link
                to={"/dashboard"}
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
          </div>
          <AdvancedSearch
            show={searchModal}
            onHide={handleCloseSearchModal}
            onSearchConfirm={handleSearchConfirm}
            advSearchData={advSearchData}
            setAdvSearchData={setAdvSearchData}
            fieldConfigs={filters}
            clearAdvSearchFilter={clearAdvSearchFilter}
          />
        </div>
      </AccessControl>
    </>
  );
};
export default ManageJobApplications;
