import React, { useState, useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../../static/images/EditIcon";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import ModalPopup from "components/common/atoms/ModalPopup";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { ARCHIVE_COMPANY, MANAGE_COMPANY } from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import ArchiveNew from "static/images/ArchiveNew";
import LinkTo from "components/common/atoms/LinkTo";
import * as CONST from "../../../../Constants";
import HeartIcon from "static/images/HeartIcon";
import HeartFillICon from "static/images/HeartFillICon";
import LinkIcon from "static/images/LinkIcon";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import EnvelopIcon from "static/images/EnvelopIcon";
import PhoneCallIcon from "static/images/PhoneCall";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { Option } from "components/common/utlis/TypeAnnotations";
import GetCrudPermissions from "components/common/services/RolesAndPermissions/GetCrudPermissions";
import FollowUpIcon from "static/images/FollowUpIcon";
import RightSideBar, {
  FollowUpEntity,
} from "components/common/atoms/RightSideBar";
import {
  createQueryParams,
  filterAndMerge,
  formatDateAndTime,
  getQueryParamsFromRedux,
  stopRecording,
} from "components/common/utlis/HelperFunctions";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import SearchFields from "components/common/atoms/SearchFields";
import { createFilterConfig } from "./Filters";
import ResetBtn from "components/common/atoms/ResetBtn";
import AdvancedSearchBtn from "components/common/atoms/AdvancedSearchBtn";
import AdvancedSearch from "components/common/molecules/AdvancedSearch";
import NavBar from "components/common/atoms/NavBar";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import GetQueryParams from "components/common/services/GetQueryParams";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";
import StartIcon from "static/images/StartIcon";
import StopIcon from "static/images/StopIcon";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";
import NoRecords from "components/common/atoms/NoRecords";
import UnArchive from "static/images/UnArchive";

export interface CompanyData {
  id?: number;
  name: string;
  cs: any;
  agreementId: number;
  agreement_status: boolean;
  unit_count: number;
  hotlist_id: number | null;
  hotlisted: boolean | null;
  location: string | null;
  phone_number: string | null;
  email: string | null;
  office: any;
  city: Option | null;
  company: string;
  city_name: string;
  officeId: string | null;
  vat: number | null;
  currentPage: number;
  recordsPerPage: number;
  userId: number | null;
  recordingStatus: number | null;
  brightId: number | null;
  created_by: string;
  updated_by: string;
  updated_at: Date | null;
  created_at: Date | null;
  units: number[];
}

interface SearchProps {
  office: number[];
  company: string;
  vat: number | null;
  location: string;
  city: Option | null;
  email: string;
  phone: number | null;
  companyType: number | null;
  hotlist: number | null;
  CompStatus: Option | null;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc"; // Ensure correct typing here
  currentPage: number;
  recordsPerPage: number;
  archiveStatus: number | null;
}

export const ManageCompanies: React.FC = () => {
  const authUser = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const canCompany = GetCrudPermissions("Company");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [entity, setEntity] = useState<FollowUpEntity>({
    entityId: null,
    isInBright: false,
  });
  const [sidebarWidth, setSidebarWidth] = useState(""); // State to store sidebar width
  const [companies, setCompanies] = useState<CompanyData[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 0,
  });
  const [dropdownList, setDropdownList] = useState({
    offices: [],
    cities: [],
    compStatus: [],
    pc: [],
    functions: []
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );

  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    office: authUser?.role === CONST.RECRUITER || authUser?.role === CONST.OFFICE_MANAGER ? [authUser?.officeId, authUser?.correspondingOffice?.id] : [],
    company: "",
    vat: null,
    location: "",
    city: null,
    email: "",
    phone: null,
    CompStatus: null,
    companyType: null,
    hotlist: null,
    orderByColumn: "",
    orderIn: "",
    currentPage: 1,
    recordsPerPage: 10,
    archiveStatus: null
  };

  //advanced search
  const defaultAdvSearchData = {
    street: "",
    pc: null,
    function: null
  };

  //active tab status
  const defaultTabData = {
    tabFieldName: "companyType",
    tabFieldValue: null,
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const filteredAdvSearchData = filterAndMerge(
    defaultAdvSearchData,
    queryParams
  );
  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  const [advSearchData, setAdvSearchData] = useState({
    ...defaultAdvSearchData,
    ...filteredAdvSearchData,
  });

  const [activeTab, setActiveTab] = useState({
    ...defaultTabData,
    ...filteredTabData,
  });

  const navItems = [
    { label: "All", fieldName: "companyType", value: null },
    { label: "Customer", fieldName: "companyType", value: 0 },
    { label: "Prospect", fieldName: "companyType", value: 1 },
    { label: "Hotlist", fieldName: "hotlist", value: 1 },
    { label: "Archived", fieldName: "archiveStatus", value: 2 },
  ];

  useEffect(() => {
    const filterData = { ...searchData, ...advSearchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };
    const response = await ApiCall.service(
      MANAGE_COMPANY,
      "POST",
      data,
      false,
      "company-creation"
    );
    if (response.status === 200) {
      const { companiesData, dropdownData, paginationData } = response.data;
      setCompanies(companiesData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  //** Archive functions */
  const [modalDetails, setModalDetails] = useState({
    title: "" as string,
    body: "" as string,
    status: 0 as number,
  })

  const ArchiveCompany = (id: number | undefined, status: number) => {
    setDeleteId(id);
    if (status === 1) {
      setModalDetails((prev) => ({
        ...prev,
        status: status,
        title: t("Unarchive company"),
        body: t("Are you sure want to unarchive") + "?"
      }));

    } else {
      setModalDetails((prev) => ({
        ...prev,
        status: status,
        title: t("Archive confirmation"),
        body: t("Are you sure want to archive") + "?"
      }));

    }
    setShowModal(true);
  };

  const handleCloseArchiveModal = () => setShowModal(!showModal);

  const handleArchive = async () => {
    setShowModal(false);
    if (deleteID) {
      const data = {
        status: modalDetails.status,
        id: deleteID
      };
      const response = await ApiCall.service(
        `${ARCHIVE_COMPANY}/${deleteID}`,
        "POST",
        data,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        setCompanies((prevdata) =>
          prevdata.filter((company) => company.id !== deleteID)
        );
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  //** follow ups */
  const handleIconClick = (
    event: any,
    id: string | number | null,
    officeId: string | number | null,
    brightId: string | number | null
  ) => {
    event.preventDefault(); // Prevent the default link action
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        officeId: null,
        isInBright: false,
      });
      setIsSidebarOpen(false);
    } else {
      setEntity({
        entityId: id ?? null,
        officeId: officeId ?? null,
        isInBright: brightId !== null ? true : false,
      });
      setIsSidebarOpen(true);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setEntity({
      entityId: null,
      officeId: null,
      isInBright: false,
    });
  };

  //** hotlist /*
  const handleHotlist = (
    companyId: number | undefined,
    hotlisted: boolean | null
  ) => {
    const status = hotlisted === null || hotlisted === false ? true : false;

    const updatedCompanies = companies.map((company) => {
      if (company.id === companyId && companyId !== undefined) {
        return { ...company, hotlisted: status };
      }
      return company;
    });
    setCompanies(updatedCompanies);
  };

  const submitHotlist = async () => {
    setLoading(true);
    const data = {
      type: "company",
      data: companies,
    };
    const response = await ApiCall.service(
      "hotlist",
      "POST",
      data,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    } else if (response.status === 400) {
      CustomNotify({ type: "error", message: t(response.msg) });
    } else {
      CustomNotify({ type: "error", message: t("Something went wrong") });
    }
    setLoading(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  //** search filters */
  const handleCloseSearchModal = () => {
    setSearchModal(false);
  };

  const handleSearchClick = () => {
    setSearchModal(true);
  };

  const handleSearchConfirm = () => {
    const filterData = { ...searchData, ...advSearchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    fetchDetails(filterData); // Pass the new search data directly
  };

  const clearAdvSearchFilter = () => {
    setAdvSearchData(defaultAdvSearchData);
    const filterData = { ...searchData, ...defaultAdvSearchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    fetchDetails(filterData);
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData({ ...defaultSearchData, office: [] });
    setAdvSearchData(defaultAdvSearchData);
    setActiveTab(defaultTabData);
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  const handleNavItemClick = (tabFieldName: string, tabFieldValue: any) => {
    setSearchData((prev) => {
      const updatedState: any = { ...prev };
      updatedState[tabFieldName] = tabFieldValue;
      updatedState["currentPage"] = 1;

      navItems.forEach((item) => {
        if (item.fieldName !== tabFieldName) {
          updatedState[item.fieldName] = null;
        }
      });

      return updatedState;
    });
    setActiveTab({ tabFieldName, tabFieldValue });
  };

  // Sales note
  const [salesnote, setSalesNote] = useState({
    name: "",
    phone: "",
    id: null,
    navigate: "",
    companyId: null,
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null,
    prevId: null as number | null | undefined,
    isInBright: false as boolean
  });

  const handleMeeting = async (
    name: string,
    phone: string | null,
    id: any,
    companyId: any,
    brightId: number | null,
    navigate?: string
  ) => {
    setSalesNote((prev) => ({
      ...prev,
      name: name,
      phone: phone !== null ? phone : "",
      id: id,
      companyId: companyId,
      navigate: navigate ?? "",
      activeRecordingId: companyId,
      triggerMeeting: true,
      type: 2, //For company type is 2,
      prevId: null,
      isInBright: brightId ? true : false
    }));
  };

  const stoprecording = async () => {
    await stopRecording(authUser.userId, setSalesNote);
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div
        className={`main-container ${isSidebarOpen ? "sidebar-open" : ""
          } rightSidebar`}
        style={{ marginRight: isSidebarOpen ? sidebarWidth : undefined }}
      >
        <Title title={t("Companies")} />
        <div className="form-height-dashboard">
          <div className="search-bar">
            {authUser.role !== CONST.EMPLOYER && (
              <div className="row">
                <div className="col-md-12 company-tabs">
                  <NavBar
                    navItems={navItems}
                    onNavItemClick={handleNavItemClick}
                    className="custom-navbar"
                    activeItem={activeTab}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Company",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/company"
                    title={t("Create company")}
                    icon={faPlus}
                    className="me-3"
                  />
                </AccessControl>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Company",
                      read: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/manage-locations"
                    title={t("Manage location")}
                    className="me-3"
                    type="manage"
                  />
                </AccessControl>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Cooperation agreeement",
                      read: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/manage/cooperation-agreement"
                    title={t("Manage cooperation agreement")}
                    className="me-3"
                    type="manage"
                  />
                </AccessControl>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Company",
                      update: true,
                    },
                  ]}
                >
                  {companies.length > 0 && (
                    <Button
                      title={t("Update hotlist")}
                      className="form-button"
                      handleClick={submitHotlist}
                    />
                  )}
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive tableSection">
              <table className="table manageCompanies border-0 mb-0">
                <thead>
                  <tr className="tableSearchFields">
                    <SearchFields
                      searchData={searchData}
                      filters={filters}
                      handleSearchChange={handleSearchChange}
                      handleSelectChange={handleSelectChange}
                    />
                    <th className="border-0">
                      <div className="d-flex align-items-center w-100">
                        <ResetBtn handleResetClick={handleReset} />
                        <AdvancedSearchBtn
                          handleSearch={handleSearchClick}
                          clearAdvSearchFilter={clearAdvSearchFilter}
                          filterData={advSearchData}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="tableContainer scrollBarDesign">
                <table className="table table-hover manageCompanies">
                  <thead>
                    <tr className="TableHeader">
                      <th onClick={() => handleOrderByColumn("office")}>
                        {t("Office")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "office"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("company")}>
                        {t("Company")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "company"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th>{t("VAT")}</th>
                      <th onClick={() => handleOrderByColumn("location")}>
                        {t("Location")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "location"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th>{t("City")}</th>
                      <th onClick={() => handleOrderByColumn("email")}>
                        {t("Email")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "email"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th>{t("Phone number")}</th>
                      <th>{t("Status")}</th>
                      <th>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Company",
                              update: true,
                              delete: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                        >
                          {t("Action")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <SpinnerWrapper headerLength={9} />
                    ) : (
                      <>
                        {isSidebarOpen && (
                          <RightSideBar
                            title={"Follow-ups"}
                            entityType={"company"}
                            officeId={entity.officeId}
                            entityId={entity.entityId}
                            isInBright={entity.isInBright}
                            onClose={handleCloseSidebar}
                            getWidth={getWidth}
                            viewIconClick={handleIconClick}
                            recordsLimit={10}
                            className="right-sidebar p-3"
                            titleClassName="mt-4"
                            destination="/manage-companies"
                          />
                        )}
                        {companies && companies.length > 0 ? (
                          companies.map((company, index) => {
                            let color_cs =
                              company.cs === 0
                                ? "bg-warning"
                                : company.cs === 1
                                  ? "bg-success"
                                  : "bg-danger";
                            let totalCount = 6;

                            // Check if `company.units` exists and is a non-empty string
                            if (
                              company.units &&
                              typeof company.units === "string"
                            ) {
                              // Cast `company.units` to a string and parse it into an array of numbers
                              const unitValues = (company.units as string)
                                .replace(/[{}]/g, "")
                                .split(",")
                                .map(Number);

                              // Check if `unitValues` includes certain values
                              if (unitValues.includes(7)) {
                                totalCount = 5;
                              } else if (
                                unitValues.includes(1) ||
                                unitValues.includes(2)
                              ) {
                                totalCount = 6;
                              }
                            }
                            // Now, calculate if units left in the agreement do not match the total count
                            let unitsLeftCoopAgrmnt =
                              company.unit_count !== totalCount;

                            return (
                              <tr
                                key={company.id}
                                className="border-bottom align-middle"
                              >
                                <td>
                                  {company.office === " - "
                                    ? ""
                                    : company.office}
                                </td>
                                <td className="text-break" data-label="Name">
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Company",
                                        update: true,
                                        read: true,
                                      },
                                    ]}
                                    strict={false}
                                  >
                                    {canCompany?.read && canCompany?.update ? (
                                      <a href={`/company/corner/${company.id}`}>
                                        <TextEllipsis
                                          label={company.name}
                                          title={company.name}
                                          width="10.5vw"
                                        />
                                      </a>
                                    ) : (
                                      company.name
                                    )}
                                  </AccessControl>
                                </td>
                                <td>{company.vat}</td>
                                <td>{company.location}</td>
                                <td>{company.city_name}</td>
                                <td>
                                  <a
                                    href={`mailto:${company.email}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {company.email}
                                  </a>
                                </td>
                                <td>
                                  <a
                                    onClick={() =>
                                      handleMeeting(
                                        company.name,
                                        company.phone_number,
                                        company.userId,
                                        company.id,
                                        company.brightId,
                                        CONST.CALL_3CX
                                      )
                                    }
                                    className="cursor-pointer"
                                  >
                                    {company.phone_number &&
                                      company.phone_number !== ""
                                      ? `+${company.phone_number}`
                                      : ""}
                                  </a>
                                </td>
                                <td className="ps-3">
                                  <span
                                    className={`d-inline-block rounded-circle status-icon ${color_cs}`}
                                    data-toggle="tooltip"
                                    title={
                                      company.cs === 1
                                        ? t("Registered company")
                                        : (company.cs === 2 ? t("Archived company") : t("Drafted company"))
                                    }
                                  ></span>
                                </td>
                                <td className="table-action-icons">
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Company",
                                        delete: true,
                                        update: true,
                                      },
                                    ]}
                                    actions={true}
                                    strict={false}
                                  >
                                    <ActionIconWrapper initialVisibleCount={4}>
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Follow-up",
                                            read: true,
                                          },
                                        ]}
                                      >
                                        <div
                                          title={t("View follow ups")}
                                          onClick={(e) => {
                                            handleIconClick(
                                              e,
                                              company.id ?? null,
                                              company.officeId ?? null,
                                              company.brightId ?? null
                                            );
                                          }}
                                          className="d-inline-block cursor-pointer"
                                        >
                                          <FollowUpIcon />
                                        </div>
                                      </AccessControl>
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission:
                                              "Cooperation agreeement",
                                            create: true,
                                          },
                                        ]}
                                      >
                                        {unitsLeftCoopAgrmnt &&
                                          authUser.role !== CONST.RECRUITER &&
                                          company.cs !== 0 && (
                                            <Link
                                              to={`/cooperation-agreement/create/${company.id}`}
                                              title={t(
                                                "Create cooperation agreement"
                                              )}
                                            >
                                              <LinkIcon />
                                            </Link>
                                          )}
                                      </AccessControl>
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Company",
                                            update: true,
                                          },
                                        ]}
                                      >
                                        <div
                                          onClick={(e) =>
                                            handleHotlist(
                                              company.id,
                                              company.hotlisted
                                            )
                                          }
                                          title={
                                            company.hotlisted != null &&
                                              company.hotlisted
                                              ? t("Remove from hotlist")
                                              : t("Add to hotlist")
                                          }
                                          className="d-inline-block cursor-pointer"
                                        >
                                          {company.hotlisted != null &&
                                            company.hotlisted ? (
                                            <HeartFillICon />
                                          ) : (
                                            <HeartIcon />
                                          )}
                                        </div>
                                      </AccessControl>
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Company",
                                            update: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to={`/company/${company.id}`}
                                          title={t("Edit")}
                                        >
                                          <EditIcon />
                                        </Link>
                                      </AccessControl>

                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Sales note",
                                            read: true,
                                          },
                                        ]}
                                      >
                                        {salesnote.activeRecordingId ===
                                          company.id ? (
                                          <>
                                            <span
                                              className="cursor-pointer"
                                              onClick={() => stoprecording()}
                                              title={t("Stop")}
                                            >
                                              <StopIcon />
                                            </span>
                                          </>
                                        ) : (
                                          <span
                                            className="me-2 cursor-pointer"
                                            onClick={() =>
                                              handleMeeting(
                                                company.name,
                                                company.phone_number,
                                                company.userId,
                                                company.id,
                                                company.brightId
                                              )
                                            }
                                            title={t("Start")}
                                          >
                                            <StartIcon />
                                          </span>
                                        )}
                                      </AccessControl>
                                      {company.cs !== 2 &&
                                        <AccessControl
                                          requiredPermissions={[
                                            {
                                              permission: "Company",
                                              delete: true,
                                            },
                                          ]}
                                        >
                                          <div
                                            title={t("Archive")}
                                            onClick={() =>
                                              ArchiveCompany(company.id, 2)
                                            }
                                            className="d-inline-block cursor-pointer"
                                          >
                                            <ArchiveNew />
                                          </div>
                                        </AccessControl>
                                      }
                                      {company.cs === 2 &&
                                        <AccessControl
                                          requiredPermissions={[
                                            {
                                              permission: "Company",
                                              delete: true,
                                            },
                                          ]}
                                        >
                                          <div
                                            title={t("Unarchive")}
                                            onClick={() =>
                                              ArchiveCompany(company.id, 1)
                                            }
                                            className="d-inline-block cursor-pointer"
                                          >
                                            <UnArchive />
                                          </div>
                                        </AccessControl>
                                      }
                                      {company.email && (
                                        <Link
                                          to={`mailto:${company.email}`}
                                          title={"Email"}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <EnvelopIcon />
                                        </Link>
                                      )}
                                      {company.phone_number && (
                                        <Link
                                          onClick={() =>
                                            handleMeeting(
                                              company.name,
                                              company.phone_number,
                                              company.userId,
                                              company.id,
                                              company.brightId,
                                              CONST.CALL_3CX
                                            )
                                          }
                                          title={t("Call")}
                                          to={""}
                                        >
                                          <PhoneCallIcon />
                                        </Link>
                                      )}
                                      <div>
                                        <UserInfoTooltip
                                          createdAt={formatDateAndTime(
                                            company.created_at
                                          )}
                                          createdBy={company.created_by}
                                          updatedBy={company.updated_by}
                                          updatedAt={formatDateAndTime(
                                            company.updated_at
                                          )}
                                        />
                                      </div>
                                    </ActionIconWrapper>
                                  </AccessControl>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <NoRecords headerLength={10} />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {!loading && (
                <div className="pagination d-block">
                  <PaginationNew
                    currentPage={searchData.currentPage}
                    totalPages={paginationData.totalPages}
                    totalRecords={paginationData.totalRecords}
                    recordsPerPage={searchData.recordsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={(newPage: any) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link to="/dashboard" className=" back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleCloseArchiveModal}
          title={modalDetails.title}
          body={modalDetails.body}
          onCloseButtonClick={handleCloseArchiveModal}
          onConfirmButtonClick={handleArchive}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
        <AdvancedSearch
          show={searchModal}
          onHide={handleCloseSearchModal}
          onSearchConfirm={handleSearchConfirm}
          advSearchData={advSearchData}
          setAdvSearchData={setAdvSearchData}
          fieldConfigs={filters}
          clearAdvSearchFilter={clearAdvSearchFilter}
        />
        {/* Sales note */}
        <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
      </div>
    </AccessControl>
  );
};
export default translate(ManageCompanies);
