import React, { useState } from "react";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import "../CompanyCorner/CompanyCorner.css";
import { Link } from "react-router-dom";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";
import { CompanyCornerProps } from "./CompanyCorner";
import User from "static/images/User";
import ActiveLocation from "static/images/ActiveLocation";
import EditIcon from "static/images/EditIcon";

export interface MatchingVacancyProfileCardProps {
  profileCardProps: CompanyCornerProps;
}

const CompanyCornerProfileCard: React.FC<MatchingVacancyProfileCardProps> = (
  props
) => {
  const functionList =
    props.profileCardProps.functions === undefined
      ? []
      : props.profileCardProps.functions;

  let address = [];

  if (props.profileCardProps.street) {
    address.push(props.profileCardProps.street);
  }
  if (props.profileCardProps.number) {
    address.push(props.profileCardProps.number);
  }
  if (props.profileCardProps.zipCode) {
    address.push(props.profileCardProps.zipCode);
  }

  if (props.profileCardProps.city) {
    address.push(props.profileCardProps.city);
  }

  if (props.profileCardProps.country) {
    address.push(props.profileCardProps.country);
  }

  let fullAddress = address.join(", ");

  // Sales note
  const [salesnote, setSalesNote] = useState({
    name: '',
    phone: '' as string | undefined,
    id: null,
    navigate: '',
    companyId: null,
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null
  });

  const handleMeeting = async (name: string, id: any | null, companyId: any, contactId?: number | null, phone?: string | null, navigate?: string) => {
    setSalesNote({
      name: name,
      phone: phone !== null ? phone : '',
      id: id,
      companyId: companyId,
      navigate: navigate ?? '',
      activeRecordingId: contactId ?? companyId,
      triggerMeeting: true,
      type: contactId ? 3 : 2 // For company contacts type is 3, candidates  is 1, companies is 2
    });
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className={`px-2`}>
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div>
              <div
                className="card position-relative border-0"
                style={{
                  backgroundColor: "var(--profileBgColor)",
                  padding: "2vw",
                }}
              >
                <div className="row">
                  <div className="col-md-12 ms-auto position-relative">
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Company",
                          update: true,
                        },
                      ]}
                    >
                      <div className="float-end table-action-icons px-2">
                        <Link
                          to={`/company/${props.profileCardProps.companyId}`}
                          className="btn p-0 border-0 me-2"
                          title={t("Edit")}
                        >
                          <EditIcon />
                        </Link>
                      </div>
                    </AccessControl>
                    {props.profileCardProps.companyName && (
                      <div className={`color-dark-pink name-text text-start`} style={{ marginBottom: "0.5vw" }}>
                        {props.profileCardProps.companyName}
                      </div>
                    )}
                    <div className="job-title text-start" style={{ marginBottom: "1vw" }}>
                      <span>{t("Looking for ")}</span>
                      <span>
                        {functionList.length > 0 && (
                          <strong> {functionList.join(", ")}</strong>
                        )}
                      </span>
                    </div>
                    <div className="candidate-person-details">
                      {props.profileCardProps?.vat && (
                        <div className="d-flex align-items-center" style={{ marginBottom: "1vw" }}>
                          <strong className="color-dark-pink" style={{ width: "5%" }}>
                            {"VAT"}
                          </strong>
                          <span>
                            {props.profileCardProps.vat}
                          </span>
                        </div>
                      )}
                      {props.profileCardProps.office && (
                        <div className="d-flex align-items-center" style={{ marginBottom: "1vw" }}>
                          <span style={{ width: "5%" }}>
                            <img
                              src="../../static/images/Company.svg"
                              alt={"Company Address"}
                              className="profileCardImage"
                            />
                          </span>
                          <span>
                            {props.profileCardProps.office}
                          </span>
                        </div>
                      )}
                      {props.profileCardProps.consultant && (
                        <div className="d-flex align-items-center" style={{ marginBottom: "1vw" }}>
                          <span title={t("Consultant")} style={{ width: "5%" }}>
                            <User />
                          </span>
                          <span>
                            {props.profileCardProps.consultant}
                          </span>
                        </div>
                      )}

                      {props.profileCardProps.website && (
                        <div className="d-flex align-items-center" style={{ marginBottom: "1vw" }}>
                          <span style={{ width: "5%" }}>
                            <img
                              src="../../static/images/Website.svg"
                              alt={t("Website")}
                              className="profileCardImage"
                            />
                          </span>
                          <span>
                            <Link
                              to={`https://${props.profileCardProps.website}`}
                              className="color-dark-pink"
                              target="_blank"
                            >
                              {props.profileCardProps.website}
                            </Link>
                          </span>
                        </div>
                      )}
                      <div className="d-flex align-items-start">
                        <span style={{ width: "5%" }} className="color-dark-pink">
                          <ActiveLocation />
                        </span>
                        <span>
                          <div className="mb-2 text-start">{fullAddress}</div>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Sales note */}
      <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
    </AccessControl>
  );
};

export default translate(CompanyCornerProfileCard);
