import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { useEffect, useState } from "react";
import { Option } from "components/common/services/ValidationService";
import { timePeriods } from "./ManageReports";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import {
  AREA_MANAGER,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  OFFICE_MANAGER,
} from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "routes/ApiEndpoints";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "../translation/Translation";
import Calender from "components/common/molecules/Calender";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import Pagination from "components/common/atoms/Pagination";
import Button from "components/common/atoms/Button";
import Reset from "static/images/Reset";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import secureLocalStorage from "react-secure-storage";
import NoRecords from "components/common/atoms/NoRecords";

interface SearchData {
  type: string;
  initialFetch?: boolean;
  office: OptionProps[];
  managers?: OptionProps[];
  recruiters?: Option | null;
  timePeriod?: Option | null;
  from?: Date | null;
  to?: Date | null;
}

interface SearchOptions {
  offices: OptionProps[];
  managers: OptionProps[];
  recruiters: OptionProps[];
}

interface ReportRecord {
  office: string;
  officeId: number;
  userId: number | string;
  name: string;
  number: string;
  startDate: string;
  endDate: string;
  year: string;
}

const ManageOmReports: React.FC = () => {
  const authUser = useSelector(selectAuth);
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    offices: [],
    managers: [],
    recruiters: [],
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchData, setSearchData] = useState<SearchData>({
    type: "officeManager",
    initialFetch: true,
    office: [],
    timePeriod: timePeriods[0],
    from: null,
    to: null,
  });
  const [reports, setReports] = useState<ReportRecord[]>([]);
  const [timePeriod, setTimePeriod] = useState<Option | null>(null);

  useEffect(() => {
    fetchData(searchData, 1, true);
    setSearchData((prevData) => ({ ...prevData, initialFetch: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.role]);

  const fetchData = async (searchFilters = {}, page = 1, initial = false) => {
    const data = {
      page: page,
      nOR: 10,
      ...searchFilters,
    };

    const response = await ApiCall.service(
      ENDPOINTS.FETCH_REPORTS,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setReports(response?.data?.reports?.records);
      setTotalPages(response?.data?.reports?.totalPages);
      setTimePeriod(searchData?.timePeriod ?? null);

      setSearchOptions((prevData) => ({
        ...prevData,
        recruiters: response?.data?.managers ?? [],
        managers: response?.data?.managers ?? [],
        offices: response?.data?.offices ?? [],
      }));
    } else {
    }
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      [name]: date,
    }));
  };

  const timePeriodLinks: { [key: number]: string } = {
    1: "/om/weekly/report",
    2: "/om/monthly/report",
  };
  const link =
    typeof timePeriod?.value === "number"
      ? timePeriodLinks[timePeriod.value] || null
      : null;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchData(searchData, pageNumber);
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      [fieldName]: selectedOption ?? null,
    }));
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    fetchData(searchData, 1);
    setCurrentPage(1);
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      type: "officeManager",
      initialFetch: true,
      office: [],
      timePeriod: timePeriods[0],
      from: null,
      to: null,
    });
    setCurrentPage(1);
    fetchData();
  };

  const setUserObj = (user: Object) => {
    const data = EncryptDecryptService.encryptData(JSON.stringify(user));
    if (timePeriod?.value === 1) {
      secureLocalStorage.setItem("omWeeklyReport", data);
    } else if (timePeriod?.value === 2) {
      secureLocalStorage.setItem("omMonthlyReport", data);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <SelectWithSearch
            title={t("Office")}
            name="office"
            value={searchData.office}
            search={true}
            onChange={(e) => {
              handleSelectChange(e, "office");
            }}
            options={searchOptions.offices}
            className="col-md-2"
            isTranslate={true}
          />
        </div>
        <div className="col-md-2">
          <SelectWithSearch
            title={t("Office manager")}
            name="managers"
            value={searchData.managers}
            search={true}
            onChange={(e) => {
              handleSelectChange(e, "managers");
            }}
            isMulti={true}
            options={searchOptions.managers}
            isDisabled={authUser.role !== AREA_MANAGER}
            className="col-md-2"
          />
        </div>
        <div className="col-md-2">
          <SelectWithSearch
            title={t("Time period")}
            name="timePeriod"
            value={searchData.timePeriod}
            search={true}
            onChange={(e) => {
              handleSelectChange(e, "timePeriod");
            }}
            options={timePeriods}
            className="col-md-2"
            isSelectOption={false}
          />
        </div>
        <div className="col-md-2 position-relative">
          <Calender
            label={t("From")}
            selectedDate={searchData.from}
            isMandatory={false}
            name="from"
            onChange={handleDateChange}
            showMonthDropdown={false}
            showYearDropdown={false}
            minDate={new Date("01/01/2024")}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-2 position-relative">
          <Calender
            label={t("To")}
            selectedDate={searchData.to}
            onChange={handleDateChange}
            isMandatory={false}
            name="to"
            showMonthDropdown={false}
            showYearDropdown={false}
            minDate={new Date("01/01/2024")}
            maxDate={new Date()}
          />
        </div>
        <div className="col-2 text-end mt-34">
          <Button
            title=""
            type="submit"
            handleClick={handleReset}
            className="btn delete-btn rounded-3 shadow-none  me-3 px-3 reset-btn"
          >
            <span title={t("Reset")}>
              <Reset />
            </span>
          </Button>
          <Button
            title={t("Search")}
            icon={faMagnifyingGlass}
            type="submit"
            handleClick={handleSearch}
            className="form-button shadow-none search-btns position-relative text-start"
          />
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                <th className="ps-lg-4">{t("Office")}</th>
                {authUser.role !== OFFICE_MANAGER && (
                  <th>{t("Office manager")}</th>
                )}
                {timePeriod?.value === 2 ? (
                  <>
                    <th>{t("Month number")}</th>
                    <th>{t("Monthly date")}</th>
                  </>
                ) : (
                  <>
                    <th>{t("Week number")}</th>
                    <th>{t("Weekly date")}</th>
                  </>
                )}
                <th>{t("Year")}</th>
                {/* <AccessControl
                                    requiredPermissions={[{
                                        permission: "Follow-up",
                                        update: true,
                                    }]}
                                    actions={true}
                                > */}
                <th>{t("Action")}</th>
                {/* </AccessControl> */}
              </tr>
            </thead>
            <tbody>
              {reports && reports.length > 0 ? (
                reports.map((item: ReportRecord, index) => {
                  const user = {
                    omId: item.userId,
                    periodNumber: item.number,
                    officeId: item.officeId,
                    year: item.year,
                  };

                  return (
                    <tr key={index}>
                      <td className="ps-lg-4 text-break">{item?.office}</td>
                      {authUser.role !== OFFICE_MANAGER && (
                        <td>{item?.name}</td>
                      )}
                      <td>{item?.number}</td>
                      <td>{`${item?.startDate} to ${item?.endDate}`}</td>
                      <td>{item?.year}</td>
                      {link ? (
                        <td className="table-action-icons">
                          <Link
                            to={link}
                            className="border-0 me-2 d-inline-block"
                            title={t("Edit")}
                            onClick={() => setUserObj(user)}
                          >
                            <EditIcon />
                          </Link>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  );
                })
              ) : (
                <NoRecords headerLength={5} message={t("No reports found")} />
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="pagination justify-content-center align-items-center mt-4 px-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(newPage) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ManageOmReports;
