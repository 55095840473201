import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  width?: string | number; // circle width
  height?: string | number; // circle height
  color?: string;
  customStyle?: React.CSSProperties;
  border?: string | number;
  backgroundColor?: string;
  boxShadow?: string;
  className?: string;
  icon?: IconProp;
  iconWidth?: string | number; // icon width
  iconHeight?: string | number; // icon height
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  top?: string | number;
  bottom?: string | number;
  left?: string | number;
  right?: string | number;
  transform?: string;
  title?: string | number;
  inputClass?: string | number;
  onClick?: () => void;
  children: React.ReactNode;
  wrapperClassName?:string;
  margin?:string;
}

const CardCircle: React.FC<Props> = ({
  width,
  height,
  color,
  border,
  backgroundColor,
  boxShadow,
  customStyle = {},
  className,
  icon,
  iconWidth,
  iconHeight,
  position,
  top,
  bottom,
  left,
  right,
  transform,
  title,
  inputClass,
  onClick,
  children,
  wrapperClassName,
  margin
}) => {
  // Circle style
  const circleStyle: React.CSSProperties = {
    width,
    height,
    border,
    backgroundColor,
    borderRadius: '50%',
    boxShadow,
    ...customStyle,
    color,
    margin
  };
  // Icon style
  const iconStyle: React.CSSProperties = {
    width: iconWidth,
    height: iconHeight,
    boxShadow
  };
  // Position style
  const PositionStyle: React.CSSProperties = {
    top,
    bottom,
    left,
    right,
    position,
    transform
  };


  return (
    <div style={PositionStyle} className={`${wrapperClassName}`}>
      <div style={circleStyle} className={`${className} d-flex align-items-center`} onClick={onClick}>
        {title}
        {icon && <FontAwesomeIcon icon={icon} style={iconStyle} className={`${inputClass}`} />}
        {children}
      </div>
    </div>
  );
};

export default CardCircle;