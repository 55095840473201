import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import FileUpload from "components/common/molecules/FileUpload";
import Button from "components/common/atoms/Button";
import { ReactElement, useEffect, useState } from "react";
import Navigation from "../formNavigation/Navigation";
import {
  scrollToTop,
  validateFileId,
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { FILE_URL, RESGISTER_COMPANY } from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import ModalPopup from "components/common/atoms/ModalPopup";
import { Option } from "components/common/services/ValidationService";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import LabelField from "components/common/atoms/LabelField";
import { DocumentProps } from "../../annotations/CompanyAnnotations";
import { useFormContext } from "../../context/Context";
import CustomNotify from "components/common/atoms/CustomNotify";
import { useNavigate, useParams } from "react-router-dom";
import { MANAGE_COMPANIES } from "routes/RouteConstants";
import GetQueryParams from "components/common/services/GetQueryParams";
import RadioField from "components/common/atoms/RadioField";
import { ApiCall } from "components/common/services/ApiServices";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const Documents = () => {
  const { state, dispatch } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };

  // ************************document code starts ********************************
  const [documentData, setDocumentData] = useState<DocumentProps>({
    id: null,
    documentName: "",
    documentCategory: null,
    documentId: null,
    fileName: "",
    filePath: "",
    extension: "",
    uploadedBy: "",
    createdAt: "",
    editId: null,
  });

  const initialDocumentErrors: { [key: string]: string } = {};
  const [documentErrors, setDocumentErrors] = useState<{
    [key: string]: string;
  }>(initialDocumentErrors);

  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const [documentIndex, setDocumentIndex] = useState(0);

  const documentValidation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      documentName: [validateRequired],
      documentCategory: [validateSelectField],
      documentId: [validateFileId],
    };
    const validationErrors = validateForm(
      { ...documentData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setDocumentErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDocumentErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const addDocumentData = (index: number) => {
    setDocumentIndex(index); // Set the index for dispatch
    setDocumentData((prev) => ({
      ...prev,
      editId: index,
    }));
    setDocumentModalOpen(true);
  };

  const handleDocumentFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    let updatedValue: string | number | null = value;
    setDocumentData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
    documentValidation(name, value, true);
  };

  const handleDocumentSelectChange = (
    selectedOption: any,
    fieldName: string
  ) => {
    setDocumentData((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption,
    }));
    documentValidation(fieldName, selectedOption, true);
  };

  const closeDocumentModal = () => {
    setDocumentModalOpen(false);
    resetDocumentFields();
    resetDocumentErrors();
  };

  const documentUploadResponse = (responseObj: any) => {
    const response = responseObj["response"];
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: response[key],
      }));
    });
    // Extract file name and extension
    const fileNameWithExtension = response[1];
    const lastDotIndex = fileNameWithExtension.lastIndexOf(".");
    const fileName =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(0, lastDotIndex)
        : fileNameWithExtension;
    const extension =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(lastDotIndex + 1)
        : "";
    setDocumentData((prevState) => ({
      ...prevState,
      extension: extension,
      documentName: fileName,
    }));
  };

  const documentDeleteResponse = (field: string) => {
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: fieldName.endsWith("Id") ? null : "",
      }));
      setDocumentData((prevData) => ({
        ...prevData,
        documentName: "",
        extension: "",
      }));
    });
  };

  const documentUploadErrors = (errorResposne: any) => {
    setDocumentErrors({
      documentId: errorResposne.error,
    });
  };
  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
  };

  const onDocumentOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const updatedDocumentData = {
      ...documentData,
      uploadedBy: userAuth.name,
      createdAt: formatDate(new Date()),
    };
    if (documentValidation(name, value)) {
      dispatch({
        type: "ADD_DOCUMENT_FIELD",
        data: updatedDocumentData,
        index: documentIndex,
      });
      setDocumentModalOpen(false); // Close modal
      resetDocumentFields();
      resetDocumentErrors();
    }
  };

  const removeDocumentFieldSet = (indexToRemove: number | null) => {
    if (indexToRemove !== null) {
      dispatch({ type: "REMOVE_DOCUMENT_FIELD", indexToRemove });
    }
  };

  const editDocumentData = (index: number | null) => {
    if (index !== null) {
      setDocumentIndex(index); // Set the index for dispatch
      setDocumentModalOpen(true);
      const documentToEdit = state.documents[index]; // Get the certificate to edit
      if (documentToEdit) {
        // Populate the certificate data in the modal fields
        setDocumentData((prev) => ({
          ...prev,
          id: documentToEdit.id,
          documentName: documentToEdit.documentName,
          documentCategory: documentToEdit.documentCategory,
          documentNo: documentToEdit.documentNo,
          documentId: documentToEdit.documentId,
          fileName: documentToEdit.fileName,
          filePath: documentToEdit.filePath,
          extension: documentToEdit.extension,
          uploadedBy: userAuth.name,
          createdAt: "",
          editId: index,
        }));
      }
    }
  };

  const resetDocumentErrors = () => {
    setDocumentErrors({
      documentName: "",
      documentCategory: "",
      documentId: "",
    });
  };

  const resetDocumentFields = () => {
    setDocumentData({
      id: null,
      documentName: "",
      documentCategory: null,
      documentId: null,
      fileName: "",
      filePath: "",
      validFrom: null,
      validTo: null,
      extension: "",
      uploadedBy: "",
      createdAt: "",
      editId: null,
    });
  };
  // *************************SearchFields form ********************************
  interface SearchFields {
    documentCategory: Option | null;
    extension: Option | null;
    documentName: string;
  }
  const extensionOptions = [
    { value: 1, label: "doc" },
    { value: 2, label: "docx" },
    { value: 3, label: "jpeg" },
    { value: 4, label: "jpg" },
    { value: 5, label: "pdf" },
    { value: 6, label: "png" },
  ];

  const [searchFields, setSearchFields] = useState<SearchFields>({
    documentCategory: null,
    extension: null,
    documentName: "",
  });

  const handleSelectFieldForSearch = (
    selectedOption: any,
    fieldName: string
  ) => {
    setSearchFields((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleInputFieldForSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setSearchFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const countCategory = (categoryId: number | undefined | null) => {
    return state.documents.filter(
      (doc) => doc.documentCategory?.value === categoryId
    ).length;
  };

  // ************************document code ends ********************************

  /** HANDLE PROSPECT OR CUSTOMER  */
  const [showCompanyTypeModal, setCompanyTypeModal] = useState(false);

  const handleCompanyTypeSubmit = () => {
    setCompanyTypeModal(false);
    dispatch({
      type: "UPDATE_BASIC_FIELD",
      field: "isCompanyTypeAdded",
      value: true,
    });
    submitCompanyDetails();
  };

  const handleCompanyTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    dispatch({
      type: "UPDATE_BASIC_FIELD",
      field: "isProspect",
      value: parseInt(value),
    });
  };

  // ** END ****
  const [modalBody, setModalBody] = useState<ReactElement | null>(null);
  const [locationPopup, setLocationPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalTitle, setModalTitle] = useState<string>("");
  const navigate = useNavigate();
  const [agreementId, setAgreementId] = useState<number | null>();
  const queryParams = GetQueryParams();
  const [companyIdForLocation, setCompanyIdForLocation] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const [emailUser, setEmailUser] = useState({});
  const { companyId } = useParams<{ companyId: string }>();
  const userAuth = useSelector(selectAuth);

  const updateFields = (index: number) => {
    setShowModal(false);
    const updatedUiObj = { ...state.contacts[index], ...emailUser };
    dispatch({
      type: "ADD_UPDATE_DYNAMIC",
      data: updatedUiObj,
    });
  };

  const navigateToLocationPage = (companyId: string | undefined) => {
    return navigate(
      `/location?company_id=${companyId}&agreement_id=${agreementId}`
    );
  };

  const navigateToManagePage = () => {
    return navigate(queryParams.get("destination") ?? MANAGE_COMPANIES);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validationStatus.isValid) {
      if (state.basic.isCompanyTypeAdded) {
        submitCompanyDetails();
      } else {
        setCompanyTypeModal(true);
      }
    } else {
      // msg && dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 2, error: true });
      scrollToTop();

      if (windowWidth <= 1199) {
        setModalTitle("");
        setModalBody(
          <div className="d-block d-xl-none mb-5 px-4 text-danger text-center">
            <div className="mb-3">
              {`Please fill all the following mandatory fields under `}
            </div>
            {state.tabDetails.map(
              (item: any, index) =>
                item.error && (
                  <div className="mb-2" key={index}>
                    <strong>{item.title}</strong>
                  </div>
                )
            )}
          </div>
        );
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  };

  const submitCompanyDetails = async () => {
    setLoading(true);
    const formData = {
      basic: state.basic,
      address: state.address,
      contacts: state.contacts,
      documents: state.documents,
      userId: userAuth.userId,
      submitType: "submit",
    };
    const url = !companyId
      ? RESGISTER_COMPANY
      : `${RESGISTER_COMPANY}/${companyId}`;
    const response = await ApiCall.service(
      url,
      "POST",
      formData,
      false,
      "company-creation"
    );
    if (response.status === 200) {
      setCompanyIdForLocation(response.companyId);
      state.basic.companyStatus === 1
        ? setLocationPopup(false)
        : setLocationPopup(true);
      const msg =
        state.basic.companyStatus === 1
          ? `${t("Company updated successfully")}!`
          : `${t("Company created successfully")}!`;
      CustomNotify({
        type: "success",
        message: t(msg),
      });
      setAgreementId(response.agreementId);
      state.basic.companyStatus === 1 &&
        navigate(queryParams.get("destination") ?? MANAGE_COMPANIES);
    } else if (response.status === 400) {
      CustomNotify({ type: "error", message: t(response.msg.vat_number) });
      dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      scrollToTop();
      state.fieldError.vat = response.msg.vat_number;
    } else if (response.status === 500) {
      if (response?.exception?.includes("vat")) {
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: "vat",
          error: t(response.exception),
        });
        CustomNotify({
          type: "error",
          message: t(response.exception),
        });
      } else {
        if (response?.emails) {
          state.contacts.forEach((contact, index) => {
            if (response.emails.includes(contact.email)) {
              dispatch({
                type: "UPDATE_CONTACTS_FIELD_ERROR",
                index: index,
                field: "email",
                error: t("Email already exists with a different role"),
              });
            }
          });

          dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 2, error: true });
          CustomNotify({
            type: "error",
            message: t(response?.msg),
          });
        } else {
          dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 3, error: true });
          CustomNotify({
            type: "error",
            message: t("Some error occurred"),
          });
        }
      }
    }
    setLoading(false);
    scrollToTop();
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              <div className="row">
                <div className="col-12">
                  <LabelField title={t("Documents")} className="tab-subtitle" />
                </div>
                <div className="col-sm-12 col-md-4">
                  <SelectWithSearch
                    isMandatory={false}
                    title={t("Category")}
                    search={true}
                    options={[
                      {
                        value: 0,
                        label: `All Files (${state.documents.length})`,
                      }, // Add the "All Files" option with count
                      ...state?.categoriesList.map((option) => ({
                        ...option,
                        label: `${option.label} (${countCategory(
                          option.value
                        )})`,
                      })),
                    ]}
                    isSelectOption={false}
                    onChange={(e) =>
                      handleSelectFieldForSearch(e, "documentCategory")
                    }
                    isMulti={false}
                    name="attestType"
                    value={
                      searchFields.documentCategory || {
                        value: 0,
                        label: `All Files (${state.documents.length})`,
                      }
                    }
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <LabelWithInputField
                    isMandatory={false}
                    label={t("File name")}
                    name="documentName"
                    handleChange={(event) => handleInputFieldForSearch(event)}
                    value={searchFields.documentName}
                    placeholder={t("File name")}
                    type="text"
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <SelectWithSearch
                    isMandatory={false}
                    title={t("File type")}
                    search={true}
                    options={extensionOptions}
                    onChange={(e) => handleSelectFieldForSearch(e, "extension")}
                    isMulti={false}
                    name="extension"
                    value={searchFields.extension}
                  />
                </div>
                {/* Search bar */}
                <div className="col-12">
                  <div
                    className="pwa table-responsive overflow-auto scrollBarDesign"
                    style={{ height: "calc(100vh - 30vw)" }}
                  >
                    <table className="table table-hover">
                      <thead className="position-sticky top-0 companyDocumentsThead">
                        <tr className="border-bottom TableHeader bg-white">
                          <th className="border-0 align-middle ps-lg-4">
                            {t("File")}
                          </th>
                          <th className="border-0 align-middle">
                            {t("File type")}
                          </th>
                          <th className="border-0 align-middle">
                            {t("Category")}
                          </th>
                          <th className="border-0 align-middle">
                            {t("Uploaded by")}
                          </th>
                          <th className="border-0 align-middle">
                            {t("Uploaded at")}
                          </th>
                          <th className="border-0 align-middle">
                            {t("Action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.documents
                          .filter((document) => {
                            // If no category is selected, include all documents
                            if (
                              !searchFields.documentCategory ||
                              searchFields.documentCategory.value === 0
                            ) {
                              // Check if the document name matches the search criteria
                              const fileNameMatches =
                                searchFields.documentName.trim() === "" ||
                                document.documentName
                                  .toLowerCase()
                                  .includes(
                                    searchFields.documentName.toLowerCase()
                                  );

                              const extensionMatches =
                                !searchFields.extension ||
                                searchFields.extension?.label === "Select" ||
                                document.extension.toLowerCase() ===
                                  searchFields.extension?.label;

                              return fileNameMatches && extensionMatches;
                            }

                            // Check if the document matches the selected category
                            const categoryMatches =
                              document.documentCategory?.value ===
                              searchFields.documentCategory?.value;

                            // Check if the document matches the file name
                            const fileNameMatches =
                              searchFields.documentName.trim() === "" ||
                              document.documentName
                                .toLowerCase()
                                .includes(
                                  searchFields.documentName.toLowerCase()
                                );

                            // Check if the document matches the file extension
                            const extensionMatches =
                              !searchFields.extension ||
                              searchFields.extension?.label === "Select" ||
                              document.extension.toLowerCase() ===
                                searchFields.extension?.label;

                            // Include the document if it matches all filter criteria
                            return (
                              categoryMatches &&
                              fileNameMatches &&
                              extensionMatches
                            );
                          })
                          .map((document, index) => (
                            <tr key={index}>
                              <td className="ps-lg-4 align-middle text-break">
                                <a
                                  className="file-link"
                                  href={
                                    document.filePath?.startsWith("https")
                                      ? document.filePath // If filePath starts with "https", use it directly
                                      : `${FILE_URL}/${document.filePath}` // Otherwise, construct the URL using FILE_URL and picFilePath
                                  }
                                  target="_blank"
                                >
                                  {document.documentName}
                                </a>
                              </td>
                              <td
                                className="align-middle text-break"
                                data-label={t("File type")}
                              >
                                {document.extension}
                              </td>
                              <td
                                className="align-middle text-break"
                                data-label={t("Category")}
                              >
                                {document.documentCategory?.label}
                              </td>
                              <td
                                className="align-middle text-break"
                                data-label={t("Uploaded by")}
                              >
                                {document?.uploadedBy}
                              </td>
                              <td
                                className="align-middle text-break"
                                data-label={t("Uploaded at")}
                              >
                                {document?.createdAt}
                              </td>
                              <td
                                className="align-middle text-break"
                                data-label={t("Action")}
                              >
                                <span
                                  title={t("Edit")}
                                  onClick={() =>
                                    editDocumentData(document.editId)
                                  }
                                  className="table-action-icons cursor-pointer me-2 rounded-0 shadow-none"
                                >
                                  <EditIcon />
                                </span>
                                <span
                                  title={t("Delete")}
                                  onClick={() =>
                                    removeDocumentFieldSet(document.editId)
                                  }
                                  className="table-action-icons cursor-pointer rounded-0 shadow-none"
                                >
                                  <DeleteIcon />
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-12" style={{marginTop:"0.5vw"}}>
                  <Button
                    title={"+" + t("Add")}
                    handleClick={() => addDocumentData(state.documents.length)}
                    className="form-button float-end shadow-none"
                  />
                </div>
                <div className="col-md-12">
                  {/* File upload modal popup */}
                  <ModalPopup
                    size="md"
                    show={isDocumentModalOpen}
                    onHide={closeDocumentModal}
                    title={t("Document")}
                    modalBodyClassName="px-2"
                    headerClassName={`col-12 mx-auto`}
                    body={
                      <>
                        <div className="row">
                          <div className="col-sm-8 col-md-6 upload-certificate">
                            <FileUpload
                              id="documentId"
                              name="documentId"
                              label={t("File upload")}
                              isMandatory={true}
                              edit={false}
                              fileId={documentData.documentId ?? null}
                              fileName={documentData.fileName ?? ""}
                              filePath={documentData.filePath ?? ""}
                              style={fileUploadStyleObject}
                              multiple={false}
                              uploadPath={"company-documents"}
                              formats=".pdf,.docx,.doc,.jpeg,.png,.jpg"
                              returnFileUploadErrors={(errorResponse) =>
                                documentUploadErrors(errorResponse)
                              }
                              fileUploadResponse={(uploadResposne) => {
                                documentUploadResponse(uploadResposne);
                              }}
                              deleteResponse={(field) =>
                                documentDeleteResponse(field)
                              }
                              maxFileSize={10 * 1024 * 1024}
                              microserviceURL={`${process.env.REACT_APP_COMPANY_SERVICE}`}
                            />
                            <div
                              className="text-danger height-20"
                              style={{ marginBottom: "0.5vw" }}
                            >
                              <span className="text-danger">
                                {documentErrors.documentId}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-8 col-md-6 d-flex align-items-center">
                            <LabelWithInputField
                              isMandatory={true}
                              name="documentName"
                              handleChange={(event) =>
                                handleDocumentFieldChange(event)
                              }
                              value={documentData.documentName}
                              id={`documentName-${documentIndex}`}
                              label={t("File name")}
                              placeholder={t("File name")}
                              type="text"
                              error={documentErrors.documentName}
                            />
                            <span className="ms-2 fw-bold">
                              {documentData.extension !== ""
                                ? `.${documentData.extension}`
                                : ""}
                            </span>
                          </div>
                          <div className="col-sm-8 col-md-6 upload-certificate">
                            <SelectWithSearch
                              title={t("Category")}
                              isMandatory={true}
                              search={true}
                              options={state.categoriesList}
                              id={`category-${documentIndex}`}
                              onChange={(e) =>
                                handleDocumentSelectChange(
                                  e,
                                  "documentCategory"
                                )
                              }
                              isMulti={false}
                              name="attestType"
                              value={documentData.documentCategory}
                              error={documentErrors.documentCategory}
                              isTranslate={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 mx-auto">
                          <Button
                            handleClick={onDocumentOkButtonClick}
                            className="mx-0 shadow-none form-button float-end"
                          >
                            {t("Save")}
                          </Button>
                        </div>
                      </>
                    }
                    className="modal-lg documentsCertificates"
                  />
                  {/* Document code end */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Navigation validStatus={validStatus} isLoading={loading} />
      </form>
      <ModalPopup
        show={showCompanyTypeModal}
        showCloseButton={false}
        title={t("Select company type")}
        body={
          <div className="col-sm-12 col-md-12">
            <br />
            <RadioField
              name={`isProspect`}
              value={1}
              ischecked={state.basic.isProspect === 1}
              handleChange={handleCompanyTypeChange}
              label={t("Prospect")}
            />
            <RadioField
              name={"isProspect"}
              value={0}
              ischecked={state.basic.isProspect === 0}
              handleChange={handleCompanyTypeChange}
              label={t("Customer")}
              className="ms-5"
            />
          </div>
        }
        onConfirmButtonClick={handleCompanyTypeSubmit}
        confirmTitle={t("Save")}
      />
      <ModalPopup
        show={locationPopup}
        onHide={() => navigateToManagePage()}
        title={t("Create location")}
        body={`${t("Company created successfully")}! ${t(
          "Go ahead and create locations to the company"
        )}`}
        onConfirmButtonClick={() =>
          navigateToLocationPage(companyIdForLocation)
        }
        onCloseButtonClick={() => navigateToManagePage()}
        closeTitle={t("No")}
        confirmTitle={t("Create location")}
      />
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={modalTitle}
        body={modalBody}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleModalClose}
        closeTitle={modalTitle !== "" ? t("No") : ""}
        confirmTitle={modalTitle !== "" ? t("Yes") : ""}
      />
    </>
  );
};
export default translate(Documents);
